import { Injectable } from '@angular/core';
import { PaymentPlanDataService } from '@app/core/data-services/payment-plan/payment-plan-data.service';
import { PayInFullPaymentPreference } from '@app/core/enums/pay-in-full.enum';
import { SmsJourneyPlan } from '@app/core/models';
import { Progress } from '@app/core/models/payment/progress.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { PendingPlanService } from '@app/core/services/pending-plan/pending-plan.service';
import { PendingPlanProgressService } from '@app/core/services/pending-plan/progress/pending-plan-progress.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { PutPendingPlanResponse } from '@app/sms-journey/interface/rp-sms-journey.interface';
import { TreatmentOptionResponse } from '@app/treatment-proposal/models/get-unsubmitted-proposal.response';
import { TreatmentOptionCard } from '@app/treatment-proposal/models/treatment-option-card';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export enum PaymentFlow {
  Normal = 1,
  PayInFull = 2
}

@Injectable()
export class TreatmentOptionPageService {
  private CONFIG_KEY = 'treatment_option_page';

  constructor(
    private configService: ConfigService,
    private pendingPlanService: PendingPlanService,
    private paymentPlanDataService: PaymentPlanDataService,
    private pendingPlanProgressService: PendingPlanProgressService
  ) {}

  getNextBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'next_btn');
  }

  getBackBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'back_btn');
  }

  getOptionDetails(): Observable<TreatmentOptionCard | null> {
    return this.pendingPlanService.getPendingPlan$().pipe(
      map((data) => {
        const options = data?.payment_plan.treatment_options;
        const selectedIndex = options?.findIndex((x) => x.selected);

        if (options && selectedIndex !== undefined && selectedIndex >= 0) {
          return this.mapToTreatmentOptionCard(options[selectedIndex], selectedIndex);
        }

        return null;
      })
    );
  }

  getIntroTextSms$(): Observable<string> {
    return this.pendingPlanService.getPendingPlan$().pipe(
      map((plan) => {
        return plan?.payment_plan.customrecord_template_proposal?.custrecord_introtxt_sms || '';
      })
    );
  }

  updatePendingPlan$(newPlanData: Partial<SmsJourneyPlan>, ddrId: string): Observable<PutPendingPlanResponse> {
    const planSnapshot = this.pendingPlanService.getPendingPlan()?.payment_plan;
    if (planSnapshot) {
      return this.paymentPlanDataService.updatePendingPlan({ ...planSnapshot, ...newPlanData }, ddrId);
    }
    return throwError('No pending plan data');
  }

  updatePendingPlan(plan: SmsJourneyPlan, ddrDraftId: string): Observable<PutPendingPlanResponse> {
    return this.paymentPlanDataService.updatePendingPlan(plan, ddrDraftId);
  }

  getPlanProgressStep(ddrDraftId: string): Observable<Progress[]> {
    return this.paymentPlanDataService
      .getPlanProgressStep(ddrDraftId)
      .pipe(tap((steps) => this.pendingPlanProgressService.setProgress(steps)));
  }

  hasPaymentSecured$(): Observable<boolean> {
    return this.pendingPlanService.hasPaymentSecured$();
  }

  private mapToTreatmentOptionCard(treatmentOption: TreatmentOptionResponse, index: number): TreatmentOptionCard {
    return {
      id: index,
      treatmentOptionName: treatmentOption.custrecord_tp_treatment_option,
      treatmentDescription: treatmentOption.treatment_description,
      planDetails: {
        totalTreatmentCost: treatmentOption.total_plan_value,
        discount: treatmentOption.discount_amount,
        ttcAfterDiscount: treatmentOption.total_plan_value - treatmentOption.discount_amount,
        deposit: treatmentOption.deposit,
        paymentPlanAmount: treatmentOption.payment_plan_total,
        paymentPlanDuration: treatmentOption.quote[0].term_months,
        firstPaymentDate: treatmentOption.start_date,
        firstPaymentDateInput: treatmentOption.first_payment_date_input?.toString() || '',
        firstPaymentDateOption: treatmentOption.first_payment_date_option?.toString() || '',
        paymentFrequency: treatmentOption.payment_freq,
        quote: treatmentOption.quote,
        numberOfPayments: treatmentOption.no_of_payments,
        discountReason: treatmentOption.discount_reason,
        pifTotalPlanValue: +treatmentOption.pif_total_plan_value || 0,
        pifDiscountAmount: +treatmentOption.pif_discount_amount || 0,
        pifOtherDiscountAmount: +treatmentOption.pif_other_discount_amount || 0,
        payDepositTo: treatmentOption.pay_deposit_to,
        pifPaymentPreference: treatmentOption.pif_pay_to as PayInFullPaymentPreference,
        paymentOptions: treatmentOption.$$paymentFlowOptions
      },
      selected: treatmentOption.selected
    };
  }
}
