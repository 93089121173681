import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  public apimKey = environment.apimKey;

  // TO DO: Group urls
  // GET
  // POST
  // PUT

  loginUrl = environment.authBaseURL + '/login';
  refreshUrl = environment.authBaseURL + '/refresh';
  forgotPasswordUrl = environment.authBaseURL + '/forgot';
  changePasswordUrl = environment.authBaseURL + '/change';
  settingsUpdateAccessUrl = environment.authBaseURL + '/update-provider-access';

  helpUrl = environment.api + '/help';
  logoutUrl = environment.api + '/logout';
  setPinUrl = environment.api + '/pin-set';
  supportUrl = environment.api + '/support';
  pinLockUrl = environment.api + '/pin-lock';
  ddrListUrl = environment.api + '/ddr-list';
  settingsUrl = environment.api + '/settings';
  invoiceListUrl = environment.api + '/invoice';
  pinUnlockUrl = environment.api + '/pin-unlock';

  savedPlanUrl = environment.api + '/saved-plan';
  updateSavedPlanUrl = environment.api + '/saved-plan';
  createUnsubmittedProposal = environment.api + '/saved-plan'; // Create Unsubmitted Proposal

  setProviderUrl = environment.api + '/provider';
  ddrRpListUrl = environment.api + '/ddr-rp-list';
  formFieldsUrl = environment.api + '/form-fields';
  paymentPlanUrl = environment.api + '/payment-plan';
  meUrl = environment.api + '/me';
  getPdfUrl = environment.api + '/file/show';
  sendOTPUrl = environment.api + '/otp/send';
  resendOTPUrl = environment.api + '/otp/resend';
  pinResetUrl = environment.api + '/pin-reset';
  tncUrl = environment.api + '/terms-conditions';
  confirmOTPUrl = environment.api + '/otp/confirm';
  previewPdfUrl = environment.api + '/preview-pdf';
  remittanceListUrl = environment.api + '/remittance';
  bsbCheckUrl = environment.api + '/payment-plan/bsb';
  addressLookupUrl = environment.api + '/address-lookup';
  rpEligibilityUrl = environment.api + '/rp-eligibility';
  settingsAccessUrl = environment.api + '/settings/access';
  missedPaymentsUrl = environment.api + '/missed-payments';
  sendInvoiceURL = environment.api + '/missed-payments/paynow-sms';
  missedPaymentsEscalateUrl = environment.api + '/missed-payments/transfer-to-apca';
  resetTokenUrl = environment.api + '/auth/pin/reset/token';
  smsSavedPlanUrl = environment.api + '/saved-plans/resume-sms';
  updatePaymentPlanUrl = environment.api + '/payment-plan/update';
  providerElementUrl = environment.api + '/provider-element-list';
  marketingCollateral = environment.api + '/marketing-collateral';
  paymentPlanUploadUrl = environment.api + '/payment-plan-upload';
  paymentPlanSummaryUrl = environment.api + '/payment-plan-summary';
  sendAppLinkUrl = environment.api + '/payment-plan-summary/send-app-link';
  resetTokenDirectUrl = environment.api + '/auth/pin/reset/direct'; // not enable due to remove button in HTML
  emailSavedPlanUrl = environment.api + '/saved-plans/resume-email';
  paymentPlanTemplateUrl = environment.api + '/payment-plan-template';
  savedPlanNoLoginUrl = environment.api + '/saved-plans-without-login';
  checkRPRecordPlanExistUrl = environment.api + '/rp-recordnplan-check';
  bsbCheckNoLoginUrl = environment.api + '/saved-plans-without-login/bsb';
  paymentPlanApprovalUrl = environment.api + '/payment-plan/approve-or-decline';
  paymentPlanPendingApprovalUrl = environment.api + '/payment-plan-summary-pending';
  previewPDFNoLoginUrl = environment.api + '/saved-plans-without-login/preview-pdf';
  rpEligibilityNoLoginUrl = environment.api + '/saved-plans-without-login/rp-eligibility';
  settingsUpdateProviderBnplWeeklyPaymentUrl = environment.api + '/settings/update-provider-bwp';
  checkRPRecordnPlanExistNoLoginUrl = environment.api + '/saved-plans-without-login/rp-recordnplan-check';
  postProcessPayment = environment.api + '/saved-plans-without-login/process-deposit';
  getPlanProgressStep = environment.api + '/saved-plans-without-login/progress-step';
  providerElementByDateRangeUrl = environment.api + '/payment-plan-summary/provider-element-by-date-range';
  verifyKYC = environment.api + '/saved-plans-without-login/verify/kyc';
  updateSavedPlanFieldByCode = environment.api + '/saved-plans-without-login/field-update';
  postRpAffordability = environment.api + '/rp-affordability';
  getDepositInvoice = environment.api + '/payment-plan/pif-deposit-pdf';
  // Rule Json API
  rulesJsonUrl = environment.rules_json_url;
  createSupportCaseUrl = environment.api + '/support/createSupportCase';
  checkAvailableBalance = environment.api + '/amend/checkAvailableBalance';

  // Amendments
  createAmendmentRequest = environment.api + '/amend/saveAmendmentRequest'; // saves amendment AND sends sms
  updateAmendmentRequest = environment.api + '/amend/editAmendmentRequest';
  sendAmendmentSMS = environment.api + '/amend/sendAmendmentSMS'; // only sends sms
  createAmendmentElement = environment.api + '/amend/createAmendmentElement';
  createAmendmentElementWithoutLogin = environment.api + '/amend/createAmendmentElementWithoutLogin';
  amendmentDetails = environment.api + '/amend/getAmendmentInit';
  acknowledgeAmendmentChanges = environment.api + '/amend/unsetAmendmentChangedWithoutLogin';

  productUpdatesUrl = environment.api + '/product-update';

  updateProviderSettings = environment.api + '/settings/update-provider-settings';
  createSupportCaseMaster = environment.api + '/support/createSupportCaseMaster';
  approveTnc = environment.api + '/auth/terms-conditions/approve';
  getSignedTncPdf = environment.api + '/auth/terms-conditions';

  getProposalTemplate = environment.api + '/template-proposal';
  postSaveProposalTemplate = environment.api + '/template-proposal';
  previewProposal = environment.api + '/preview-proposal';
  postSmsJourneyPreviewProposalPdf = environment.api + '/saved-plans-without-login/preview-pdf';

  // arrears
  getPaynowInvoiceData = environment.api + '/missed-payments-paynow-without-login';
  paynow = environment.api + '/missed-payments-paynow-without-login/paynow';
  confirmPaynowPayment = environment.api + '/missed-payments-paynow-without-login/confirm-paynow';

  constructor() {
    if (!this.apimKey) {
      // console.log("Not APIM");
      this.loginUrl = environment.authBaseURL + '/provider/login';
      this.refreshUrl = environment.authBaseURL + '/auth/token';
      this.forgotPasswordUrl = environment.authBaseURL + '/provider/forgot-password';
      this.changePasswordUrl = environment.authBaseURL + '/provider/change-password';
      this.settingsUpdateAccessUrl = environment.authBaseURL + '/provider/settings/update-provider-access';

      // PHP APIs
      this.setProviderUrl = environment.api + '/auth/provider';
      this.formFieldsUrl = environment.api + '/auth/form-fields';
      this.setPinUrl = environment.api + '/auth/pin/set';
      this.logoutUrl = environment.api + '/auth/logout';
      this.pinLockUrl = environment.api + '/auth/pin/lock';
      this.pinUnlockUrl = environment.api + '/auth/pin/unlock';

      this.savedPlanUrl = environment.api + '/saved-plans'; // Create Unsubmitted Plan
      this.updateSavedPlanUrl = environment.api + '/saved-plans';
      this.createUnsubmittedProposal = environment.api + '/saved-plans'; // Create Unsubmitted Proposal

      this.ddrRpListUrl = environment.api + '/support/ddr-rp-list';
      this.ddrListUrl = environment.api + '/support/ddr-list';
      this.invoiceListUrl = environment.api + '/invoices';
      this.pinResetUrl = environment.api + '/auth/pin/reset'; // this send email to netsuite
      this.meUrl = environment.api + '/auth/me';
      this.tncUrl = environment.api + '/auth/terms-conditions';
      this.providerElementUrl = environment.api + '/payment-plan-summary/provider-element-list';
      this.previewPdfUrl = environment.api + '/payment-plan/preview-pdf';
      this.addressLookupUrl = environment.harmonyBaseURL;
    }
  }
}
