import { Injectable } from '@angular/core';
import { BasiqDataService } from '@app/core/data-services/basiq/basiq-data.service';
import { BasiqVerificationStatus } from '@app/core/enums/basiq.enum';
import { BasiqAccount } from '@app/core/models/basiq/account.interface';
import { BasiqAuthPayload, BasiqAuthResponse } from '@app/core/models/basiq/auth.interface';
import { UpdateVerificationStatusResponseData } from '@app/core/models/basiq/basiq-api.interface';
import {
  IncomeVerificationApiResponse,
  IncomeVerificationData,
  IncomeVerificationPayload
} from '@app/core/models/basiq/income-verification.interface';
import { BasiqJobData } from '@app/core/models/basiq/job-data.interface';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HelperService } from '../helper.service';
import { PendingPlanService } from '../pending-plan/pending-plan.service';

interface BasiqAccountsUiState {
  accounts: BasiqAccount[];
  isLoading: boolean;
  isLoaded: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BasiqService {
  private jobDataSubject = new BehaviorSubject<BasiqJobData | null>(null);
  private jobIdSubject = new BehaviorSubject<string>('');
  private basiqUserId = '';

  private basiqAccountsUiStateSubject = new BehaviorSubject<BasiqAccountsUiState>({
    accounts: [],
    isLoaded: false,
    isLoading: false
  });

  constructor(
    private basiqDataService: BasiqDataService,
    private helperService: HelperService,
    private pendingPlanService: PendingPlanService
  ) {}

  setBasiqUserId(id: string): void {
    this.basiqUserId = id;
  }

  getBasiqUserId(): string {
    return this.basiqUserId;
  }

  setJobId(id: string): void {
    this.jobIdSubject.next(id);
  }

  getJobId(): string | null {
    return this.jobIdSubject.getValue();
  }

  setJobData(data: BasiqJobData): void {
    this.jobDataSubject.next(data);
  }

  getJobData(): BasiqJobData | null {
    return this.jobDataSubject.getValue();
  }

  getBasiqAccountsUiStateSnapshot(): BasiqAccountsUiState {
    return this.basiqAccountsUiStateSubject.getValue();
  }

  setAccounts(bankAccounts: BasiqAccount[]): void {
    const stateSnapshot = this.basiqAccountsUiStateSubject.getValue();
    this.basiqAccountsUiStateSubject.next({ ...stateSnapshot, accounts: bankAccounts, isLoaded: true });
  }

  getAccounts$(): Observable<BasiqAccount[]> {
    return this.basiqAccountsUiStateSubject.pipe(map((data) => data.accounts));
  }

  setFetchingAccountsLoadingFlag(isFetching: boolean): void {
    this.basiqAccountsUiStateSubject.next({ ...this.getBasiqAccountsUiStateSnapshot(), isLoading: isFetching });
  }

  isFetchingAccounts$(): Observable<boolean> {
    return this.basiqAccountsUiStateSubject.pipe(map((data) => data.isLoading));
  }

  getTransactionalAccounts$(): Observable<BasiqAccount[]> {
    return this.basiqAccountsUiStateSubject.pipe(
      map((state) => {
        const transactionalAcccounts = state.accounts.filter((acct) => acct.isTransactionAccount);
        return this.transformAccounts(transactionalAcccounts);
      })
    );
  }

  getAccounts(): BasiqAccount[] {
    return this.basiqAccountsUiStateSubject.getValue().accounts;
  }

  getAccountById(id: string): BasiqAccount | undefined {
    return this.getAccounts().find((acct) => acct.id === id);
  }

  createUserAndToken$(payload: BasiqAuthPayload): Observable<BasiqAuthResponse> {
    return this.basiqDataService.createUserAndToken(payload).pipe(
      tap((res) => {
        const basiqUserId: string = res.data[0].basiqUserId;

        this.setBasiqUserId(basiqUserId);
        sessionStorage.setItem(`pv.basiq`, basiqUserId);
      }),
      map((res) => {
        if (!res.isError) {
          const data = this.helperService.getAPIDataResponse(res);
          return data[0];
        }
        return [];
      })
    );
  }

  getConsentUrl(basiqToken: string, ddrId: string): string {
    return `${environment.basiq.consentUrl}?token=${basiqToken}&state=${ddrId}`;
  }

  verifyIncome$(payload: IncomeVerificationPayload): Observable<IncomeVerificationData> {
    return this.basiqDataService.verifyIncome(payload).pipe(
      map((res) => {
        if (!res.isError) {
          const data = this.helperService.getAPIDataResponse(res);
          return this.mapIncomeVerificationResponse(data[0]);
        }
        return { verificationStatus: BasiqVerificationStatus.PENDING };
      })
    );
  }

  getAccountsByUserId$(basiqUserId: string): Observable<BasiqAccount[]> {
    return this.basiqDataService.getAccounts(basiqUserId).pipe(
      map((res) => {
        if (!res.isError) {
          const data = this.helperService.getAPIDataResponse(res);
          return data[0];
        }
        return [];
      }),
      tap((accounts) => this.setAccounts(accounts))
    );
  }

  getJobData$(jobId: string): Observable<BasiqJobData> {
    const fallbackValue: BasiqJobData = {
      type: 'job',
      id: '',
      created: new Date(),
      updated: new Date(),
      jobType: '',
      steps: [],
      links: { self: '', source: '' }
    };

    return this.basiqDataService.getJobData(jobId, this.basiqUserId).pipe(
      map((res) => {
        if (!res.isError) {
          return res.data[0];
        }
        return fallbackValue;
      }),
      catchError(() => of(fallbackValue))
    );
  }

  updateBasiqVerificationStatus(status: BasiqVerificationStatus, ddrDraftId: string): Observable<UpdateVerificationStatusResponseData> {
    return this.basiqDataService.updateBasiqVerificationStatus({ status, ddrDraftId }).pipe(
      map((res) => res.data),
      tap((data) => {
        const clonedPendingPlan = Object.assign({}, this.pendingPlanService.getPendingPlan());
        clonedPendingPlan.payment_plan.basiq.custrecord_ddrip_basiq_verify_status = status;
        this.pendingPlanService.setPendingPlan(clonedPendingPlan);
      })
    );
  }

  isBasiqVerificationRetryRequired(status: BasiqVerificationStatus): boolean {
    const isBasiqVerificationRetryRequiredStatuses = [BasiqVerificationStatus.NO_TRANSACTIONAL_ACCOUNTS, BasiqVerificationStatus.NO_INCOME];
    return isBasiqVerificationRetryRequiredStatuses.includes(status);
  }

  isManualVerificationRequired(status: BasiqVerificationStatus): boolean {
    const isManualVerificationRequiredStatuses = [
      BasiqVerificationStatus.JOBSEEKER,
      BasiqVerificationStatus.FAILED_CREDENTIALS,
      BasiqVerificationStatus.FAILED_ACCOUNT,
      BasiqVerificationStatus.FAILED_TRANSACTION,
      BasiqVerificationStatus.FAILED_INCOME
    ];
    return isManualVerificationRequiredStatuses.includes(status);
  }

  getBsbByAccountNr(accountNo: string): string {
    return accountNo.substring(0, 6);
  }

  private mapIncomeVerificationResponse(response: IncomeVerificationApiResponse): IncomeVerificationData {
    return {
      verificationStatus: response.verificationStatus.toString()
    };
  }

  private transformAccounts(accounts: BasiqAccount[]): BasiqAccount[] {
    const highestAccountBalance = accounts.reduce<BasiqAccount>((curr, item) => {
      return +curr.balance > +item.balance ? curr : item;
    }, accounts[0]);

    return accounts.reduce<BasiqAccount[]>((acc, acct) => {
      const bankAccount = {
        ...acct,
        $$selected: highestAccountBalance.id === acct.id
      };
      acc.push(bankAccount);
      return acc;
    }, []);
  }
}
