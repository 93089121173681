import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArrearsDataService {
  constructor(private apiUrlService: ApiUrlService, private httpClient: HttpClient) {}

  sendArrearsInvoiceViaSms$(ddrId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrlService.sendInvoiceURL}/${ddrId}`);
  }

  escalate$(body: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrlService.createSupportCaseUrl, body).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
