import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { DdrPlanType } from '@app/core/models/common/form-fields.interface';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { Observable } from 'rxjs';
import { CreatePlanDropdownService } from './create-plan-dropdown.service';

@Component({
  selector: 'sliqpay-create-plan-dropdown',
  templateUrl: './create-plan-dropdown.component.html',
  styleUrls: ['./create-plan-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CreatePlanDropdownService]
})
export class CreatePlanDropdownComponent implements OnInit {
  @Input() moduleType: ModuleType.Plan | ModuleType.Proposal = ModuleType.Plan;

  ModuleType = ModuleType;

  createDdConfig$!: Observable<AppDropdown>;
  createPlanBtnConfig$!: Observable<AppButton>;
  planTypes$!: Observable<DdrPlanType[]>;

  constructor(private cpdService: CreatePlanDropdownService, private router: Router) {}

  ngOnInit(): void {
    this.createDdConfig$ = this.cpdService.getCreateDdConfig(this.moduleType);
    this.createPlanBtnConfig$ = this.cpdService.getCreatePlanBtnConfig();
    this.planTypes$ = this.cpdService.getPlanTypes();
  }

  onDropdownChange(selectedValue: any): void {
    switch (this.moduleType) {
      case ModuleType.Plan: {
        this.router.navigate(['/provider-journey/create-plan'], { queryParams: { initialPlanType: selectedValue } });
        break;
      }
      case ModuleType.Proposal: {
        this.router.navigate(['/provider-journey/create-proposal'], { queryParams: { planType: selectedValue } });
        break;
      }
    }
  }

  onBtnChange(): void {
    this.router.navigateByUrl('/provider-journey/create-plan');
  }
}
