import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { TierPermissionService } from '@app/core/services/tier-permission/tier-permission.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private toastrService: ToastrService,
    private tierPermissionService: TierPermissionService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    try {
      // block create Payment Plan BNPL route
      if (state.url.includes('/payment-plan-bnpl')) {
        this.router.navigate(['/login']);
        return false;
      }
      if (!this.authService.canAccess(state.url)) {
        if (
          ((state.url.includes('/create-plan') || state.url.includes('/create-proposal')) &&
            !state.url.includes('/payment-plan-summary')) ||
          state.url.includes('/amendment/confirmation')
        ) {
          this.activatedRoute.queryParams.subscribe((params) => {
            return !params['token'];
          });
          // this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/login']);
          // alert("You don't have permission to access that page");
          console.warn('Denied access to requested route', state);
          return false;
        }
        return true;
      } else {
        if (this.authService.checkToken()) {
          // block create Payment Plan route
          if (
            (state.url.includes('/create-plan') || state.url.includes('/create-proposal')) &&
            !state.url.includes('/payment-plan-summary')
          ) {
            if (this.tierPermissionService.getCreatePlanPermission()) {
              return true;
            } else {
              this.router.navigate(['/login']);
              this.toastrService.info(
                'Please contact your dedicated Account Manager on 1300 633 472 to discuss upgrading your service to access this feature.',
                'Insufficient Access'
              );
              // this.helperService.dialogInsufficientAccess();
              return false;
            }
          }

          // block create/edit template route
          if (state.url.includes('/templates/create') || state.url.includes('/templates') || state.url.includes('/templates/edit')) {
            if (this.tierPermissionService.getCreateTemplatePermission()) {
              return true;
            } else {
              this.router.navigate(['/login']);

              this.toastrService.info(
                'Please contact your dedicated Account Manager on 1300 633 472 to discuss upgrading your service to access this feature.',
                'Insufficient Access'
              );
              return false;
            }
          }
        } else {
          this.router.navigate(['/login']);
          console.warn('Token Expired', state);
          return false;
        }
      }
    } catch (error) {
      console.log('error', error);
    }

    return true;
  }
}
