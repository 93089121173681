<div
  class="alert d-flex"
  role="alert"
  [ngClass]="{
    'alert-primary': type === alertType.PRIMARY,
    'alert-success': type === alertType.SUCCESS,
    'alert-warning': type === alertType.WARNING,
    'alert-danger': type === alertType.DANGER,
    'alert-info': type === alertType.INFO
  }"
>
  <div class="pp-alert-icon">
    <ng-container [ngSwitch]="type">
      <!-- <img *ngSwitchCase="alertType.HELP" src="assets/images/question-circle.svg" alt="question mark" /> -->
      <i *ngSwitchCase="alertType.PRIMARY" class="uil uil-info-circle mr-2"></i>
      <i *ngSwitchCase="alertType.SUCCESS" class="uil uil-check-circle mr-2"></i>
      <i *ngSwitchCase="alertType.INFO" class="uil uil-info-circle mr-2"></i>
      <i *ngSwitchCase="alertType.WARNING" class="uil uil-exclamation-triangle mr-2"></i>
      <i *ngSwitchCase="alertType.DANGER" class="uil uil-exclamation-octagon mr-2"></i>
    </ng-container>
  </div>

  <span [innerHTML]="message" class="alert-message"></span>
</div>
