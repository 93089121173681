import { AbstractControl } from '@angular/forms';

export class CustomValidators {
  static NonZero = (control: AbstractControl) => {
    const value = control?.value;

    if (parseFloat(value) === 0 || isNaN(parseFloat(value))) {
      return { nonZero: true };
    }
    return null;
  };
}
