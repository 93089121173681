import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface FullscreenLoaderState {
  message: string;
  show: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FullscreenLoaderService {
  private showLoadingScreenSubject = new BehaviorSubject<FullscreenLoaderState>({
    show: false,
    message: ''
  });

  show(message: string): void {
    this.showLoadingScreenSubject.next({ show: true, message });
  }

  hide(): void {
    this.showLoadingScreenSubject.next({ show: false, message: '' });
  }

  fullscreenUiState$(): Observable<FullscreenLoaderState> {
    return this.showLoadingScreenSubject.asObservable();
  }
}
