import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AmendmentQuote, FirstStepFields } from '@core/models';
import { HelperService } from '@core/services/helper.service';
import { PaymentService } from '@core/services/payment.service';
import { environment } from '@environments/environment';
import { format } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentFrequency } from '../enums/payment-frequency.enum';
import { PlanType } from '../enums/plan-type.enum';
import { UserSettingsService } from './user-settings/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class PlanCalculationService {
  objUser = this.helperService.getObjUser() || null;
  treatmentTypeOrtho = this.objUser?.validation_values.DDR_TREATMENT.ORTHO;
  minDeposit = 0;
  minTermMonths = 0;
  minTreatmentCost = 0;
  maxTreatmentCost = 0;
  minAdditionalPlanAmount = 1;
  minDepositAmount = 0;
  minDepositInAmtBNPL = 0;
  maxTreatmentCostBNPL = 0;
  computeMinTermMonths = 1;
  paymentPlanAmount2k = 2000;
  defaultWeeklyNoOfPayments = 12;
  calcMinTreatmentCostBNPL = 1;
  maxTermMonthsConnect = 36;
  public radioComplianceNoLbl = 'complianceNo';

  calculatorOutput = {
    directDebit: {
      cost: 0.0,
      per: ''
    },
    periodOf: {
      months: 0,
      weeks: 0
    },
    numberOfDebits: 0,
    firstPaymentOn: new Date(),
    finalPaymentOn: new Date()
  };
  maxRepaymentData = {
    valid: true,
    maxRepaymentAmountByFreq: 0
  };
  treatmentTypeDental = this.objUser?.validation_values.DDR_TREATMENT.DENTAL;
  nonGuaranteePlan = this.objUser?.validation_values.DDR_PLAN_TYPE.NON_GUARANTEED;
  today = new Date();
  tomorrow = this.helperService.addDaysToDate(1);
  maxFortnightlyNoOfPayments = 5;
  hideFornightly = false;

  maxDepositObs$ = new BehaviorSubject(0);

  constructor(
    private helperService: HelperService,
    private paymentService: PaymentService,
    private userSettingsService: UserSettingsService
  ) {}

  public calculateTreatmentFeesNew(formValue: any) {
    const planAmount = formValue.payment_plan_total || 0;
    const paymentAmount = formValue.custrecord_mfa_ddr_single_payments || 0;
    const amountRequiredToPay = formValue.total_plan_value - formValue.deposit;
    const newQuote = formValue.quote;

    formValue.quote.forEach((quote: any, i: number) => {
      const weeklyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(1, quote.term_months, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const fortnightlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(2, quote.term_months, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const monthlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(3, quote.term_months, planAmount, paymentAmount),
        amountRequiredToPay
      );
      newQuote[i]['weekly'] = weeklyCost;
      newQuote[i] = {
        ...newQuote[i],
        weekly: weeklyCost,
        fortnightly: fortnightlyCost,
        monthly: monthlyCost
      };
    });
    return newQuote;
  }

  public calculateTreatmentFee(firstStepGroup: UntypedFormGroup) {
    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;
    const planAmount = firstStepGroup.value.payment_plan_total || 0;
    const paymentAmount = firstStepGroup.value.custrecord_mfa_ddr_single_payments || 0;
    const amountRequiredToPay = firstStepGroup.value.total_plan_value - firstStepGroup.value.deposit;

    quoteGroup.controls.forEach((quote) => {
      this.calculatePaymentPlanAmount(firstStepGroup);

      const weeklyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(1, quote.get('term_months')?.value, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const fortnightlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(2, quote.get('term_months')?.value, planAmount, paymentAmount),
        amountRequiredToPay
      );
      const monthlyCost = this.calculatePerUnitCost(
        this.calculateNumberOfDebits(3, quote.get('term_months')?.value, planAmount, paymentAmount),
        amountRequiredToPay
      );
      quote.get('weekly')?.setValue(weeklyCost);
      quote.get('fortnightly')?.setValue(fortnightlyCost);
      quote.get('monthly')?.setValue(monthlyCost);
    });
  }

  public generateAmendmentQuotes(planAmount: number, directDebitAmount: number, currentQuote: AmendmentQuote[]): Array<AmendmentQuote> {
    return currentQuote.reduce<AmendmentQuote[]>((acc, _quote) => {
      const weeklyTotalDebits = this.calculateNumberOfDebits(PaymentFrequency.Weekly, _quote.term_months, planAmount, directDebitAmount);
      const weeklyCost = this.calculatePerUnitCost(weeklyTotalDebits, planAmount);

      const fortnightlyTotalDebits = this.calculateNumberOfDebits(
        PaymentFrequency.Fortnightly,
        _quote.term_months,
        planAmount,
        directDebitAmount
      );
      const fortnightlyCost = this.calculatePerUnitCost(fortnightlyTotalDebits, planAmount);

      const monthlyTotalDebits = this.calculateNumberOfDebits(PaymentFrequency.Monthly, _quote.term_months, planAmount, directDebitAmount);
      const monthlyCost = this.calculatePerUnitCost(monthlyTotalDebits, planAmount);

      const newQuote: AmendmentQuote = {
        ..._quote,
        weekly: weeklyCost,
        fortnightly: fortnightlyCost,
        monthly: monthlyCost
      };

      return [...acc, newQuote];
    }, []);
  }

  public calculateTreatmentFees(firstStepGroup: UntypedFormGroup, event?: any) {
    if (firstStepGroup.get('plan_type')?.value === PlanType.Bnpl) {
      return this.calculateTreatmentFeesBnpl(firstStepGroup);
    } else {
      return this.calculateTreatmentFee(firstStepGroup);
    }
  }

  public calculatePerUnitCost(numberOfDebits: number, amountRequiredToPay: number): number {
    // calculate the amount per unit
    let amountPerUnit = amountRequiredToPay / numberOfDebits;

    amountPerUnit = amountPerUnit ? amountPerUnit : 0;

    // https://stackoverflow.com/questions/10413573/rounding-up-to-the-nearest-0-05-in-javascript
    const ceil = Math.ceil(amountPerUnit * 20);
    // console.log('ceil ' + ceil);

    amountPerUnit = ceil / 20;

    // return parseFloat(amountPerUnit.toFixed(2));

    return numberOfDebits !== 0 ? Math.round((amountPerUnit ? amountPerUnit : 0) * 100) / 100 : 0;
  }

  public checkDentalProviderOrthoTreatment(form: UntypedFormGroup): boolean {
    return (
      this.objUser?.clinic_type === '1' &&
      form.get('treatment_type')?.value === this.treatmentTypeOrtho &&
      form.get('plan_type')?.value !== PlanType.Bnpl
    );
  }

  public calculateNumberOfDebits(frequency: string | number, months: number, planAmount: number, debitAmount: number): number {
    let numberOfDebits = 0;
    const weeks = 52;

    if (months === 0) {
      if (planAmount && debitAmount) {
        while (planAmount > 0) {
          numberOfDebits++;
          planAmount -= debitAmount;
        }
      }
      return numberOfDebits;
    } else {
      switch (parseInt(frequency as string, 10)) {
        // weekly payments
        case 1:
          // numberOfDebits = months * 4.428 + weeks;
          numberOfDebits = (months / 12) * weeks;
          break;
        // fortnightly payments
        case 2:
          // numberOfDebits = (months * 4.428 + weeks) / 2;
          numberOfDebits = (months / 12) * (weeks / 2);
          break;
        // monthly payments
        case 3:
          // numberOfDebits = months + weeks / 4.428;
          numberOfDebits = months;
          break;
      }
    }

    // return Math.ceil(numberOfDebits);
    return Math.floor(numberOfDebits);
  }

  public checkDentalProvidernOrthoTreatment(firstStepFields: FirstStepFields): boolean {
    this.objUser = this.helperService.getObjUser();
    return (
      this.objUser?.clinic_type === '1' &&
      parseInt(firstStepFields.treatment_type, 10) === this.treatmentTypeOrtho &&
      firstStepFields.plan_type !== PlanType.Bnpl
    );
  }

  public getBNPLFrequencies(frequencies: any) {
    // filter to exlcude 3 => Monthly
    return frequencies.filter((item: any) => item.internalid !== '3');
  }

  public setDDRSinglePayments(frequency: any, frequencies: any[]): string | null {
    if (frequency) {
      return this.helperService.strtolower(
        this.helperService.getObjectByValue(frequencies, 'internalid', frequency)
          ? this.helperService.getObjectByValue(frequencies, 'internalid', frequency).name
          : ''
      );
    }
    return null;
  }

  public getPaymentFrequencyMultiplier(frequency: string, isDays = false): number {
    let days = 0;
    let multiplier = 0;
    switch (parseInt(frequency, 10)) {
      // weekly payments
      case 1:
        days = 7;
        // multiplier = 4;
        multiplier = 52 / 12;
        break;
      // fortnightly payments
      case 2:
        days = 14;
        // multiplier = 2;
        multiplier = 26 / 12;
        break;
      // monthly payments
      case 3:
        days = 1;
        multiplier = 1;
        break;
    }
    return isDays ? days : multiplier;
  }

  public setCalculatorOutput(firstStepGroup: UntypedFormGroup) {
    const periods = ['null', 'week', 'fortnight', 'month'];

    const plan = {
      option: 0,
      frequency: firstStepGroup.get('payment_freq')?.value
    };

    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;
    const period = periods[plan.frequency];

    if (!quoteGroup.controls[plan.option]?.value.term_months) {
      quoteGroup.controls[plan.option].value.term_months =
        firstStepGroup.get('no_of_payments')?.value && firstStepGroup.get('payment_freq')?.value
          ? Math.ceil(
              firstStepGroup.get('no_of_payments')?.value / this.getPaymentFrequencyMultiplier(firstStepGroup.get('payment_freq')?.value)
            )
          : 0;
    }

    const months = plan.option !== null ? quoteGroup.controls[plan.option]?.value.term_months : 0;
    const planAmount = firstStepGroup.get('payment_plan_total')?.value;
    const paymentAmount = firstStepGroup.get('custrecord_mfa_ddr_single_payments')?.value;
    const numberOfDebits = this.calculateNumberOfDebits(plan.frequency, months, planAmount, paymentAmount);
    const amountRequiredToPay = firstStepGroup.get('total_plan_value')?.value - firstStepGroup.get('deposit')?.value;
    this.calculatorOutput = {
      directDebit: {
        cost:
          firstStepGroup.get('plan_type')?.value === 2
            ? this.calculatePerUnitCost(numberOfDebits, amountRequiredToPay)
            : firstStepGroup.get('custrecord_mfa_ddr_single_payments')?.value,
        per: period
      },
      periodOf: {
        months,
        weeks: 0
      },
      numberOfDebits,
      firstPaymentOn: firstStepGroup.get('start_date')?.value !== '' ? new Date(firstStepGroup.get('start_date')?.value) : new Date(),
      finalPaymentOn: new Date()
    };
  }

  public calculateTermMonths(numberOfPayments: number, frequency: string): number {
    if (!numberOfPayments || !frequency) {
      return 0;
    }
    return Math.ceil(numberOfPayments / this.getPaymentFrequencyMultiplier(frequency));
  }

  public minTermMonthValid(firstStepGroup: UntypedFormGroup, selectedTermMonths: number): boolean {
    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;

    if (quoteGroup?.controls[0].get('term_months')?.touched) {
      return selectedTermMonths >= this.computeMinTermMonths;
    } else {
      return true;
    }
  }

  public calculatePaymentPlanAmount(firstStepFields: UntypedFormGroup) {
    let ppAmount = 0;
    if (firstStepFields.get('total_plan_value')?.valid) {
      ppAmount = Math.round((firstStepFields.get('total_plan_value')?.value - firstStepFields.get('deposit')?.value) * 100) / 100;
    }
    return ppAmount;
  }

  public minTreatmentCostValidBNPL(treatmentCost: number) {
    return treatmentCost >= 1;
  }

  public checkRPRecordnPlanExist(firstStepGroup: UntypedFormGroup, urlParams: any): Observable<any> {
    // eslint-disable-next-line eqeqeq
    const rpDOB =
      typeof firstStepGroup.get('rp_dob')?.value == 'string'
        ? firstStepGroup.get('rp_dob')?.value
        : format(firstStepGroup.get('rp_dob')?.value, 'dd/MM/yyyy');

    const mobileNumber = firstStepGroup.get('rp_mobile_num')?.value;

    const data = {
      altname: firstStepGroup.get('rp_firstname')?.value + ' ' + firstStepGroup.get('rp_lastname')?.value,
      phone: mobileNumber,
      custentity_mfa_rp_rpdob: rpDOB,
      intDDrPlanType: firstStepGroup.get('plan_type')?.value
    };

    return this.paymentService.recordAndPlanCheck(urlParams, data);
  }

  public checkRPEligibility(firstStepGroup: UntypedFormGroup, urlParams: string): Observable<any> {
    // eslint-disable-next-line eqeqeq
    const rpDOB =
      typeof firstStepGroup.get('rp_dob')?.value == 'string'
        ? firstStepGroup.get('rp_dob')?.value
        : format(firstStepGroup.get('rp_dob')?.value, 'dd/MM/yyyy');
    const mobileNumber = firstStepGroup.get('rp_mobile_num')?.value;

    const data = {
      altname: firstStepGroup.get('rp_firstname')?.value + ' ' + firstStepGroup.get('rp_lastname')?.value,
      phone: mobileNumber,
      custentity_mfa_rp_rpdob: rpDOB
    };
    return this.paymentService.checkRPEligibility(urlParams, data);
  }

  // BNPL
  public calculateTreatmentFeesBnpl(firstStepGroup: UntypedFormGroup) {
    const quoteGroup = firstStepGroup.get('quote') as UntypedFormArray;
    if (firstStepGroup.get('no_of_payments')?.value && firstStepGroup.get('no_of_payments')?.value !== 0) {
      const plan_amount_by_no_of_payments = this.helperService.formatMoney(
        firstStepGroup.get('total_plan_value')?.value / firstStepGroup.get('no_of_payments')?.value
      );

      quoteGroup.controls.forEach((quote) => {
        this.calculatePaymentPlanAmount(firstStepGroup);

        let weeklyCost;
        let fortnightlyCost;
        let monthlyCost;

        if (firstStepGroup.get('payment_freq')?.value === '2') {
          weeklyCost = this.helperService.formatMoney(
            firstStepGroup.get('total_plan_value')?.value / (firstStepGroup.get('no_of_payments')?.value * 2)
          );
          fortnightlyCost = plan_amount_by_no_of_payments;
          monthlyCost = plan_amount_by_no_of_payments;
        } else {
          weeklyCost = plan_amount_by_no_of_payments;
          fortnightlyCost = this.helperService.formatMoney(
            firstStepGroup.get('total_plan_value')?.value / (firstStepGroup.get('no_of_payments')?.value / 2)
          );
          monthlyCost = plan_amount_by_no_of_payments;
        }
        quote.get('weekly')?.setValue(weeklyCost);
        quote.get('fortnightly')?.setValue(fortnightlyCost);
        quote.get('monthly')?.setValue(monthlyCost);
      });
    }
  }

  // return value based on plantype
  verifySourceType(planType: string | null, value: string, countryOfIssue: string): string {
    if (value === 'PASSPORT') {
      switch (planType) {
        case '3':
        case '2':
          return countryOfIssue.toUpperCase() === environment.defaultCountry.toUpperCase() ? 'LOCAL_PASSPORT' : 'VISA_PASSPORT';
        case '5':
          return 'LOCAL_PASSPORT';
      }
    } else {
      return value;
    }
    return '';
  }

  public verifyResponseType(status: string, isRegistered: string): { label: string; subLabel: string; buttonLabel: string } {
    if (isRegistered || isRegistered === 'true') {
      switch (status) {
        case 'IN_PROGRESS':
          return {
            label: 'Failed to verify',
            subLabel: 'Cannot verify your ID, please try again.',
            buttonLabel: 'Retry'
          };
        case 'VERIFIED':
        case 'VERIFIED_ADMIN':
        case 'VERIFIED_WITH_CHANGES':
          return {
            label: 'Successful ID verification',
            subLabel: '',
            buttonLabel: 'Continue'
          };
        case 'NONE':
        case 'PENDING':
        case 'LOCKED_OUT':
          return {
            label: 'Verification still pending',
            subLabel: 'Please continue filling in your details and we will attempt to verify you shortly.',
            buttonLabel: 'Continue'
          };
        default:
          return {
            label: '',
            subLabel: '',
            buttonLabel: ''
          };
      }
    }
    return {
      label: '',
      subLabel: '',
      buttonLabel: ''
    };
  }

  // NEW
  // TO DO:  Delete all old codes for plan calculation after integrating the new functions in support amendment and template
  isDentalProviderAndOrthoTreatment(treatmentType: string, planType: string): boolean {
    this.objUser = this.helperService.getObjUser();
    return this.objUser?.clinic_type === '1' && treatmentType === this.treatmentTypeOrtho.toString() && planType !== PlanType.Bnpl;
  }

  isUserHasBnplAccess(): boolean {
    return this.objUser?.tier_permissions && this.objUser?.tier_permissions?.custrecord_tier_bnpl;
  }

  getPlanTypes(): any[] {
    const planTypes = this.helperService.getObjFormFields()?.custrecord_mfa_ddr_plan_type;

    if (!this.isUserHasBnplAccess()) {
      return planTypes.filter((item: any) => item.internalid !== PlanType.Bnpl);
    }
    return planTypes;
  }
}
