<div *ngIf="mobile | async" [@slideInOut] class="sidebar">
  <div>
    <div class="sidebar-top-block">
      <a href="/" class="__logo">
        <img src="../../../../assets/images/{{ themePath }}/logo.svg" alt="Brand logo" />
      </a>
      <button (click)="closeSidebar()" class="btn sidebar-close">
        <i class="uil uil-times"></i>
      </button>
    </div>
    <ul class="main-menu" *ngIf="permission$ | async as permission">
      <li *ngIf="permission?.['/home']">
        <a
          routerLink="/home"
          routerLinkActive="is-active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="openMenu('home'); closeMenu(false)"
        >
          <i class="uil uil-estate"></i>
          <span>Home</span>
        </a>
      </li>
      <li *ngIf="permission?.['/payment-plan-summary']">
        <a
          routerLink="/payment-plan-summary"
          routerLinkActive="is-active"
          (click)="closeMenu(false); openMenu('payment')"
        >
          <i class="uil uil-bill"></i>
          <span>Payment plans</span>
        </a>
      </li>
      <li *ngIf="permission?.['/your-payments'] || permission?.['/invoices'] || permission?.['/payment-forecast']">
        <a class="d-flex justify-content-between" (click)="openMenu('financials')">
          <span class="d-flex align-items-center"> <i class="uil uil-chart-line"></i><span>Financials</span> </span>
          <i
            class="uil uil-angle-down"
            [class.is-active]="openMenuId !== 'financials'"
            [ngClass]="{ rotate: openMenuId === 'financials' }"
          ></i>
        </a>
        <ul [hidden]="openMenuId !== 'financials'">
          <li *ngIf="permission?.['/your-payments']">
            <a
              routerLink="financials/your-payments"
              routerLinkActive="is-active"
              (click)="closeMenu(false)"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span>Received payments</span>
            </a>
          </li>
          <li *ngIf="permission?.['/invoices']">
            <a routerLink="financials/invoices" routerLinkActive="is-active" (click)="closeMenu(false)">
              <span>Invoices</span>
            </a>
          </li>
          <!-- <li *ngIf="permission?.['/payment-forecast']">
            <a routerLink="financials/payment-forecast" routerLinkActive="is-active" (click)="closeMenu(false)">
              <span>Payment forecast</span>
            </a>
          </li> -->
        </ul>
      </li>
      <li *ngIf="permission?.['/templates']">
        <a routerLink="/templates" routerLinkActive="is-active">
          <i class="uil uil-file-alt"></i>
          <span>Templates</span>
        </a>
      </li>
      <li *ngIf="permission?.['/marketing-collateral']" [class.is-active]="openMenuId === 'marketing'">
        <a class="d-flex justify-content-between" (click)="openMenu('marketing')">
          <span class="d-flex align-items-center">
            <i class="uil uil-megaphone"></i>
            <span [innerHTML]="'marketing_centre' | lang: LANG_CONFIG_KEY | async"></span>
          </span>
          <i
            class="uil uil-angle-down"
            [class.is-active]="openMenuId !== 'marketing'"
            [ngClass]="{ rotate: openMenuId === 'marketing' }"
          ></i>
        </a>
        <ul [hidden]="openMenuId !== 'marketing'">
          <li>
            <a
              routerLink="/marketing-centre/provider-compliance"
              routerLinkActive="is-active"
              (click)="closeMenu(false)"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span [innerHTML]="'compliance' | lang: LANG_CONFIG_KEY | async"></span>
            </a>
          </li>
          <li>
            <a routerLink="/marketing-centre/provider-toolkit" routerLinkActive="is-active" (click)="closeMenu(false)">
              <span [innerHTML]="'toolkit' | lang: LANG_CONFIG_KEY | async"></span>
            </a>
          </li>
          <li>
            <a routerLink="/marketing-centre/patient-toolkit" routerLinkActive="is-active" (click)="closeMenu(false)">
              <span [innerHTML]="'patient_toolkit' | lang: LANG_CONFIG_KEY | async"></span>
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="permission?.['/support']" [class.is-active]="openMenuId === 'support'">
        <a class="d-flex justify-content-between" (click)="openMenu('support')">
          <span class="d-flex align-items-center">
            <i class="uil uil-question-circle"></i>
            <span>Support</span>
          </span>
          <i
            class="uil uil-angle-down"
            [class.is-active]="openMenuId !== 'support'"
            [ngClass]="{ rotate: openMenuId === 'support' }"
          ></i>
        </a>
        <ul [hidden]="openMenuId !== 'support'">
          <li *ngIf="permission?.['/support']">
            <a
              routerLink="/support"
              routerLinkActive="is-active"
              (click)="closeMenu(false)"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span>My requests</span>
            </a>
          </li>
          <li *ngIf="permission?.['/resources/latest-updates']">
            <a routerLink="support/latest-updates" (click)="closeMenu(false)" routerLinkActive="is-active">
              <span>Product updates</span>
            </a>
          </li>
          <!-- <li *ngIf="permission?.['/resources/help']">
            <a routerLink="/support/help" routerLinkActive="is-active" (click)="closeMenu(false)">
              <span>Help centre</span>
            </a>
          </li> -->
        </ul>
      </li>
      <li *ngIf="permission?.['/settings']">
        <a routerLink="/settings" routerLinkActive="is-active">
          <i class="uil uil-setting"></i>
          <span>Settings</span>
        </a>
      </li>
    </ul>
  </div>

  <button class="provider-btn" [matMenuTriggerFor]="providerMenu">
    <div class="details">
      <span class="practice-email">{{ user.email }}</span>
      <span class="practice-name">{{ user.name }}</span>
    </div>
    <div class="icon">
      <i class="uil uil-angle-down fs-20"></i>
    </div>
  </button>
  <mat-menu class="provider-menu" #providerMenu="matMenu">
    <button class="items" mat-menu-item [matMenuTriggerFor]="switchMenu">
      <i class="uil uil-file-alt fs-20 mr-2"></i>Switch practice
    </button>
    <button class="items" mat-menu-item (click)="logout()"><i class="uil uil-signout fs-20 mr-2"></i>Log out</button>
  </mat-menu>

  <mat-menu class="switch-practice-menu" #switchMenu="matMenu">
    <button
      mat-menu-item
      class="items"
      *ngFor="let provider of practiceList$ | async"
      [class.is-selected]="provider.id === selectedProviderId"
      (click)="changeProvider(provider)"
    >
      {{ provider?.name }} <i *ngIf="provider.id === selectedProviderId" class="uil uil-check fs-20"></i>
    </button>
  </mat-menu>
</div>
<div class="sidebar-overflow" *ngIf="mobile | async" (click)="closeSidebar()"></div>
