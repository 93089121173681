<form [formGroup]="searchForm">
  <div class="row">
    <div class="col-6 col-md-6">
      <div class="form-group search-date-group">
        <i class="uil uil-calendar-alt" (click)="from.toggle()"></i>
        <div class="input-group l-datepicker">
          <input
            #from="ngbDatepicker"
            (click)="from.toggle()"
            [class.is-invalid]="searchForm.get('from')?.invalid"
            class="form-control"
            formControlName="from"
            [maxDate]="maxDateValidator"
            name="dp"
            ngbDatepicker
            [placeholder]="config.fromPlaceholder || 'From'"
          />
        </div>
        <i class="uil uil-angle-down cursor-pointer" (click)="from.toggle()"></i>
      </div>
    </div>
    <div class="col-6 col-md-6 pl-0">
      <div class="form-group search-date-group">
        <i class="uil uil-calendar-alt" (click)="to.toggle()"></i>
        <div class="input-group l-datepicker">
          <input
            #to="ngbDatepicker"
            (click)="to.toggle()"
            [class.is-invalid]="searchForm.get('to')?.invalid"
            class="form-control"
            formControlName="to"
            [minDate]="minDateValidator"
            ngbDatepicker
            [placeholder]="config.toPlaceholder || 'To'"
          />
        </div>
        <i class="uil uil-angle-down cursor-pointer" (click)="to.toggle()"></i>
      </div>
    </div>
  </div>
</form>
