import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppButton } from '@app/shared/interfaces/button.interface';

@Component({
  selector: 'sliqpay-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input()
  config!: AppButton | null;
  @Input()
  loading: boolean | null = false;
  @Input()
  disabled: boolean | null = false;
  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  isPrimary = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disabled) {
      this.disabled = changes?.disabled?.currentValue;
    }

    if (changes?.loading) {
      this.loading = changes?.loading?.currentValue;
    }
  }

  ngOnInit(): void {
    if (this.config?.class.includes('btn-grey')) {
      this.isPrimary = false;
    }
  }

  onClick(): void {
    if (!this.disabled || !this.loading) {
      this.clicked.emit(true);
    }
  }
}
