import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { Observable } from 'rxjs';

@Injectable()
export class PendingPlansService {
  private CONFIG_KEY = 'pending_payments_page';

  constructor(private configService: ConfigService) {}

  getSearchWidgetConfig(): Observable<SearchConfig[]> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'search_widget');
  }
}
