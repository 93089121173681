import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class ForgotPasswordPageService {
  FORGOT_PWORD_CONFIG_KEY = 'forgot_password_page';

  constructor(private configService: ConfigService) {}

  getEmailConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.FORGOT_PWORD_CONFIG_KEY, 'email');
  }

  getBackBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.FORGOT_PWORD_CONFIG_KEY, 'back_btn');
  }

  getSendBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.FORGOT_PWORD_CONFIG_KEY, 'send_btn');
  }
}
