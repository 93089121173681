import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {
  toBase64(file: File): Observable<any> {
    if (file?.name) {
      return new Observable<any>((observer) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          observer.next(reader.result);
          observer.complete();
        };
        reader.onerror = (error) => {
          observer.error(error);
          observer.complete();
        };
      });
    }
    return of(null);
  }
}
