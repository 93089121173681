<sliqpay-dropdown
  class="primary-color-dropdown"
  [class.create-proposal]="moduleType === ModuleType.Proposal"
  [config]="createDdConfig$ | async"
  [items]="planTypes$ | async"
  [isFormGroup]="false"
  [setDefaultIfOneItem]="false"
  (changed)="onDropdownChange($event)"
>
</sliqpay-dropdown>
