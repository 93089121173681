<div class="d-flex justify-content-between align-items-center">
  <div class="w-50">
    <pagination-controls
      (pageChange)="page = $event; pageChange.emit($event)"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>
  <sliqpay-dropdown
    [config]="listingDdConfig$ | async"
    [items]="pageSizes || []"
    [selectedValue]="itemPerPage"
    [isFormGroup]="false"
    [isSideLabel]="true"
    (changed)="handlePageSizeChange($event)"
  >
  </sliqpay-dropdown>
</div>
