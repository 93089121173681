import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { MenuItem } from '@app/shared/interfaces/three-dot-action-menu.interface';

@Component({
  selector: 'sliqpay-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuPanelComponent {
  @Input() items: MenuItem[] = [];
  @Output() itemClicked = new EventEmitter<any>();

  @ViewChild('menu', { static: true }) public menu!: MatMenu;

  onItemClick(value: string): void {
    this.itemClicked.emit(value);
  }
}
