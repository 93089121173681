import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { Observable } from 'rxjs';

@Injectable()
export class PaginationService {
  private CONFIG_KEY = 'pagination';

  constructor(private configService: ConfigService) {}

  getListingDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'listing_dd');
  }
}
