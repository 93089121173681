import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissedPaymentsService } from './services/missed-payments.service';

@Component({
  selector: 'sliqpay-missed-payments',
  templateUrl: './missed-payments.component.html',
  styleUrls: ['./missed-payments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
export class MissedPaymentsComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject();

  constructor(private missedPaymentsService: MissedPaymentsService) {}

  ngOnInit(): void {
    this.getAllMissedPayments$();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  getAllMissedPayments$(): void {
    this.missedPaymentsService.fetchMissedPayments$().pipe(takeUntil(this.destroyed$)).subscribe();
  }
}
