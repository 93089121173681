export enum SmsJourneyStep {
  SaveAsDraft = 'saveAsDraft',
  SmsSent = 'smsSent',
  TreatmentSelection = 'treatmentOptions',
  TreatmentDetails = 'treatmentOptionsPaymentFlow',
  PracticeDocument = 'documentation',
  PlanDetails = 'planDetails',
  Compliance = 'compliance',
  IdVerification = 'idVerification',
  PaymentForDeposit = 'deposit',
  BasiqVerification = 'basiq',
  PaymentMethod = 'paymentMethod',
  PaymentSummary = 'paymentSummary'
}
