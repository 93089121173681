import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';

export class CustomDateAdapter extends MomentDateAdapter {
  parse(value: any, parseFormat: string | string[]): Moment | null {
    const dateFormat: string | string[] = sessionStorage.getItem('dateFormatMaterial') || parseFormat;
    return super.parse(value, dateFormat);
  }

  format(date: Moment, displayFormat: string): string {
    const dateFormat: string | string[] = sessionStorage.getItem('dateFormatMaterial') || displayFormat;
    return super.format(date, dateFormat);
  }
}

export const MY_FORMATS = {
  parse: {
    dateInput: sessionStorage.getItem('date_format')
  },
  display: {
    dateInput: sessionStorage.getItem('date_format'),
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
