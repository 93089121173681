import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { ConverterService } from '@app/core/services/converter.service';
import { SettingDataService } from '@app/core/data-services/settings/settings-data.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class SettingsAccountService implements OnDestroy {
  private CONFIG_KEY = 'settings_account';

  unsubscribe$ = new Subject();

  constructor(
    private configService: ConfigService,
    private settingService: SettingDataService,
    private converterService: ConverterService
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSaveChangesBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'saved_changes_btn');
  }

  getChangePwordBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'change_pword_btn');
  }

  getPracticeIdConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'practice_id');
  }

  getProviderNameConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'provider_name');
  }

  getPracticeManagerConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'practice_manager');
  }

  getRegDateConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'reg_date');
  }

  getPhoneNumberConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'phone_number');
  }

  getEmailConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'email');
  }

  getPaymentDatesConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'payment_dates');
  }

  getAbnConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'abn');
  }

  getWebsiteConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'website');
  }

  getClinicTypeConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'clinic_type');
  }

  getManagerPhoneConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'manager_phone');
  }

  updateProviderSettings(phone: string, website: string, file: File) {
    const payload: any = {
      phone,
      url: website,
      trustee_file_name: file?.name ? file?.name : undefined,
      trustee_file_content: null,
      trustee_file_ext: file?.name ? file?.name.split('.').pop() : undefined
    };

    if (file?.name) {
      return this.converterService.toBase64(file).pipe(
        takeUntil(this.unsubscribe$),
        switchMap((data: any) => {
          payload.trustee_file_content = data;
          return this.settingService.postUpdateProviderSettings(payload);
        })
      );
    } else {
      payload.trustee_file_content = undefined;
    }

    return this.settingService.postUpdateProviderSettings(payload);
  }
}
