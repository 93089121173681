import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsChangeAccessService {
  private CONFIG_KEY = 'settings_change_access';

  constructor(private configService: ConfigService) {}

  getNameConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'name');
  }

  getEmailConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'email');
  }

  getAccessConfig(): Observable<ChangeAccess[]> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'access');
  }

  getPermissionsConfig(): Observable<ChangeAccess[]> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'permissions');
  }

  getAccessDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'access_dd');
  }

  getPermissionsDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'permissions_dd');
  }

  getSaveBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'save_btn');
  }
}

export interface ChangeAccess {
  internalId: string;
  name: string;
}
