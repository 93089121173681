import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { ChangePasswordRequest, ChangePasswordResponse, ForgotPasswordRequest, GenericListResponse } from '@core/models';
import { ApiUrlService } from '@core/services/api-url.service';
import { HelperService } from '@core/services/helper.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public refFlag = false;

  readonly user$ = new ReplaySubject<any | null>(1);
  readonly providers$ = new ReplaySubject<any | null>(1);

  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService,
    private apiUrlService: ApiUrlService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  login(request: { email: string; password: string }): Observable<any> {
    const body = {
      ...request,
      company_brand: environment.company_brand,
      device_info: [this.deviceService.getDeviceInfo()]
    };

    return this.httpClient.post<any>(`${this.apiUrlService.loginUrl}`, body, { observe: 'response' }).pipe(
      filter((response) => {
        return !!response;
      }),
      map((response) => {
        this.user$.next(response.body.data[0]);
        this.providers$.next(response.body.data[0].providers);
        this.setAuth(response.headers, response.body.data[0]);
        return response.body.data[0];
      })
    );
  }

  logout() {
    return this.httpClient.post(`${this.apiUrlService.logoutUrl}`, {}).pipe(
      catchError((error: HttpErrorResponse) => {
        sessionStorage.clear();
        this.router.navigate(['login']);
        return throwError(error);
      })
    );
  }

  forgotPassword(request: ForgotPasswordRequest) {
    return this.httpClient.post(`${this.apiUrlService.forgotPasswordUrl}`, {
      ...request,
      company_brand: environment.company_brand
    });
  }

  authValid() {
    return sessionStorage.getItem('auth') !== null;
  }

  checkTokenExpiredForRefresh() {
    return new Date().getSeconds() >= parseInt(sessionStorage.getItem('auth_expires') as string, 10);
  }

  checkToken(): boolean {
    // If the currently stored token is valid, return true, and also refresh if it expires soon, else return false
    if (this.authValid()) {
      if (this.checkTokenExpiredForRefresh()) {
        this.refreshToken();
        this.refFlag = true;
      }
      return true;
    } else {
      // console.log("Stored authentication invalid");
      return false;
    }
  }

  hasPermissionToAccess(route: any) {
    if (!this.currentUser()) {
      return false;
    }

    const permissions = this.currentUser().permissions;

    // Checks against permissions with a prepended slash always
    const routeCheck = route.substring(0, 1) === '/' ? route : `/${route}`;

    if (routeCheck === '/login/select-provider') {
      return true;
    }
    if (permissions && permissions[routeCheck]) {
      return true;
    }

    // console.log(this.currentUser().permissions);
    // console.log(Object.keys(permissions));

    // Convert :ID into a wildcard and check for user permissions to view these pages
    let hasExtendedPermission = false;
    if (permissions) {
      for (const slug of Object.keys(permissions)) {
        const rx = RegExp(`/?${slug.replace('/', '/').replace(':ID', '.*$')}`);

        hasExtendedPermission = hasExtendedPermission || (rx.test(routeCheck) && permissions[slug]);
      }
    }
    return hasExtendedPermission;
  }

  refreshToken() {
    if (!this.refFlag) {
      this.httpClient.post(`${this.apiUrlService.refreshUrl}`, {}, { observe: 'response' }).pipe(
        map((data: any) => {
          if (this.helperService.checkAPIResponse(data.body)) {
            data.body = this.helperService.getAPIDataResponse(data.body)[0];
            this.setAuthHeader(data.headers);
          }

          sessionStorage.setItem('auth_expires', parseInt(data.body.expiresIn, 10).toString());

          this.refFlag = false;
          return data.headers.get('accesstoken');
        }),
        catchError((error) => {
          console.error('refresh token unsuccessful', error);
          return throwError(error);
        })
      );
    }
  }

  getProviderSet() {
    return sessionStorage.getItem('provider') !== null && sessionStorage.getItem('provider') !== '';
  }

  getCurrentProvider() {
    const user = this.currentUser();
    return user.providers.find((provider: any) => provider.name === user.name)?.id || false;
  }

  changePassword(request: ChangePasswordRequest): Observable<GenericListResponse<ChangePasswordResponse, any>> {
    return this.httpClient.post<GenericListResponse<any, any>>(`${this.apiUrlService.changePasswordUrl}`, request);
  }

  fetchFields() {
    return this.httpClient.get(this.apiUrlService.formFieldsUrl).pipe(
      map((data: any) => {
        sessionStorage.removeItem('form_fields');
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data)[0];
          sessionStorage.setItem('form_fields', JSON.stringify(data.values));
          return data.values;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('fetchFields unsuccessful', error);
        return throwError(error);
      })
    );
  }

  getRulesJsonFromAPI() {
    return this.httpClient.get(this.apiUrlService.rulesJsonUrl).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.error('getRulesJsonFromAPI unsuccessful', error);
        return throwError(error);
      })
    );
  }

  async setRulesJson(router: Router) {
    if (environment.apimKey !== '') {
      // Non local
      this.getRulesJsonFromAPI().subscribe((data) => {
        sessionStorage.setItem('rules_json', JSON.stringify(data));
        this.maintenanceRerouting(router);
        return data;
      });
    } else {
      // Local
      sessionStorage.setItem('rules_json', JSON.stringify(environment.rules_json));
      this.maintenanceRerouting(router);
    }
  }

  getPDF(fileName: string) {
    const body = {
      fileName
    };
    return this.httpClient.post(this.apiUrlService.getPdfUrl, body).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data)[0];
          return data;
        }
      }),
      catchError((error) => {
        return error;
      })
    );
  }

  public getCurrentToken() {
    return sessionStorage.getItem('auth');
  }

  public setProvider(id: string) {
    return this.httpClient.post<any>(`${this.apiUrlService.setProviderUrl}`, { 'provider-id': id }).pipe(
      map((response) => {
        sessionStorage.setItem('provider', response.data[0]['providerId']);
        sessionStorage.setItem('user', JSON.stringify(response.data[0]));
        return response;
      })
    );
  }

  public currentUser() {
    return sessionStorage.getItem('user') !== null && sessionStorage.getItem('form_fields') !== ''
      ? this.helperService.getObjUser()
      : false;
  }

  public setAuthHeader(dataHeaders: any) {
    sessionStorage.setItem('auth', dataHeaders.get('accesstoken'));
    sessionStorage.setItem('refreshtoken', dataHeaders.get('refreshtoken'));
  }

  public setAuthBody(dataBody: any) {
    sessionStorage.setItem('user', JSON.stringify(dataBody));
    sessionStorage.setItem('providers', JSON.stringify(dataBody.providers));
  }

  public canModifyData() {
    const permissions = this.currentUser().permissions;

    return permissions['/settings'];
  }

  public getRulesJson() {
    if (environment.apimKey !== '') {
      // Non local
      return JSON.parse(sessionStorage.getItem('rules_json') || '');
    } else {
      // Local
      return environment.rules_json;
    }
  }

  public getProductRules(key: string) {
    try {
      return this.getRulesJson().product.portal[environment.company_brand.toLowerCase()][key];
    } catch (error) {}
  }

  public maintenanceRerouting(router: any) {
    // Detect and show maintenance page
    if (this.getGlobalRules('maintenance_mode') === true || this.getProductRules('maintenance_mode') === true) {
      this.router.navigate(['/maintenance']);
    } else {
      // avoid loop navigation
      if (router.url.includes('maintenance')) {
        router.navigate(['/login']);
      }
    }
  }

  public getGlobalRules(key: string) {
    try {
      return this.getRulesJson().global[key];
    } catch (error) {}
  }

  public canAccess(route: any) {
    return this.hasPermissionToAccess(route);
  }

  private setAuth(dataHeaders: any, dataBody: any) {
    this.setAuthHeader(dataHeaders);
    sessionStorage.setItem('auth_expires', parseInt(dataBody.expiresIn, 10).toString());
    this.setAuthBody(dataBody);
  }
}
