import { Injectable } from '@angular/core';
import {
  ProposalTemplateDataService,
  SaveTemplateProposalResponse
} from '@app/core/data-services/proposal-template/proposal-template-data.service';
import { GetProposalTemplateResponse } from '@app/templates/models/get-proposal-template.response';
import { ProposalTextSection, SaveProposalTemplateRequest } from '@app/templates/models/save-proposal-template.request';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ImageService } from '../image/image.service';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalTemplateService {
  private isLoadedSubject = new BehaviorSubject<boolean>(false);
  private proposalTemplateSubject = new BehaviorSubject<GetProposalTemplateResponse>({
    id: '',
    customrecord_templateproposal_txtsection: [],
    custrecord_asset_footer: '',
    custrecord_asset_header: '',
    custrecord_asset_logo: '',
    custrecord_introtxt_printout: '',
    custrecord_introtxt_sms: '',
    custrecord_practice_notes: ''
  });

  constructor(
    private proposalTemplateDataService: ProposalTemplateDataService,
    private imageService: ImageService,
    private notifService: NotificationService
  ) {}

  init(): void {
    this.proposalTemplateDataService
      .getProposalTemplate$()
      .pipe(
        switchMap((response) => {
          if (response.data[0]) {
            return of(response.data[0]);
          }
          return this.getDefaultProposalTemplate$();
        })
      )
      .subscribe((proposalTemplate: GetProposalTemplateResponse) => {
        this.proposalTemplateSubject.next(proposalTemplate);
        this.isLoadedSubject.next(true);
      });
  }

  getProposalTemplate$(): Observable<GetProposalTemplateResponse> {
    return this.proposalTemplateSubject;
  }

  getProposalTemplate(): GetProposalTemplateResponse {
    return this.proposalTemplateSubject.getValue();
  }

  getPracticeDocuments$(): Observable<ProposalTextSection[]> {
    return this.proposalTemplateSubject.pipe(map((template) => template.customrecord_templateproposal_txtsection));
  }

  getPracticeDocumentIds(): string[] {
    const templateSnapshot = this.proposalTemplateSubject.getValue();
    const practiceDocuments = templateSnapshot.customrecord_templateproposal_txtsection;
    return practiceDocuments.map((doc) => doc.id);
  }

  isLoaded$(): Observable<boolean> {
    return this.isLoadedSubject;
  }

  upsertProposalTemplate(body: SaveProposalTemplateRequest): Observable<SaveTemplateProposalResponse> {
    return this.proposalTemplateDataService.saveProposalTemplate(body).pipe(
      map((response) => {
        return {
          templateProposalId: response.data[0].templateProposalId,
          message: response.data[0].message
        };
      }),
      tap((res) => {
        this.notifService.success(res.message, 'Saved');
      })
    );
  }

  private getDefaultProposalTemplate$(): Observable<GetProposalTemplateResponse> {
    return forkJoin([this.imageService.getImageAsBase64(), this.getDefaultProposalProviderLogo$()]).pipe(
      map(([defaultBanner, defaultLogo]) => ({
        id: '',
        customrecord_templateproposal_txtsection: [],
        custrecord_asset_footer: defaultBanner,
        custrecord_asset_header: defaultBanner,
        custrecord_asset_logo: defaultLogo,
        custrecord_introtxt_printout: '',
        custrecord_introtxt_sms: '',
        custrecord_practice_notes: ''
      }))
    );
  }

  private getDefaultProposalProviderLogo$(): Observable<string> {
    return this.imageService.getImageAsBase64(`assets/images/${environment.client}/default-proposal-logo.png`);
  }
}
