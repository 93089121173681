<div *ngIf="config">
  <button
    #button
    [type]="config.type"
    [disabled]="disabled"
    [class]="config.class"
    [class.loading-primary]="loading && isPrimary"
    [class.loading-grey]="loading && !isPrimary"
    (click)="onClick()"
  >
    <sliqpay-dot-loader *ngIf="loading; else showText"></sliqpay-dot-loader>
    <ng-template #showText>
      <i *ngIf="config?.icon" [class]="'uil ' + config.icon"></i> {{ config.label }}
    </ng-template>
  </button>
</div>
