import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';

@Component({
  selector: 'sliqpay-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DropdownComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() config!: AppDropdown | null;
  @Input() items!: Array<any> | null;
  @Input() selectedValue!: any;
  @Input() isFormGroup = true; // Default ng-select as form-group
  @Input() isSideLabel = false; // If label of the dropdown is on the left side
  @Input() searchFn: any = null;
  @Input() setDefaultIfOneItem = true;
  @Input() refreshDdAfterSelect = false; // For simple dd, if true, it will set the value of the dropdown to null after every select
  @Output() changed = new EventEmitter();
  @Output() cleared = new EventEmitter();
  @Output() searched = new EventEmitter();

  onChange!: (value: any) => void;
  onTouched!: () => void;

  form!: UntypedFormGroup;

  get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  get invalid(): boolean | null {
    return this.control ? this.control.invalid : false;
  }

  get readonly(): boolean {
    return this.config?.readonly ? this.config?.readonly : false;
  }

  get disabled(): boolean {
    return this.control?.disabled ? this.control?.disabled : false;
  }

  get placeholder(): string {
    return this.config?.placeholder ? this.config?.placeholder : 'Select';
  }

  get bindLabel(): string {
    return this.config?.bindLabel ? this.config?.bindLabel : '';
  }

  get bindValue(): string {
    return this.config?.bindValue ? this.config?.bindValue : '';
  }

  get clearable(): boolean {
    return this.config?.clearable !== undefined ? this.config?.clearable : true;
  }

  get multiple(): boolean {
    return this.config?.multiple ? this.config?.multiple : false;
  }

  get searchable(): boolean {
    return this.config?.searchable !== undefined ? this.config?.searchable : true;
  }

  get isRequired(): boolean {
    return this.control?.control?.hasValidator(Validators.required) || false;
  }

  constructor(@Self() @Optional() public control: NgControl, private fb: UntypedFormBuilder) {
    if (this.control) {
      this.control.valueAccessor = this;
    }

    this.form = this.fb.group({
      dropdown: new UntypedFormControl(null)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.setDefaultValueForOneItem();
    }
  }

  writeValue(value: any): void {
    this.form.get('dropdown')?.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.get('dropdown')?.disable();
    } else {
      this.form.get('dropdown')?.enable();
    }
  }

  ngOnInit(): void {
    if (this.selectedValue) {
      this.form.get('dropdown')?.setValue(this.selectedValue);
    }

    this.setDefaultValueForOneItem();
  }

  onDropdownChange(event: any): void {
    if (this.multiple) {
      let items: any = [];
      if (Array.isArray(event)) {
        event.forEach((x: any) => {
          items.push(x[this.bindValue]);
        });
      } else {
        if (this.bindValue) {
          items = event ? [event[this.bindValue]] : [];
        } else {
          items = [event];
        }
      }

      this.onChange(items);
      this.changed.emit(items);
    } else {
      let value;
      if (this.bindValue) {
        value = event ? event[this.bindValue] : null;
      } else {
        value = event;
      }

      this.form.get('dropdown')?.setValue(value);
      this.onChange(value);
      this.changed.emit(value);
    }
  }

  onSimpleDdChange(event: any): void {
    const value = this.bindValue ? event[this.bindValue] : event;
    this.changed.emit(value);

    if (this.refreshDdAfterSelect) {
      setTimeout(() => {
        // Reset the ng-select value after selection
        this.selectedValue = null;
      });
    }
  }

  onClear(): void {
    this.cleared.emit();
  }

  onSearch(event: any): void {
    this.searched.emit(event);
  }

  onDropdownTouched(): void {
    this.onTouched();
  }

  private setDefaultValueForOneItem(): void {
    if (this.setDefaultIfOneItem) {
      // Set the default value if item is only 1
      if (this.items?.length === 1) {
        if (this.isFormGroup) {
          this.onDropdownChange(this.items[0]);
          this.control.control?.markAsPristine();
        } else {
          this.selectedValue = this.items[0][this.bindValue];
          this.onSimpleDdChange(this.items[0]);
        }
      }
    }
  }
}
