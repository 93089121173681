import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import parse from 'date-fns/parse';
import parsePhoneNumber from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';

const numbersValidation = '^[0-9]+$';
const currencyValidation = '[0-9]+(\\.[0-9][0-9]?)?';
const emailValidation = '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$';
const ccExpiryValidation = '^(0[1-9]|1[0-2]|[1-9])/([2][0-9])$';
const nameValidation = "([a-zA-Z][-,a-zA-z. ']+[ ]*)+";
const urlValidation = '^(http|https)://[^ "]+$';

export const MAX_FILE_SIZE = 10000000; // 10MB

export const DDRRegex = {
  getNumbersValidation: () => numbersValidation,
  getCurrencyValidation: () => currencyValidation,
  getEmailValidation: () => emailValidation,
  getCCExpiryValidation: () => ccExpiryValidation,
  getNameValidation: () => nameValidation,
  getUrlValidation: () => urlValidation
};

export const termMonthsValidator = (control: AbstractControl) => {
  const term_months_value = control.value % 1;
  try {
    if (term_months_value === 0) {
      return null;
    } else {
      return { hasDecimal: true };
    }
  } catch (error) {
    return { hasDecimal: true };
  }
};

export const isDOBMinYearsValidator = (minAllowedAge: number) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      const minAllowedDate = new Date(new Date().getFullYear() - minAllowedAge, new Date().getMonth(), new Date().getDate());

      let currentValue = control.value;
      if (typeof currentValue === 'string') {
        currentValue = parse(control.value, 'dd/MM/yyyy', new Date());
      }

      // Set both time to 0 since we are just comparing dates
      currentValue.setHours(0, 0, 0, 0);
      minAllowedDate.setHours(0, 0, 0, 0);

      if (currentValue) {
        const selectedDate = new Date(currentValue.getFullYear(), currentValue.getMonth(), currentValue.getDate());
        if (selectedDate > minAllowedDate) {
          return { isDOBMinYears: true };
        }
      }
    }

    return null;
  };
};

export const isDOBMinYearsTodayValidator = (control: AbstractControl) => {
  if (control.value) {
    const minAllowedDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    let currentValue = control.value;
    if (typeof currentValue === 'string') {
      currentValue = parse(control.value, 'dd/MM/yyyy', new Date());
    }

    // Set both time to 0 since we are just comparing dates
    currentValue.setHours(0, 0, 0, 0);
    minAllowedDate.setHours(0, 0, 0, 0);

    if (currentValue) {
      const selectedDate = new Date(currentValue.getFullYear(), currentValue.getMonth(), currentValue.getDate());
      if (selectedDate > minAllowedDate) {
        return { isDOBMinYearsToday: true };
      }
    }
  }

  return null;
};

export const mobileValidator = (control: AbstractControl) => {
  if (control.value) {
    const phoneNumber = parsePhoneNumber(control.value, environment.defaultCountry as CountryCode);
    return phoneNumber?.isValid() ? null : { invalidPhoneNumber: true };
  }
  return null;
};

export const patient_first_name_validator = [Validators.required, Validators.maxLength(140)];

export const patient_last_name_validator = [Validators.required, Validators.maxLength(140)];

export const custrecord_mfa_ddr_single_payments_validator = [Validators.required];

export const rp_first_name_validator = [Validators.required, Validators.minLength(1), Validators.maxLength(140)];

export const rp_last_name_validator = [Validators.required, Validators.minLength(1), Validators.maxLength(140)];

export const occupation_validator = [Validators.required, Validators.minLength(1), Validators.maxLength(300)];

export const rp_mobile_num_validator = [Validators.required, Validators.pattern(DDRRegex.getNumbersValidation()), mobileValidator];

export const rp_home_num_validator = [
  Validators.required,
  Validators.minLength(10),
  Validators.maxLength(21),
  Validators.pattern(DDRRegex.getNumbersValidation())
];

export const rp_address_validator = [Validators.required, Validators.minLength(1), Validators.maxLength(300)];

export const rp_suburb_validator = [Validators.required, Validators.minLength(1), Validators.maxLength(300)];

export const rp_postcode_validator = [Validators.required, Validators.minLength(1), Validators.maxLength(300)];

export const rp_email_validator = [Validators.required, Validators.pattern(DDRRegex.getEmailValidation())];

export const start_date_validator = [Validators.required, Validators.pattern(DDRRegex.getCCExpiryValidation())];

export const minDateValidator = (minDate: Date): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      let currentValue = control.value;
      if (typeof currentValue === 'string') {
        currentValue = parse(control.value, 'dd/MM/yyyy', new Date());
      }

      // Set both time to 0 since we are just comparing dates
      currentValue.setHours(0, 0, 0, 0);
      minDate.setHours(0, 0, 0, 0);

      if (currentValue < minDate) {
        return { valueLessThanMinDate: true };
      }
    }

    return null;
  };
};

export const maxDateValidator = (maxDate: any): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      let currentValue = control.value;
      if (typeof currentValue === 'string') {
        currentValue = parse(control.value, 'dd/MM/yyyy', new Date());
      }

      // Set both time to 0 since we are just comparing dates
      currentValue.setHours(0, 0, 0, 0);
      maxDate.setHours(0, 0, 0, 0);

      if (currentValue > maxDate) {
        return { valueGreaterThanMaxDate: true };
      }
    }
    return null;
  };
};

// to be removed
export const custrecord_mfa_ddr_patient_email_validator = [Validators.required, Validators.pattern(DDRRegex.getEmailValidation())];

// to be removed
export const custrecord_mfa_ddr_patient_mobile_validator = [
  Validators.required,
  Validators.minLength(7),
  Validators.maxLength(21),
  Validators.pattern(DDRRegex.getNumbersValidation())
];

export const idExpiryDateValidator = (control: AbstractControl) => {
  try {
    const today = new Date();

    if (control.value) {
      if (control.value > today) {
        return null;
      } else {
        return { idExpiryDateGreaterThanToday: true };
      }
    } else {
      return null;
    }
  } catch (error) {
    return { idExpiryDateGreaterThanToday: true };
  }
};

export const account_holder_validator = [
  Validators.required,
  Validators.minLength(1),
  Validators.maxLength(300),
  Validators.pattern(DDRRegex.getNameValidation())
];

export const account_num_validator = [
  Validators.required,
  Validators.minLength(1),
  Validators.maxLength(9),
  Validators.pattern(DDRRegex.getNumbersValidation())
];

export const bsb_validator = [
  Validators.required,
  Validators.minLength(6),
  Validators.maxLength(6),
  Validators.pattern(DDRRegex.getNumbersValidation())
];

export const bank_name_validator = [Validators.required, Validators.minLength(1), Validators.maxLength(300)];

export const cc_payment_method_validator = [Validators.required];

export const otp_code_validator = [
  Validators.required,
  Validators.minLength(6),
  Validators.maxLength(6),
  Validators.pattern(DDRRegex.getNumbersValidation())
];

export const medicareNo = (control: AbstractControl) => {
  const id_no = control.value;

  try {
    if (id_no.toString().length < 10) {
      return {
        isLessLength: true
      };
    }
    if (!id_no) {
      return { required: true };
    }
    return false;
  } catch (err) {
    return {
      invalid: true
    };
  }
};

export const fileSizeValidator = (control: AbstractControl) => {
  const file = control.value;
  if (file) {
    if (file.size > MAX_FILE_SIZE) {
      return {
        fileSizeValidator: true
      };
    } else {
      return null;
    }
  }
  return null;
};

export const maxRepaymentAmountValidator = (monthlyAmount: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const value = control.value;

    if (!value || monthlyAmount <= value) {
      return null;
    }

    return { maxRepaymentAmount: true };
  };
};
