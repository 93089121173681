import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core/core.module';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { CreateNewPasswordComponent } from './components/create-new-password/create-new-password.component';
import { ProviderPageComponent } from './pages/provider-page/provider-page.component';
import { SharedModule } from '@app/shared/shared.module';
import { LoginService } from './pages/login-page/login-page.service';
import { ForgotPasswordPageService } from './pages/forgot-password-page/forgot-password-page.service';
import { CreateNewPasswordService } from './components/create-new-password/create-new-password.service';

@NgModule({
  declarations: [LoginPageComponent, ForgotPasswordPageComponent, CreateNewPasswordComponent, ProviderPageComponent],
  imports: [CommonModule, CoreModule, AuthRoutingModule, ReactiveFormsModule, SharedModule],
  providers: [LoginService, ForgotPasswordPageService, CreateNewPasswordService]
})
export class AuthModule {}
