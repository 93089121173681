export enum Brand {
  Dc = 'DentiCare',
  Mhb = 'MyHealth&Beauty',
  Mhc = 'MyHealthCare'
}

export enum EnvCompanyBrand {
  DC = 'DENTICARE',
  MHB = 'MYHEALTH_BEAUTY',
  MHC = 'MYHEALTH_CARE'
}
