import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class SettingChangePasswordService {
  private CONFIG_KEY = 'settings_change_pword';

  constructor(private configService: ConfigService) {}

  getChangePwordBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'change_pword_btn');
  }

  getCurrentPwordConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'current_pword');
  }

  getNewPwordConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'new_pword');
  }

  getConfirmPwordConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'confirm_pword');
  }
}
