import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as LangConfigActions from '../actions/lang-config.action';

interface AppState {
  langConfig: any;
}

@Injectable({
  providedIn: 'root'
})
export class LangConfigSelector {
  constructor(private store: Store<AppState>) {}

  getLangConfig(): Observable<any> {
    return this.store.select('langConfig');
  }

  setLangConfig(langConfig: any): void {
    this.store.dispatch(new LangConfigActions.UpdateLangConfig(langConfig));
  }
}
