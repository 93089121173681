<div class="modal-container">
  <div class="close-block">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>

  <h4 class="modal-title text-center">Do you wish to copy this {{ recordType }}?</h4>
  <p class="modal-body px-3 text-center">
    The Responsible Party has accessed the link to this {{ recordType }} and you can no longer edit the details. Do you wish to
    create a copy of this pending {{ recordType}} to modify and re-send to the Responsible Party?
    <br/>
    <small
      >(Please note this will advise the Responsible Party their {{ recordType }} has been updated and they will receive
      a new link shortly).</small
    >
  </p>

  <div class="actions">
    <button class="btn btn-light" (click)="activeModal.close()">Cancel</button>
    <button class="btn btn-primary" (click)="activeModal.close(true)">Copy {{ recordType }}</button>
  </div>
</div>
