import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/auth/auth.guard';
import { AllPlansComponent } from './all-plans/all-plans.component';
import { ApprovalPlansComponent } from './approval-plans/approval-plans.component';
import { PendingPlansComponent } from './pending-plans/pending-plans.component';
import { MissedPaymentsComponent } from './missed-payments/missed-payments.component';
import { MissedPaymentsListComponent } from './missed-payments/list/missed-payments-list/missed-payments-list.component';
import { MissedPaymentsDetailsComponent } from './missed-payments/details/missed-payments-details.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AllPlansComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'missed-payments',
    component: MissedPaymentsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'list',
        component: MissedPaymentsListComponent
      },
      {
        path: 'details/:isMissedPayment/:ddrID',
        component: MissedPaymentsDetailsComponent
      }
    ]
  },
  {
    path: 'pending',
    component: PendingPlansComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approval',
    component: ApprovalPlansComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule {}
