import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@core/core.module';
import { SettingsRoutingModule } from '@settings/settings-routing.module';
import { SettingActionMenuComponent } from './components/setting-action-menu/setting-action-menu.component';
import { SettingsChangeAccessComponent } from './components/setting-change-access/settings-change-access.component';
import { SettingsChangePasswordComponent } from './components/setting-change-password/settings-change-password.component';
import { SettingsAccountComponent } from './components/settings-account/settings-account.component';
import { SettingsTncComponent } from './components/settings-tnc/settings-tnc.component';
import { SettingsUsersComponent } from './components/settings-users/settings-users.component';
import { SettingsService } from './services/settings.service';
import { SettingsComponent } from './settings.component';

@NgModule({
  declarations: [
    SettingActionMenuComponent,
    SettingsChangeAccessComponent,
    SettingsChangePasswordComponent,
    SettingsAccountComponent,
    SettingsUsersComponent,
    SettingsTncComponent,
    SettingsComponent
  ],
  imports: [CommonModule, CoreModule, SettingsRoutingModule, SharedModule, PdfViewerModule],
  providers: [SettingsService]
})
export class SettingsModule {}
