import { Injectable } from '@angular/core';
import { PaymentMissedItemResponse } from '@app/core/models';
import { ConfigService } from '@app/core/services/config/config.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { AppThreeDotActionMenu, MenuItem } from '@app/shared/interfaces/three-dot-action-menu.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum MissedPaymentsListActions {
  ViewDetails = '1',
  TransferToAPCA = '2',
  SendInvoice = '3',
  Comments = '4'
}

@Injectable({
  providedIn: 'root'
})
export class MissedPaymentsListActionMenuService {
  private CONFIG_KEY = 'missed_payments_list_action_menu';

  constructor(private configService: ConfigService, public userSettingsService: UserSettingsService) {}

  getActionsConfig(plan: PaymentMissedItemResponse | null): Observable<AppThreeDotActionMenu> {
    const config = this.configService.getConfigValue(this.CONFIG_KEY, 'actions');

    return config.pipe(
      map((data) => {
        const filteredData: MenuItem[] = data.menuItems;
        const transformedItems = filteredData.reduce<MenuItem[]>((items, item) => {
          switch (item.value) {
            case MissedPaymentsListActions.ViewDetails: {
              items.push(item);
              break;
            }
            case MissedPaymentsListActions.TransferToAPCA: {
              if (!(plan?.['Over 60 Days'] === '1' && !plan?.['Escalate to Collection Agency'])) {
                return items;
              }
              items.push(item);
              break;
            }
            case MissedPaymentsListActions.SendInvoice: {
              if (this.userSettingsService.isPromptPayEnabled() === true) {
                if (
                  Boolean(plan?.['Escalate to Collection Agency']) === true ||
                  Number(plan?.['Invoice SMS Counter']) >= Number(this.userSettingsService.getPromptPaySmsLimit())
                ) {
                  return items;
                }
                items.push(item);
              } else {
                return items;
              }
              break;
            }
            default:
              items.push(item);
          }

          return items;
        }, []);

        return { menuItems: transformedItems };
      })
    );
  }
}
