import { Injectable } from '@angular/core';
import { PaymentFrequency } from '@app/core/enums/payment-frequency.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { InternalIdNamePair } from '@app/core/models/common/form-fields.interface';
import { BnplCalculatorV2Service } from '@app/core/services/bnpl-calculator-v2/bnpl-calculator-v2.service';
import { FormFieldsService } from '@app/core/services/form-fields/form-fields.service';
import { PlanTypeService } from '@app/core/services/plan-type/plan-type.service';

@Injectable()
export class FrequencyOptionsV2Service {
  constructor(
    private formFieldsService: FormFieldsService,
    private planTypeService: PlanTypeService,
    private bnplCalculatorService: BnplCalculatorV2Service
  ) {}

  getFrequencies(planType: PlanType): InternalIdNamePair[] {
    if (this.planTypeService.isPlanTypeBnpl(planType)) {
      return this.formFieldsService.getPaymentFrequencies().filter((x) => x.internalid !== PaymentFrequency.Monthly);
    }

    return this.formFieldsService.getPaymentFrequencies();
  }

  getNoOfPayments(frequency: string, totalPlanValue: number): number {
    return this.bnplCalculatorService.getCalculateNoOfPayments(frequency, totalPlanValue);
  }
}
