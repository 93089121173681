import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  readonly DEFAULT_BANNER = 'assets/images/propsal/proposal-banner-default.png';
  readonly READ_PNG_BASE_64_PREFIX = 'data:image/png;base64,';
  readonly BASE_64_PREFIX = 'base64,';

  constructor(private http: HttpClient) {}

  // Return is without the base64 prefix
  getImageAsBase64(imagePath: string = this.DEFAULT_BANNER): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.http.get(imagePath, { responseType: 'blob' }).subscribe(
        (blob: Blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result as string;
            observer.next(base64String.split('data:image/png;base64,')[1]);
            observer.complete();
          };
          reader.readAsDataURL(blob);
        },
        (error: any) => {
          observer.error(error);
          observer.complete();
        }
      );
    });
  }

  getFileTypeFromBase64(base64Data: string): string {
    const mimeMatch = base64Data.match(/^data:(.*);base64,/);
    return mimeMatch ? mimeMatch[1].replace(/^image\//, '') : '';
  }
}
