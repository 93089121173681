<header>
  <div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0">
      <div class="d-flex align-items-center mb-2">
        <a
          id="top-bar__navigation-toggler"
          class="top-bar__navigation-toggler"
          href="javascript:void(0);"
          (click)="sidenavState()"
        >
          <span></span>
        </a>
        <i *ngIf="navigateBackTo" class="mr-3 uil uil-arrow-left" (click)="onBackBtnClick()"></i>
        <h4 class="mb-0">{{ pageTitle }}</h4>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</header>
