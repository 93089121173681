<div *ngIf="config" class="form-group hide-arrow" [ngClass]="config.containerClass">
  <label *ngIf="config?.label" class="w-100 d-flex">
    <span [ngClass]="{ disabled: disabled }" class="text-truncate" title="{{ config.label }}">
      {{ config.label }}
    </span>
    <span *ngIf="isRequired && config.showRequiredMarker && !disabled" class="required-field"></span>
  </label>

  <div class="number-input-group">
    <span class="dollar-symbol" [class.disabled]="disabled">
      <input
        [(ngModel)]="value"
        #input
        type="number"
        class="form-control"
        autocomplete="off"
        [ngClass]="{
          'border--danger': hasError && control.touched && !disabled,
          'text-right': config.textAlign === 'right'
        }"
        [id]="config.label"
        [placeholder]="config.placeholder || '0.00'"
        [disabled]="disabled"
        [readonly]="disabled"
        (focus)="onFocus()"
        (blur)="onBlur(input.value)"
        (keyup)="onKeyup($event,input.value)"
        [sliqpayNumeric]="{ allowNegative: config.allowNegative, allowDecimal: true }"
    /></span>

    <ng-container *ngIf="!disabled && hasError && control?.touched">
      <div class="invalid-feedback d-block">
        <div *ngIf="hasError.required || hasError.invalid">
          {{ config.errors?.required }}
        </div>
        <div *ngIf="hasError.min">{{ config.errors?.min }}</div>
        <div *ngIf="hasError.max">{{ config.errors?.max }}</div>
      </div>
    </ng-container>
  </div>
</div>
