import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class LoginService {
  LOGIN_CONFIG_KEY = 'login_page';

  constructor(private configService: ConfigService) {}

  getEmailConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.LOGIN_CONFIG_KEY, 'email');
  }

  getPasswordConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.LOGIN_CONFIG_KEY, 'password');
  }

  getLoginBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.LOGIN_CONFIG_KEY, 'login_btn');
  }
}
