import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { PaymentMissedItemResponse } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { CommentModalComponent, CommentPropertyName } from '@app/payments/modals/comment-modal/comment-modal.component';
import { AppThreeDotActionMenu } from '@app/shared/interfaces/three-dot-action-menu.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of } from 'rxjs';
import { concatMap, filter, finalize, map, take, takeUntil } from 'rxjs/operators';
import { MissedPaymentsService } from '../../services/missed-payments.service';
import { MissedPaymentsListActionMenuService, MissedPaymentsListActions } from './missed-payments-list-action-menu.service';

@Component({
  selector: 'sliqpay-missed-payments-list-action-menu',
  templateUrl: './missed-payments-list-action-menu.component.html',
  styleUrls: ['./missed-payments-list-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissedPaymentsListActionMenuComponent implements OnInit, OnChanges, OnDestroy {
  LANG_CONFIG_KEY = 'missed_payment_list';

  unsubscribe$ = new Subject<boolean>();

  @Input() plan: PaymentMissedItemResponse | null = null;
  @Output() invoiceSent: EventEmitter<PaymentMissedItemResponse> = new EventEmitter<PaymentMissedItemResponse>();
  @Output() commentActionChange = new EventEmitter<{
    plan: PaymentMissedItemResponse;
    commentValue: string;
  }>();
  actionsConfig$!: Observable<AppThreeDotActionMenu>;

  constructor(
    private missedPaymentsListActionMenuService: MissedPaymentsListActionMenuService,
    private notificationService: NotificationService,
    public helperService: HelperService,
    private missedPaymentsService: MissedPaymentsService,
    private modal: NgbModal,
    public userSettingsService: UserSettingsService
  ) {}

  ngOnInit() {
    this.actionsConfig$ = this.missedPaymentsListActionMenuService.getActionsConfig(this.plan);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.plan && !changes.plan.isFirstChange()) {
      this.actionsConfig$ = this.missedPaymentsListActionMenuService.getActionsConfig(this.plan);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onActionChange(action: string): void {
    if (!this.plan) {
      return;
    }

    switch (action) {
      case MissedPaymentsListActions.ViewDetails: {
        this.missedPaymentsService.gotoMissedPaymentDetailsScreen(this.plan['DDR Internal ID'], true);
        break;
      }
      case MissedPaymentsListActions.TransferToAPCA: {
        this.confirmEscalate(this.plan);

        break;
      }
      case MissedPaymentsListActions.SendInvoice: {
        this.sendInvoice(this.plan);

        break;
      }
      case MissedPaymentsListActions.Comments: {
        this.comment(this.plan);

        break;
      }
    }
  }

  confirmEscalate(plan: PaymentMissedItemResponse) {
    const ddrId = plan['DDR'];
    this.openEscalateConfirmationDialog()
      .pipe(
        concatMap((proceed: boolean) => {
          if (proceed) {
            return this.missedPaymentsService.escalateMissedPayment$(plan).pipe(map((res) => this.helperService.checkAPIResponse(res)));
          }
          return of(false);
        }),
        take(1)
      )
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          const successMsg = 'Payment ' + ddrId + ' has been escalated to APCA';
          this.notificationService.success(successMsg, 'Escalated');
          this.missedPaymentsService.fetchMissedPayments$().pipe(takeUntil(this.unsubscribe$)).subscribe();
        }
      });
  }

  sendInvoice(plan: PaymentMissedItemResponse): void {
    this.openSendInvoiceConfirmationDialog()
      .pipe(
        concatMap((proceed: boolean) => {
          if (proceed) {
            return this.missedPaymentsService.sendInvoice$(plan['DDR Internal ID']).pipe(
              map((res) => this.helperService.checkAPIResponse(res)),
              finalize(() => {
                this.invoiceSent.emit({ ...plan });
              })
            );
          }
          return of(false);
        }),
        take(1)
      )
      .subscribe((isSent) => {
        if (isSent) {
          const successMsg = 'An invoice of the total overdue amount has been sent via SMS to the Responsible Party.';
          this.notificationService.success(successMsg, 'Invoice Sent');
        }
      });
  }

  private openEscalateConfirmationDialog(): Observable<boolean> {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Esacalate To Agency',
      content:
        parseFloat(this.userSettingsService.getConnectServices()?.debtCollectionEscalationFee) > 0
          ? 'escalate_with_fees_message'
          : 'escalate_without_fees_message',
      langConfigKey: this.LANG_CONFIG_KEY,
      strReplacements: [this.userSettingsService.getConnectServices()?.debtCollectionEscalationFee],
      buttons: [
        {
          text: 'Cancel',
          class: 'btn btn-grey w-170 mr-1',
          value: false
        },
        {
          text: 'Transfer to APCA',
          class: 'btn btn-primary w-170 ml-1',
          value: true
        }
      ]
    };

    return modalRef.closed.pipe(map((isConfirmed) => isConfirmed));
  }

  private openSendInvoiceConfirmationDialog(): Observable<boolean> {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Send Invoice',
      content: 'send_invoice_message',
      langConfigKey: this.LANG_CONFIG_KEY,
      buttons: [
        {
          text: 'Cancel',
          class: 'btn btn-grey w-170 mr-1',
          value: false
        },
        {
          text: 'SMS Invoice',
          class: 'btn btn-primary w-170 ml-1',
          value: true
        }
      ]
    };

    return modalRef.closed.pipe(map((proceedWithInvoice) => proceedWithInvoice));
  }

  private comment(plan: PaymentMissedItemResponse): void {
    const modal = this.modal.open(CommentModalComponent, { centered: true });
    modal.componentInstance.id = plan['DDR Internal ID'];
    modal.componentInstance.propertyName = CommentPropertyName.DDR_PLANS;
    modal.componentInstance.commentValue = plan.custrecord_mfa_ddr_provider_comments;
    modal.closed
      .pipe(
        filter((d) => !!d),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        this.commentActionChange.emit({ plan, commentValue: data.custrecord_mfa_ddr_provider_comments });
      });
  }
}
