import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { PaginationService } from './pagination.service';

@Component({
  selector: 'sliqpay-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PaginationService]
})
export class PaginationComponent implements OnInit {
  @Input() page = 0;
  @Input() itemPerPage = environment.itemPerPage;
  @Output() pageChange = new EventEmitter();
  @Output() itemPerPageChange = new EventEmitter();
  pageSizes = [10, 20, 30];

  listingDdConfig$!: Observable<AppDropdown>;

  constructor(private paginationService: PaginationService) {}

  ngOnInit(): void {
    this.listingDdConfig$ = this.paginationService.getListingDdConfig();
  }

  handlePageSizeChange(event: number): void {
    this.pageChange.emit(1);
    this.itemPerPageChange.emit(event);
  }
}
