import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sliqpay-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent extends Toast implements OnInit {
  isError?: boolean;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    this.isError = this.toastClasses.includes('pp-toast-error');
  }
}
