import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse, GenericListResponse, PaymentPlanItemResponse } from '@app/core/models';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanListDataService {
  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

  getPaymentPlanSummary$(): Observable<GenericListResponse<PaymentPlanItemResponse, ErrorResponse>> {
    return this.httpClient.get<GenericListResponse<PaymentPlanItemResponse, ErrorResponse>>(
      `${this.apiUrlService.paymentPlanSummaryUrl}?moduleType=getProviderActiveDdrList`,
      { withCredentials: true }
    );
  }
}
