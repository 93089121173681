import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { FullscreenLoaderService, FullscreenLoaderState } from './fullscreen-loader.service';

@Component({
  selector: 'sliqpay-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenLoaderComponent implements OnInit {
  themePath = environment.client;

  fullscreenLoaderState$!: Observable<FullscreenLoaderState>;

  constructor(private service: FullscreenLoaderService) {}

  ngOnInit(): void {
    this.fullscreenLoaderState$ = this.service.fullscreenUiState$();
  }
}
