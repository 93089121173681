import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseDateToValid'
})
export class ParseDateToValidPipe implements PipeTransform {
  // This is to transform data from dd/mm/yyyy to mm/dd/yyyy which is a valid date for new Date(validDate)
  transform(value: string): Date {
    const splitDateTime = value.split(' ');
    const splitDate = splitDateTime[0].split('/');
    const formattedDate = [splitDate[1], splitDate[0], splitDate[2]].join('/');
    const formattedDateTime = [formattedDate, splitDateTime[1], splitDateTime[2]].join(' ');
    return new Date(formattedDateTime);
  }
}
