import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { Observable } from 'rxjs';

@Injectable()
export class ProviderPageService {
  private CONFIG_KEY = 'provider_page';

  constructor(private configService: ConfigService) {}

  getContinueBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'continue_btn');
  }

  getProviderDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'provider_dd');
  }
}
