import { Injectable } from '@angular/core';
import { SmsJourneyStep } from '@app/core/enums/sms-journey-step.enum';
import { Progress } from '@app/core/models/payment/progress.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PendingPlanProgressService {
  private progressSubject = new BehaviorSubject<Progress[]>([]);

  private excludedFromSmsJourney = [SmsJourneyStep.SaveAsDraft, SmsJourneyStep.SmsSent];

  setProgress(progress: Progress[]): void {
    this.progressSubject.next(progress);
  }

  getProgress(): Progress[] {
    return this.progressSubject.getValue();
  }

  getSmsJourneySteps$(): Observable<Progress[]> {
    return this.progressSubject.pipe(
      map((progress) => progress.filter((step) => !this.excludedFromSmsJourney.includes(step.custrecord_spps_step_id as SmsJourneyStep)))
    );
  }

  getSmsJourneySteps(): Progress[] {
    return this.getProgress().filter((step) => !this.excludedFromSmsJourney.includes(step.custrecord_spps_step_id as SmsJourneyStep));
  }

  updateStepByInternalId(stepInternalId: string, newValue: Partial<Progress>): void {
    const newProgress = this.getProgress().map((step) => {
      if (step.internalid === stepInternalId) {
        return {
          ...step,
          ...newValue
        };
      }
      return step;
    });
    this.setProgress(newProgress);
  }

  updateStepByStepId(stepId: SmsJourneyStep, newValue: Partial<Progress>): void {
    const newProgress = this.getProgress().map((step) => {
      if (step.custrecord_spps_step_id === stepId) {
        return {
          ...step,
          ...newValue
        };
      }
      return step;
    });
    this.setProgress(newProgress);
  }

  getStepByStepId(step: SmsJourneyStep): Progress | undefined {
    return this.getProgress().find((progress) => progress.custrecord_spps_step_id === step);
  }

  getStepByInternalId(internalid: string): Progress | undefined {
    return this.getProgress().find((progress) => progress.internalid === internalid);
  }

  isLastStep(step: SmsJourneyStep): boolean {
    const lastStep = [...this.getProgress()].pop();
    return lastStep?.custrecord_spps_step_id === step || false;
  }
}
