import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApprovalPlansService {
  private CONFIG_KEY = 'approval_queue';

  constructor(private configService: ConfigService) {}

  getApproveBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'approve_btn');
  }

  getDeclineBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'decline_btn');
  }

  getCreatePlanBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'create_plan_btn');
  }

  getSearchWidgetConfig(): Observable<SearchConfig[]> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'search_widget');
  }
}
