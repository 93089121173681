import { Component, Input } from '@angular/core';
import { ModalData } from '@core/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sliqpay-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() public data: ModalData | null = null;

  constructor(public activeModal: NgbActiveModal) {}
}
