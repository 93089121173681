import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { HttpErrorInterceptor } from '@app/core/interceptors';
import { HttpAuthInterceptor } from '@app/core/interceptors/http-auth-interceptor';
import { NgbDateCustomParserFormatter } from '@app/core/vendors/parser/ngb-date-formatter';
import { PaymentPlansModule } from '@app/payments/payment-plans.module';
import { AuthModule } from '@auth/auth.module';
import { CoreModule } from '@core/core.module';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SettingsModule } from '@settings/settings.module';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageModule } from './home-page/home-page.module';
import { reducers } from './store/reducers';

export const APP_VERSION = new InjectionToken<string>('appVersion');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    HomePageModule,
    SettingsModule,
    AuthModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    PaymentPlansModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: APP_VERSION, useValue: packageJson.version }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
