import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@app/core/services/auth/auth.service';

@Component({
  selector: 'sliqpay-payments-menu',
  templateUrl: './payments-menu.component.html',
  styleUrls: ['./payments-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsMenuComponent {
  user = this.authService.currentUser();

  constructor(private authService: AuthService) {}
}
