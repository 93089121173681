import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { SettingDataService } from '@app/core/data-services/settings/settings-data.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { SettingChangePasswordService } from './settings-change-password.service';

@Component({
  selector: 'sliqpay-settings-change-password',
  templateUrl: './settings-change-password.component.html',
  styleUrls: ['./settings-change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettingChangePasswordService]
})
export class SettingsChangePasswordComponent implements OnInit {
  LANG_CONFIG_KEY = 'settings_change_pword';

  form: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  errors$ = new BehaviorSubject(null);

  // Config
  changePwordBtnConfig$!: Observable<AppButton>;
  currentPwordConfig$!: Observable<AppInput>;
  newPwordConfig$!: Observable<AppInput>;
  confirmPwordConfig$!: Observable<AppInput>;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private settingService: SettingDataService,
    private toastrService: ToastrService,
    private settingChangePwordService: SettingChangePasswordService
  ) {
    this.form = this.fb.group(
      {
        old_password: ['', Validators.required],
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required]
      },
      {
        validator: this.passwordValidator
      }
    );
  }

  passwordValidator(group: UntypedFormGroup) {
    let bothSame = true;

    const secondValue = group.get('password')?.value;
    const thirdValue = group.get('password_confirmation')?.value;

    bothSame = bothSame && secondValue === thirdValue;

    return bothSame ? null : { invalid: 'Password and confirmation password must be the same' };
  }

  ngOnInit(): void {
    this.setConfig();
  }

  submit() {
    if (!this.form.valid) {
      this.toastrService.error('Password and confirmation password must be the same');
      return;
    }

    this.loading$.next(true);
    this.settingService
      .changePassword(this.form.value)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.loading$.next(false);
          return throwError(error);
        })
      )
      .subscribe((response) => {
        this.loading$.next(false);
        this.activeModal.close();
        this.toastrService.success(response.data[0].message, 'Your password has been changed');
      });
  }

  private setConfig(): void {
    this.changePwordBtnConfig$ = this.settingChangePwordService.getChangePwordBtnConfig();
    this.currentPwordConfig$ = this.settingChangePwordService.getCurrentPwordConfig();
    this.newPwordConfig$ = this.settingChangePwordService.getNewPwordConfig();
    this.confirmPwordConfig$ = this.settingChangePwordService.getConfirmPwordConfig();
  }
}
