import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppThreeDotActionMenu, MenuItem } from '@app/shared/interfaces/three-dot-action-menu.interface';

@Component({
  selector: 'sliqpay-three-dot-action-menu',
  templateUrl: './three-dot-action-menu.component.html',
  styleUrls: ['./three-dot-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreeDotActionMenuComponent {
  @Input() config!: AppThreeDotActionMenu | null;
  @Input() subMenu!: MenuItem; // The sub menu item if any
  @Input() isRootNode = false; // To display 3 dots or item node
  @Output() valueChange = new EventEmitter();

  onClick(value: string): void {
    this.valueChange.emit(value);
  }
}
