import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { Observable } from 'rxjs';

@Injectable()
export class NotFoundService {
  private CONFIG_KEY = 'not_found';

  constructor(private configService: ConfigService) {}

  getBackHomeBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'back_home_btn');
  }
}
