import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AuthService } from '@app/core/services/auth/auth.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { NotFoundService } from './not-found.service';

@Component({
  selector: 'sliqpay-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotFoundService]
})
export class NotFoundComponent implements OnInit {
  LANG_CONFIG_KEY = 'not_found';

  themePath = environment.client;

  // Config
  backHomeBtnConfig$!: Observable<AppButton>;

  constructor(public authService: AuthService, private notFoundService: NotFoundService, private router: Router) {}

  ngOnInit(): void {
    this.backHomeBtnConfig$ = this.notFoundService.getBackHomeBtnConfig();
  }

  goToHome(): void {
    this.router.navigate(['/']);
  }
}
