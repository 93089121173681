import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { TermsConditionsModalService } from '../core/pages/terms-conditions-modal/terms-conditions-modal.service';
import { HomePageComponent } from './home-page.component';
import { HomePageService } from './home-page.service';

@NgModule({
  imports: [CommonModule, SharedModule, CoreModule],
  declarations: [HomePageComponent],
  providers: [HomePageService, TermsConditionsModalService]
})
export class HomePageModule {}
