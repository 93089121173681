export enum PayInFullPaymentPreference {
  PAY_AT_PRACTICE = '1',
  IN_APP = '2',
  RP_OPTIONAL = '3'
}

// below are the same denums with Deposit, separated only in FE for clarity
export enum PayInFullPaymentStatus {
  CLEARED = '2', // pif processed, rp paid to denticare
  PAID = '3', // pif paid to provider
  TO_BE_COLLECTED_AT_PRACTICE = '7',
  COLLECTED_AT_PRACTICE = '8'
}
