<div class="not-found">
  <div class="__inner">
    <a href="#" *ngIf="authService.currentUser(); else noLink">
      <img src="../../../../assets/images/{{ themePath }}/logo-big.svg" alt="logo" />
    </a>
    <ng-template #noLink>
      <img src="../../../../assets/images/{{ themePath }}/logo-big.svg" alt="logo" />
    </ng-template>
    <div class="modal-container">
      <i class="content-icon icon-type-error"></i>
      <h4 [innerHTML]="'header' | lang: LANG_CONFIG_KEY | async"></h4>
      <p [innerHTML]="'header_desc' | lang: LANG_CONFIG_KEY | async"></p>

      <sliqpay-button
        *ngIf="authService.currentUser()"
        [config]="backHomeBtnConfig$ | async"
        (clicked)="goToHome()"
      ></sliqpay-button>
    </div>
  </div>
</div>
