import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, Renderer2, Self } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { AppDatePicker } from '@app/shared/interfaces/date-picker.interface';
import { environment } from '@environments/environment';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'sliqpay-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  @Input()
  config!: AppDatePicker | null;
  @Input() isMonthYearDtp = false;
  @Output() dateChange = new EventEmitter();

  onChange!: (value: any) => void;
  onTouched!: () => void;

  private defaultMinDate = new Date('01/01/1900');
  private defaultMaxDate = new Date();

  // For dob
  today = new Date();

  form!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    @Self() @Optional() public control: NgControl,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
    this.form = this.fb.group({
      dateInput: new UntypedFormControl('')
    });
  }

  public get invalid(): boolean | null {
    return this.control ? this.control.invalid : false;
  }

  public get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  public get disabled(): boolean {
    return this.config?.disabled || this.control?.disabled ? true : false;
  }

  public get minDate(): Date {
    return this.config?.minDate ? new Date(this.config?.minDate) : this.defaultMinDate;
  }

  public get maxDate(): Date {
    return this.config?.maxDate ? new Date(this.config?.maxDate) : this.defaultMaxDate;
  }

  get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }

  ngOnInit(): void {
    sessionStorage.setItem('dateFormatMaterial', environment.dateFormatMaterial);
  }

  writeValue(obj: any): void {
    this.form.get('dateInput')?.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onDateChange(): void {
    const value = this.form.get('dateInput')?.value ? new Date(this.form.get('dateInput')?.value) : null;
    this.onChange(value);
    this.dateChange.emit(value);
  }

  onClose(): void {
    this.onTouched();
    this.renderer.removeClass(this.document.body, 'pp-datepicker');
  }

  onOpened(): void {
    this.renderer.addClass(this.document.body, 'pp-datepicker');
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>): void {
    if (this.isMonthYearDtp) {
      const date = new FormControl(moment());
      const ctrlValue = date.value;
      ctrlValue?.month(normalizedMonthAndYear.month());
      ctrlValue?.year(normalizedMonthAndYear.year());
      ctrlValue?.day(ctrlValue.endOf('month').day());
      this.form.get('dateInput')?.setValue(ctrlValue?.toDate());
      this.onChange(ctrlValue?.toDate());
      datepicker.close();
    }
  }
}
