<div class="modal-container">
  <div class="close-block">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.close()">
      <i class="uil uil-times" aria-hidden="true"></i>
    </button>
  </div>
  <h4 [innerHTML]="'h4' | lang: LANG_CONFIG_KEY | async"></h4>
  <form [formGroup]="form">
    <div class="widget-login__form">
      <div class="row">
        <div class="col-6">
          <sliqpay-input formControlName="old_password" [config]="currentPwordConfig$ | async"></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <sliqpay-input formControlName="password" [config]="newPwordConfig$ | async"></sliqpay-input>
        </div>
        <div class="col-6">
          <sliqpay-input formControlName="password_confirmation" [config]="confirmPwordConfig$ | async"></sliqpay-input>
        </div>
      </div>
      <div class="col-12">
        <div *ngIf="form.errors" class="is-invalid">{{ form.errors.invalid }}</div>
        <ng-template [ngIf]="errors$ | async">
          <div class="is-invalid" *ngFor="let err of errors$ | async">
            {{ err?.message }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="text-right">
      <sliqpay-button
        [config]="changePwordBtnConfig$ | async"
        [loading]="loading$ | async"
        [disabled]="!form.valid"
        (clicked)="submit()"
      ></sliqpay-button>
    </div>
  </form>
</div>
