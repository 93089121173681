<div *ngIf="config" class="form-group">
  <form [formGroup]="form">
    <label *ngIf="config?.label" class="w-100 d-flex">
      <span [ngClass]="{ disabled: disabled }" class="text-truncate" title="{{ config.label }}">
        {{ config.label }}
      </span>
      <span *ngIf="isRequired && config.showRequiredMarker && !disabled" class="required-field"></span>
    </label>
    <textarea
      class="form-control h-auto pp-textarea fs-12"
      formControlName="text_area"
      [class.border--danger]="hasError && control.touched"
      (keyup)="onValueChanged()"
      (blur)="onTouched()"
      [rows]="config.rows"
      [placeholder]="config.placeholder"
      [style.resize]="config.resizable ? '' : 'none'"
      [maxlength]="maxlength"
      [readOnly]="disabled"
    ></textarea>
    <label *ngIf="config?.maxlength" class="fs-12 mt-1 label disabled"
      >{{ remainingChars$ | async }} characters remaining
    </label>
    <div class="invalid-feedback d-block" *ngIf="!disabled && hasError && control?.touched">
      <div *ngIf="hasError.required">
        {{ config.errors?.required }}
      </div>
    </div>
  </form>
</div>
