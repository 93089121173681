import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'sliqpay-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle!: string | null;
  @Input() content: TemplateRef<HTMLElement> | undefined;
  @Input() navigateBackTo: string | null = null; // For displaying back button in left of page title. Route of the back button.

  constructor(private storageService: StorageService, private router: Router) {}

  ngOnInit(): void {
    this.storageService.sidebarOpened.subscribe((data) => {
      if (!data) {
        this.storageService.sidebarState = 'false';
      }
    });
  }

  sidenavState(): void {
    this.storageService.sidebarState = 'true';
  }

  onBackBtnClick(): void {
    this.router.navigate([this.navigateBackTo]);
  }
}
