import { Injectable } from '@angular/core';
import { PaymentFrequency } from '@app/core/enums/payment-frequency.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { InternalIdNamePair } from '@app/core/models/common/form-fields.interface';
import { FormFieldsService } from '@app/core/services/form-fields/form-fields.service';
import { UserSegmentService } from '@app/core/services/user-segment/user-segment.service';

@Injectable()
export class AmendmentRequestService {
  private isOrtho15kAssured = false;

  constructor(private formFieldService: FormFieldsService, private userSegmentService: UserSegmentService) {}

  getMaxRepaymentAmountForFrequency(frequency: PaymentFrequency): number {
    const maxDdrMonth = +this.getMaxDirectDebitAmount();

    switch (frequency) {
      case PaymentFrequency.Weekly:
        return maxDdrMonth / 4;
      case PaymentFrequency.Fortnightly:
        return maxDdrMonth / 2;
      case PaymentFrequency.Monthly:
        return maxDdrMonth;
      default:
        return 0;
    }
  }

  isMaxRepaymentReachedForFrequency(amount: number, frequency: PaymentFrequency) {
    return amount > this.getMaxRepaymentAmountForFrequency(frequency);
  }

  getProcedureItems(): InternalIdNamePair[] {
    return this.formFieldService.getOrthoItems();
  }

  getFrequenciesByPlanType(planType: PlanType): InternalIdNamePair[] {
    let frequencies = this.formFieldService.getPaymentFrequencies();
    if (planType === PlanType.Bnpl) {
      frequencies = frequencies.filter((f) => f.internalid !== PaymentFrequency.Monthly);
    }
    return frequencies;
  }

  getMaxAmendmentAmount(): number {
    return this.isOrtho15kAssured
      ? +this.userSegmentService.getMaxAmendmentAmount15k()
      : +this.userSegmentService.getMaxAmendmentIncrease();
  }

  setIsOrtho15kAssured(isOrtho15kAssured = false): void {
    this.isOrtho15kAssured = isOrtho15kAssured;
  }

  private getMaxDirectDebitAmount(): number {
    return this.isOrtho15kAssured
      ? +this.userSegmentService.getMaxDirectDebitAmount15k()
      : +this.userSegmentService.getMaxDirectDebitAmount();
  }
}
