import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[sliqpayNumeric]'
})
export class NumericDirective implements AfterViewInit {
  @Input() sliqpayNumeric?: { allowNegative: boolean; allowDecimal: boolean };

  private excludeKeys = ['E', 'e'];

  constructor(public elRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.addExcludedKeys();
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    if (this.excludeKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onInput(value: string): void {
    this.elRef.nativeElement.value = +value;
  }

  private addExcludedKeys(): void {
    if (!this.sliqpayNumeric?.allowNegative) {
      this.excludeKeys.push('-');
    }
    if (!this.sliqpayNumeric?.allowDecimal) {
      this.excludeKeys.push('.');
    }
  }
}
