import * as ConfigActions from '../actions/config.action';

export type ConfigAction = ConfigActions.All;

const defaulState: any = {};

// Helper function to create new state object
const newState = (state: any, newData: any) => {
  return Object.assign({}, state, newData);
};

export const configReducer = (state: any = defaulState, action: ConfigAction): any => {
  switch (action.type) {
    case ConfigActions.UPDATE_CONFIG:
      return newState(state, action.payload);

    default:
      return state;
  }
};
