import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { AppTextarea } from '@app/shared/interfaces/textarea.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sliqpay-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
  @Input() config: AppTextarea | null;

  onChange!: (value: string) => void;
  onTouched!: () => void;

  disabled = false;
  form!: UntypedFormGroup;
  remainingChars$ = new BehaviorSubject(0);

  get textArea(): AbstractControl | null {
    return this.form.get('text_area');
  }

  get maxlength(): number | null {
    return this.config?.maxlength ? this.config.maxlength : null;
  }

  public get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  public get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }

  constructor(@Self() @Optional() public control: NgControl, private fb: UntypedFormBuilder) {
    this.config = {
      label: '',
      placeholder: '',
      rows: 3
    };

    if (this.control) {
      this.control.valueAccessor = this;
    }

    this.form = this.fb.group({
      text_area: [null]
    });
  }

  writeValue(value: string): void {
    this.textArea?.setValue(value ? this.convertBrToNewLines(value) : value);

    this.setRemainingChars();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.setRemainingChars();
  }

  onValueChanged(): void {
    this.onChange(this.convertNewlinesToBr(this.textArea?.value));
    this.setRemainingChars();
  }

  private convertNewlinesToBr(input: string): string {
    return input?.replace(/\n/g, '<br>');
  }

  private convertBrToNewLines(input: string): string {
    if (input.includes('&lt;br&gt;')) {
      return input.replace(/&lt;br&gt;/g, '\n');
    }
    return input?.replace(/<br>/g, '\n');
  }

  private setRemainingChars(): void {
    if (this.config?.maxlength) {
      if (this.textArea?.value) {
        this.remainingChars$.next(this.config.maxlength - this.textArea?.value.length);
      } else {
        this.remainingChars$.next(this.config.maxlength);
      }
    }
  }
}
