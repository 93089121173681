import { DepositPayee, DepositStatus } from '../enums/deposit.enum';

export const PaymentPlanDepositStatusLabel = new Map<DepositStatus, string>([
  [DepositStatus.PENDING, 'Deposit hold'],
  [DepositStatus.CLEARED, 'Deposit collected'],
  [DepositStatus.PAID, 'Deposit remitted'],
  [DepositStatus.FAILED, 'Deposit failed'],
  [DepositStatus.PRACTICE_TO_PROCESS, 'Practice to process deposit'],
  [DepositStatus.RELEASED, 'Deposit released']
]);

export const DepositPaymentPreferenceOrder = new Map<DepositPayee, number>([
  [DepositPayee.DENTICARE, 1],
  [DepositPayee.PRACTICE, 2]
]);
