import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '@app/core/services/lang.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'lang'
})
export class LangPipe implements PipeTransform {
  constructor(private langService: LangService) {}

  // strReplace is used for replacing texts that are in this format: ${index}
  // Where ${index} will be replaced by strReplace[index]
  transform(value: string, key: string, strReplace: string[] = []): Observable<string> {
    return this.langService.getLangConfigValue(key, value).pipe(
      map((langConfigValue: string) => {
        if (!strReplace.length) {
          return langConfigValue ? langConfigValue : value;
        }
        return this.replaceVariables(langConfigValue, strReplace);
      })
    );
  }

  private replaceVariables(originalValue: string, stringReplacements: string[]): string {
    let newText = originalValue;
    stringReplacements.forEach((replacement, index) => {
      newText = newText.replace('${' + index + '}', replacement);
    });

    return newText;
  }
}
