import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericListResponse, GenericModelResponse } from '@app/core/models';
import { BasiqAccount } from '@app/core/models/basiq/account.interface';
import { BasiqAuthPayload, BasiqAuthResponse } from '@app/core/models/basiq/auth.interface';
import { UpdateVerificationStatusPayload, UpdateVerificationStatusResponseData } from '@app/core/models/basiq/basiq-api.interface';
import { IncomeVerificationPayload, IncomeVerificationApiResponse } from '@app/core/models/basiq/income-verification.interface';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasiqDataService {
  constructor(private http: HttpClient, private apiService: ApiUrlService) {}

  createUserAndToken(payload: BasiqAuthPayload): Observable<GenericListResponse<BasiqAuthResponse, any>> {
    return this.http.post<GenericListResponse<BasiqAuthResponse, any>>(`${environment.basiq.apiBaseUrl}/basiq-connect-user`, payload);
  }

  verifyIncome(payload: IncomeVerificationPayload): Observable<GenericListResponse<IncomeVerificationApiResponse, any>> {
    return this.http.post<GenericListResponse<IncomeVerificationApiResponse, any>>(
      environment.basiq.apiBaseUrl + '/basiq-verify-income',
      payload
    );
  }

  getAccounts(basiqUserId: string): Observable<GenericListResponse<BasiqAccount, any>> {
    return this.http.get<GenericListResponse<BasiqAccount, any>>(`${environment.basiq.apiBaseUrl}/basiq-user-accounts`, {
      headers: {
        basiqUserId
      }
    });
  }

  getJobData(jobId: string, basiqUserId: string): Observable<any> {
    return this.http.get(environment.basiq.apiBaseUrl + '/basiq-job-status', {
      headers: { jobId, basiqUserId }
    });
  }

  updateBasiqVerificationStatus(
    payload: UpdateVerificationStatusPayload
  ): Observable<GenericModelResponse<UpdateVerificationStatusResponseData, any>> {
    return this.http.put<GenericModelResponse<UpdateVerificationStatusResponseData, any>>(
      `${this.apiService.updateSavedPlanFieldByCode}/${payload.ddrDraftId}`,
      {
        fieldCode: 'UPDATE_BASIQ',
        custrecord_ddrip_basiq_verify_status: payload.status
      }
    );
  }
}
