import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { SupportDataService } from '@app/core/data-services/support/support-data.service';
import { UnsubmittedAmendment } from '@app/core/models';
import { AmendmentService } from '@app/core/services/amendment/amendment.service';
import { NotificationService } from '@app/core/services/notification.service';
import { PaymentService } from '@app/core/services/payment.service';
import { AmendmentRequestComponent } from '@app/payments/modals/amendment-request/amendment-request.component';
import { CommentModalComponent } from '@app/payments/modals/comment-modal/comment-modal.component';
import { AppThreeDotActionMenu } from '@app/shared/interfaces/three-dot-action-menu.interface';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PendingPlansActions } from '../payments-unsubmitted-action-menu/payments-unsubmitted-action-menu.service';
import { AmendmentActionMenuService } from './amendment-action-menu.service';

@Component({
  selector: 'sliqpay-amendment-action-menu',
  templateUrl: './amendment-action-menu.component.html',
  styleUrls: ['./amendment-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AmendmentActionMenuService]
})
export class AmendmentActionMenuComponent implements OnInit {
  @Input() amendment?: UnsubmittedAmendment;

  menuConfig$!: Observable<AppThreeDotActionMenu>;

  constructor(
    private service: AmendmentActionMenuService,
    private supportService: SupportDataService,
    private notificationService: NotificationService,
    private paymentService: PaymentService,
    private toastrService: ToastrService,
    private amendmentService: AmendmentService,
    protected modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.setUiConfigs();
  }

  setUiConfigs(): void {
    this.menuConfig$ = this.service.getMenuUiConfig$();
  }

  onActionChange(action: string): void {
    if (!this.amendment) {
      return;
    }

    switch (action) {
      case PendingPlansActions.Comment: {
        this.comment(this.amendment);
        break;
      }
      case PendingPlansActions.SendSms: {
        this.sendSms(this.amendment);
        break;
      }
      case PendingPlansActions.ViewAmendment: {
        this.viewAmendment(this.amendment);
        break;
      }
      case PendingPlansActions.EditAmendment: {
        this.editAmendment();
        break;
      }
      case PendingPlansActions.Delete: {
        this.delete(this.amendment.id);
        break;
      }
    }
  }

  private sendSms(plan: any) {
    this.supportService
      .postSendAmendmentSMS(plan)
      .pipe(take(1))
      .subscribe(
        (response) => {
          const data = response.data[0].msg[1];
          const message = data.message || 'SMS has been sent to ' + plan.json_transformed.recepientMobileNo + '.';
          const title = data.title || 'SMS sent';
          this.paymentService.fetchUnsubmittedPlans();
          this.notificationService.success(message, title);
        },
        (error) => {
          const modal = this.modal.open(ModalComponent, { centered: true });
          modal.componentInstance.data = {
            content: `Unfortunately we are unable to proceed with your application. Please contact ${environment.client} for further assistance.`
          };
        }
      );
  }

  private comment(plan: UnsubmittedAmendment): void {
    const modal = this.modal.open(CommentModalComponent, { centered: true });
    modal.componentInstance.id = plan.id;
    modal.componentInstance.propertyName = 'custrecord_unsubmitted_comment';
    modal.componentInstance.commentValue = plan.custrecord_unsubmitted_comment;
    modal.closed
      .pipe(
        filter((d) => !!d),
        take(1)
      )
      .subscribe((data) => {
        const updatedValue = { ...plan, custrecord_unsubmitted_comment: data.custrecord_unsubmitted_comment };
        this.paymentService.updateUnsubmittedPlan(updatedValue.id, updatedValue);
      });
  }

  private editAmendment(): void {
    if (!this.amendment) {
      return;
    }

    const amendmentData = this.amendment.json_transformed;

    this.amendmentService
      .getAmendmentDetails(amendmentData.ddrId, amendmentData.rpId)
      .pipe(take(1))
      .subscribe((ddrData) => {
        this.amendmentService.setCurrentPlan(ddrData);

        const modalAmendmentPopup = this.modal.open(AmendmentRequestComponent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-wide',
          centered: true
        });
        modalAmendmentPopup.componentInstance.data = { amendmentData, unsubmittedPlanId: this.amendment?.id };
      });
  }

  private viewAmendment(plan: UnsubmittedAmendment): void {
    const amendmentData = plan.json_transformed;

    this.amendmentService
      .getAmendmentDetails(amendmentData.ddrId, amendmentData.rpId)
      .pipe(take(1))
      .subscribe((ddrData) => {
        this.amendmentService.setCurrentPlan(ddrData);

        const modalAmendmentPopup = this.modal.open(AmendmentRequestComponent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-wide',
          centered: true
        });
        modalAmendmentPopup.componentInstance.data = { amendmentData, readonly: true };
        modalAmendmentPopup.closed.subscribe();
      });
  }

  private delete(id: string): void {
    const modalDialog = this.modal.open(ModalComponent, { centered: true });
    modalDialog.componentInstance.data = {
      title: 'Delete plan?',
      content: `Are you sure you want to delete unsubmitted amendment ID ${id}?`,
      iconClass: 'uil uil-trash',
      buttons: [
        {
          text: 'Delete',
          class: 'btn-red w-170 mr-1',
          value: true,
          withLoading: true
        },
        {
          text: 'Cancel',
          class: 'btn-primary w-170 ml-1',
          value: false
        }
      ]
    };
    modalDialog.closed.subscribe((result: boolean) => {
      if (result) {
        this.paymentService.deletePlan(id).subscribe((data) => {
          this.toastrService.success(data[0].message, 'Deleted');
        });
      }
    });
  }
}
