import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Brand } from '../enums/brand.enum';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  isInputAutoCompleteEnabled(): boolean {
    return environment?.modules?.general?.shared?.input?.autocomplete?.enabled;
  }

  showRpAppLink(): boolean {
    return environment?.modules?.payments?.smsJourney?.successScreen?.rpAppDetails?.showRpAppLink;
  }

  showTreatmentType(): boolean {
    return environment?.modules?.general?.treatmentType?.show;
  }

  skipBsbValidation(): boolean {
    return environment.modules.payments.smsJourney.skipBsbValidation;
  }

  isDentiCare(): boolean {
    return environment?.client === Brand.Dc;
  }

  isMyHealthBeauty(): boolean {
    return environment?.client === Brand.Mhb;
  }

  isMyHealthCare(): boolean {
    return environment?.client === Brand.Mhc;
  }

  getFileMaxSize(): number {
    return environment.files.maxSize;
  }

  getRpAppPlayStoreLink(): string {
    return environment?.modules?.payments?.smsJourney?.successScreen?.rpAppDetails?.playStoreLink;
  }

  getRpAppAppStoreLink(): string {
    return environment?.modules?.payments?.smsJourney?.successScreen?.rpAppDetails?.appStoreLink;
  }

  showHomePageVideo(): boolean {
    return environment?.modules?.homepage?.showVideo;
  }

  showWebsiteBadgeDownloadBtn(): boolean {
    return environment.modules.marketingCenter.showWebsiteBadgeDownloadBtn;
  }
}
