import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeLineBreak',
  standalone: true
})
export class DecodeLineBreakPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Replace encoded HTML entities with actual HTML tags
    return value.replace(/&lt;br&gt;/g, '<br>');
  }
}
