import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastrService: ToastrService) {
    toastrService.toastrConfig.iconClasses = {
      error: 'pp-toast-error',
      success: 'pp-toast-success'
    };
  }

  info(message?: string, title?: string): void {
    this.toastrService.info(message, title, { enableHtml: true });
  }

  success(message?: string, title?: string): void {
    this.toastrService.success(message, title, { enableHtml: true });
  }

  warning(message?: string, title?: string): void {
    this.toastrService.warning(message, title, { enableHtml: true });
  }

  error(message?: string, title?: string): void {
    this.toastrService.error(message, title, { enableHtml: true });
  }
}
