<sliqpay-header class="page-header-sticky" [pageTitle]="'page_title' | lang : LANG_CONFIG_KEY | async">
  <div class="create-btn-container">
    <sliqpay-create-plan-dropdown
      class="mr-2"
      [moduleType]="ModuleType.Proposal"
      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
    ></sliqpay-create-plan-dropdown>
    <sliqpay-create-plan-dropdown></sliqpay-create-plan-dropdown>
  </div>
</sliqpay-header>
<div class="page-content overflow-hidden">
  <div class="bg-white rounded">
    <sliqpay-payments-menu></sliqpay-payments-menu>
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-8">
            <h6 [innerHTML]="'header' | lang : LANG_CONFIG_KEY | async"></h6>
            <p
              [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.PROMPT_PAY"
              [innerHTML]="'header_desc' | lang : LANG_CONFIG_KEY | async"
            ></p>
            <p
              *ngIf="this.userSettingsService.isPromptPayEnabled() === false"
              [innerHTML]="'header_desc2' | lang : LANG_CONFIG_KEY | async"
            ></p>
          </div>
        </div>
        <sliqpay-list-search-widget
          [config]="(searchWidgetConfig$ | async) ?? []"
          (searchValue)="searchFilter($event)"
          (dateChange)="onDateFilterChange($event)"
        ></sliqpay-list-search-widget>
        <div class="row" *ngIf="paymentPlanFiltered$ | async">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" sliqpaySortable="Patient ID" (sort)="onSort($event)" class="col-narrow">
                      <span class="sorting">Patient ID</span>
                    </th>
                    <th scope="col" sliqpaySortable="DDR" (sort)="onSort($event)">
                      <span class="sorting">DDR</span>
                    </th>
                    <th scope="col" sliqpaySortable="Patient Name" (sort)="onSort($event)">
                      <span class="sorting">Patient name</span>
                    </th>
                    <th scope="col" sliqpaySortable="Responsible Party" (sort)="onSort($event)">
                      <span class="sorting">Responsible Party</span>
                    </th>
                    <th scope="col" sliqpaySortable="Original Plan Total" (sort)="onSort($event)" class="col-narrow">
                      <span class="sorting">Original plan total</span>
                    </th>
                    <th scope="col" sliqpaySortable="Paid To Date" (sort)="onSort($event)" class="col-narrow">
                      <span class="sorting">Provider closing balance</span>
                    </th>
                    <th
                      scope="col"
                      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.PROMPT_PAY"
                      sliqpaySortable="Total Outstanding Amount"
                      (sort)="onSort($event)"
                    >
                      <span class="sorting"
                        >#Sent
                        <i
                          class="uil uil-info-circle field-name-tooltip"
                          placement="bottom"
                          ngbTooltip="{{ noOfSMS }}"
                        ></i
                      ></span>
                    </th>
                    <th
                      scope="col"
                      sliqpaySortable="Total Outstanding Amount"
                      (sort)="onSort($event)"
                      class="col-narrow"
                    >
                      <span class="sorting">Total overdue amount</span>
                    </th>
                    <th
                      scope="col"
                      sliqpaySortable="custrecord_mfa_ddr_provider_comments"
                      (sort)="onSort($event)"
                      class="col-wide"
                    >
                      <span class="sorting">Comments</span>
                    </th>
                    <th scope="col" [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.PROMPT_PAY">
                      Sent invoice
                    </th>
                    <th scope="col" class="col-narrow">Transfer to APCA</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let paymentPlan of (paymentPlanFiltered$ | async) || []
                        | searchFilter : searchData
                        | dateFilter : dateSearchData
                        | paginate : { itemsPerPage: itemPerPage, currentPage: page }
                    "
                  >
                    <td>{{ paymentPlan['Patient ID'] }}</td>
                    <td>{{ paymentPlan['DDR'] }}</td>
                    <td>{{ paymentPlan['Patient Name'] }}</td>
                    <td>{{ paymentPlan['Responsible Party'] }}</td>
                    <td>${{ paymentPlan['Original Plan Total'] | number : '1.2-2' }}</td>
                    <td>${{ paymentPlan['Provider Closing Balance'] || 0 | number : '1.2-2' }}</td>
                    <td [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.PROMPT_PAY">
                      {{ paymentPlan['Invoice SMS Counter'] }}
                    </td>
                    <td class="navColor" (click)="gotoDetailsScreen(paymentPlan)">
                      ${{ paymentPlan['Total Outstanding Amount'] | number : '1.2-2' }}
                    </td>
                    <td class="ellipsis" (click)="comment(paymentPlan)">
                      <span [innerHTML]="paymentPlan.custrecord_mfa_ddr_provider_comments | decodeLineBreak"></span>
                    </td>
                    <td [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.PROMPT_PAY">
                      <label>{{ paymentPlan['Last Invoice SMS Date'] }}</label>
                    </td>
                    <td class="border-right-0">
                      <div class="d-flex justify-content-between">
                        <label *ngIf="paymentPlan['Escalate to Collection Agency'] === true">Transferred to APCA</label>
                      </div>
                    </td>
                    <td>
                      <sliqpay-missed-payments-list-action-menu
                        [plan]="paymentPlan"
                        (invoiceSent)="invoiceSent($event)"
                        (commentActionChange)="onCommentActionChange($event)"
                      ></sliqpay-missed-payments-list-action-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sliqpay-empty-screen
      iconClass="icon-empty-screen"
      text="No missed payments here yet"
      *ngIf="(paymentPlanFiltered$ | async) === null || (paymentPlanFiltered$ | async)?.length === 0"
    ></sliqpay-empty-screen>
  </div>

  <sliqpay-pagination
    [(page)]="page"
    [(itemPerPage)]="itemPerPage"
    *ngIf="paymentPlanFiltered$ | async"
  ></sliqpay-pagination>
</div>
