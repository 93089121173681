import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertType } from './alert-type.enum';

@Component({
  selector: 'sliqpay-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @Input() message = '';
  @Input() type: AlertType = AlertType.SUCCESS;

  alertType = AlertType;
}
