import { Injectable } from '@angular/core';
import { TierPermission } from '../../models/auth/tier-permission.interface';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class TierPermissionService {
  constructor(private helperService: HelperService) {}

  getPermission(): TierPermission {
    return JSON.parse(sessionStorage.getItem('user') as string)?.tier_permissions;
  }

  getCreatePlanPermission(): boolean {
    return this.getPermission().custrecord_tier_create_plan;
  }

  getAmendmentIncreasePermission(): boolean {
    return this.getPermission().custrecord_tier_amend_increase;
  }

  getCreateTemplatePermission(): boolean {
    return this.getPermission().custrecord_tier_create_template;
  }
}
