import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sliqpay-empty-screen',
  templateUrl: './empty-screen.component.html',
  styleUrls: ['./empty-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyScreenComponent {
  @Input() text = 'No plans here yet';
  @Input() iconClass = 'icon-empty-screen';
}
