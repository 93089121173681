<div class="container-fluid">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-7 order-1 order-md-0">
        <h5 [innerHTML]="'general' | lang: LANG_CONFIG_KEY | async" class="mb-3"></h5>
      </div>
    </div>

    <div class="widget-login__form">
      <div class="row">
        <div class="col-6">
          <sliqpay-input formControlName="name" [config]="providerNameConfig$ | async"></sliqpay-input>
        </div>
        <div class="col-6">
          <sliqpay-input formControlName="abn" [config]="abnConfig$ | async"></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <sliqpay-input formControlName="phone" [config]="phoneNumberConfig$ | async"></sliqpay-input>
        </div>
        <div class="col-6">
          <sliqpay-input formControlName="url" [config]="websiteConfig$ | async"></sliqpay-input>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <sliqpay-input formControlName="registration_date" [config]="regDateConfig$ | async"></sliqpay-input>
        </div>
        <div class="col-6">
          <sliqpay-input formControlName="clinic_type" [config]="clinicTypeConfig$ | async"></sliqpay-input>
        </div>
      </div>
    </div>
    <hr />

    <h5 [innerHTML]="'fee_sched' | lang: LANG_CONFIG_KEY | async"></h5>
    <div class="text-left">
      <mat-grid-list [cols]="glCols" [rowHeight]="glRowHeight" [gutterSize]="glGutterSize">
        <mat-grid-tile
          *ngFor="let feeScheduleTile of feeScheduleTiles"
          [colspan]="feeScheduleTile.cols"
          [rowspan]="feeScheduleTile.rows"
          [style.background]="feeScheduleTile.color"
        >
          <span [innerHTML]="feeScheduleTile.text"></span>
        </mat-grid-tile>
      </mat-grid-list>
      <br />
      <p *ngFor="let disclaimer of disclaimers">{{ disclaimer }}</p>
    </div>
    <hr />

    <h5 [innerHTML]="'brand_contact' | lang: LANG_CONFIG_KEY | async"></h5>
    <div class="row">
      <div class="col-6">
        <sliqpay-input formControlName="accountmanager_fname" [config]="practiceManagerConfig$ | async"></sliqpay-input>
      </div>
      <div class="col-6">
        <sliqpay-input formControlName="accountmanager_phone" [config]="managerPhoneConfig$ | async"></sliqpay-input>
      </div>
      <div class="col-6">
        <sliqpay-input formControlName="accountmanager_email" [config]="emailConfig$ | async"></sliqpay-input>
      </div>
    </div>
    <hr />

    <h5 [innerHTML]="'trustee_details' | lang: LANG_CONFIG_KEY | async"></h5>
    <div class="row">
      <div class="col-12">
        <p [innerHTML]="'trustie_desc' | lang: LANG_CONFIG_KEY | async"></p>
      </div>
    </div>

    <div class="row file-upload-container">
      <div class="file-upload-input-container">
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            name="customFile"
            id="customFile"
            accept=".pdf,.doc,.docx"
            (change)="handleFileInput($event)"
          />
          <label class="custom-file-label" for="customFile">{{ inputFilePlaceholder }}</label>

          <div class="invalid-feedback d-block" *ngIf="form.get('file')?.errors">
            <div *ngIf="form.get('file')?.errors?.fileSizeValidator">
              File is too big ({{ MAX_FILE_SIZE }} file size limit)
            </div>
          </div>
        </div>
      </div>
      <div class="file-name-label-container">
        <label class="font-weight-bold">Current File Name: </label><br />
        <span>{{ fileName$ | async }}</span>
      </div>
    </div>
  </form>
</div>
