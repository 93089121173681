import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SupportDataService } from '@app/core/data-services/support/support-data.service';
import { TemplatesDataService } from '@app/core/data-services/templates/templates-data.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { ModalComponent } from '@core/components/modal/modal.component';
import { SmsJourneyPlan, UnsubmittedPlan } from '@core/models';
import { HelperService } from '@core/services/helper.service';
import { NotificationService } from '@core/services/notification.service';
import { PaymentService } from '@core/services/payment.service';
import { environment } from '@environments/environment';

import { SourceListId } from '@app/core/enums/ddr-source-list.enum';
import { PendingPlanType } from '@app/core/enums/pending-plan-type.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { SmsJourneyStep } from '@app/core/enums/sms-journey-step.enum';
import { SendSmsQueryParams } from '@app/core/models/payment/sms.interface';
import { BnplCalculatorV2Service } from '@app/core/services/bnpl-calculator-v2/bnpl-calculator-v2.service';
import { DobService } from '@app/core/services/dob/dob.service';
import { EnvironmentService } from '@app/core/services/environment.service';
import { PlanTypeService } from '@app/core/services/plan-type/plan-type.service';
import { PlusConnectCalculatorService } from '@app/core/services/plus-connect-calculator/plus-connect-calculator.service';
import { PrintDownloadService } from '@app/core/services/print-download/print-download.service';
import { RpAffordabilityService } from '@app/core/services/rp-affordability/rp-affordability.service';
import { UserSegmentService } from '@app/core/services/user-segment/user-segment.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { CommentModalComponent } from '@app/payments/modals/comment-modal/comment-modal.component';
import { DiscountType } from '@app/proposal-calculator/enums/discount.enum';
import { CopyPendingRecordComponent } from '@app/provider-journey/copy-pending-record/copy-pending-record.component';
import { CreateNewProposalService } from '@app/provider-journey/create-new-proposal/create-new-proposal.service';
import { ProposalDataService } from '@app/provider-journey/create-new-proposal/data-services/proposal-data.service';
import { AppThreeDotActionMenu } from '@app/shared/interfaces/three-dot-action-menu.interface';
import * as DDRValidator from '@core/services/ddr.validator';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { PaymentsUnsubmittedActionMenuService, PendingPlansActions } from './payments-unsubmitted-action-menu.service';

@Component({
  selector: 'sliqpay-payments-unsubmitted-action-menu',
  templateUrl: './payments-unsubmitted-action-menu.component.html',
  styleUrls: ['./payments-unsubmitted-action-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PaymentsUnsubmittedActionMenuService,
    BnplCalculatorV2Service,
    PlusConnectCalculatorService,
    RpAffordabilityService,
    CreateNewProposalService,
    PrintDownloadService,
    ProposalDataService
  ]
})
export class PaymentsUnsubmittedActionMenuComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'payments_unsubmitted_action_menu';
  PendingPlansActions = PendingPlansActions;

  @Input() plan: UnsubmittedPlan | null = null;
  @Output() dropdownChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  actionsConfig$!: Observable<AppThreeDotActionMenu>;
  unsubscribe$ = new Subject<boolean>();

  private proposalForm!: FormGroup;
  private basicPlanForm!: FormGroup;

  get treatmentOptions(): FormArray {
    return this.proposalForm.get('treatmentOptions') as FormArray;
  }

  constructor(
    public helperService: HelperService,
    public authService: AuthService,
    private notificationService: NotificationService,
    private supportService: SupportDataService,
    private ppamService: PaymentsUnsubmittedActionMenuService,
    private planTypeService: PlanTypeService,
    private bnplCalculatorV2Service: BnplCalculatorV2Service,
    private plusConnectCalcV2Service: PlusConnectCalculatorService,
    protected fb: UntypedFormBuilder,
    protected activatedRoute: ActivatedRoute,
    protected templateService: TemplatesDataService,
    protected paymentService: PaymentService,
    protected modal: NgbModal,
    protected router: Router,
    private toastService: ToastrService,
    private environmentService: EnvironmentService,
    private dobService: DobService,
    private createNewProposalService: CreateNewProposalService,
    private printDownloadService: PrintDownloadService,
    private userSettingsService: UserSettingsService,
    private userSegmentService: UserSegmentService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.actionsConfig$ = this.ppamService.getActionsConfig(this.plan);
  }

  onActionChange(action: string): void {
    if (!this.plan) {
      return;
    }

    switch (action) {
      case PendingPlansActions.Comment: {
        this.comment(this.plan);
        break;
      }
      case PendingPlansActions.ResumePlan: {
        const sendSmsProgress = this.plan.plan_progress.find((progress) => progress.custrecord_spps_step_id === SmsJourneyStep.SmsSent);

        if (!sendSmsProgress?.custrecord_spps_completed) {
          if (this.plan.custrecord_module_type === PendingPlanType.PaymentPlan) {
            this.resumePendingPlan(this.plan.id);
          } else if (this.plan.custrecord_module_type === PendingPlanType.Proposal) {
            this.resumePendingProposal(this.plan.id);
          }
        } else {
          const recordType = this.plan.custrecord_module_type === PendingPlanType.PaymentPlan ? 'Plan' : 'Proposal';
          this.openCopyRecordModal(recordType);
        }

        break;
      }
      case PendingPlansActions.SendSms: {
        const planType = this.plan.json_transformed.plan_type as PlanType;
        if (planType !== PlanType.Bnpl) {
          this.plusConnectCalcV2Service.setPlanType(planType);
        }

        if (this.plan.custrecord_module_type === PendingPlanType.PaymentPlan) {
          this.setBasicPlanForm();

          this.setBasicPlanValues(this.plan.json_transformed as any);

          this.setBasicPlanValidators();
          this.basicPlanForm.updateValueAndValidity();

          if (!this.basicPlanForm.invalid) {
            this.checkAffordability(this.plan.id);
          } else {
            this.openSendSmsValidationModal();
          }
        } else if (this.plan.custrecord_module_type === PendingPlanType.Amendment) {
          this.smsAmend(this.plan);
        } else if (this.plan.custrecord_module_type === PendingPlanType.Proposal) {
          // Set form
          this.setProposalForm();
          // Set values
          this.setProposalValues(this.plan.json_transformed as any);
          // Set validators
          this.setProposalValidators();
          this.proposalForm.updateValueAndValidity();

          if (!this.proposalForm.invalid) {
            this.checkAffordability(this.plan.id, true);
          } else {
            this.openSendSmsValidationModal();
          }
        }
        break;
      }
      case PendingPlansActions.Delete: {
        this.delete(this.plan);
        break;
      }
      case PendingPlansActions.View: {
        if (this.plan.custrecord_module_type === PendingPlanType.PaymentPlan) {
          this.resumePendingPlan(this.plan.id, true);
        } else if (this.plan.custrecord_module_type === PendingPlanType.Proposal) {
          this.resumePendingProposal(this.plan.id, true);
        }

        break;
      }
      case PendingPlansActions.DownloadAndPrintProposal: {
        if (this.plan.custrecord_module_type === PendingPlanType.Proposal) {
          // Set form
          this.setProposalForm();
          // Set values
          this.setProposalValues(this.plan?.json_transformed as any);
          // Set validators
          this.setProposalValidators();
          this.proposalForm.updateValueAndValidity();
          this.downloadAndPrint();
        }
        break;
      }
    }
  }

  private downloadAndPrint(): void {
    const previewProposalPayload = {
      ...this.proposalForm.value,
      unsubId: this.plan?.id
    };
    this.createNewProposalService
      .previewProposal(previewProposalPayload, this.plan?.created_at)
      .pipe(
        map((base64: string) => {
          return this.printDownloadService.openPrintWindow(base64);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private delete(plan: UnsubmittedPlan): void {
    const modalDialog = this.modal.open(ModalComponent, { centered: true });
    modalDialog.componentInstance.data = {
      title: 'Delete plan?',
      content: `Are you sure you want to delete saved plan ${plan.id}?`,
      iconClass: 'uil uil-trash',
      buttons: [
        {
          text: 'Delete',
          class: 'btn-red w-170 mr-1',
          value: true,
          withLoading: true
        },
        {
          text: 'Cancel',
          class: 'btn-primary w-170 ml-1',
          value: false
        }
      ]
    };
    modalDialog.closed.pipe(takeUntil(this.unsubscribe$)).subscribe((result: boolean) => {
      if (result) {
        this.paymentService
          .deletePlan(plan.id)
          .pipe(take(1))
          .subscribe((data) => {
            if (data[0].message) {
              this.toastService.success(data[0].message, 'Deleted');
            }
          });
      }
    });
  }

  private checkAffordability(id: string, isProposal = false): void {
    const planValues = isProposal ? this.proposalForm.getRawValue() : this.basicPlanForm.getRawValue();
    this.ppamService
      .postCheckRpAffordability(planValues, isProposal)
      .pipe(
        switchMap((proceedWithSms) => {
          if (proceedWithSms) {
            if (isProposal) {
              return this.sendSmsWithChecks(id);
            }
            return this.sendSms(id);
          }

          return of(proceedWithSms);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private smsAmend(plan: any) {
    this.supportService
      .postSendAmendmentSMS(plan)
      .pipe(take(1))
      .subscribe(
        (response) => {
          const message = 'SMS has been sent to ' + plan.json_transformed.recepientMobileNo + '.';
          const title = response?.data[0]?.title;
          this.paymentService.fetchUnsubmittedPlans();
          this.notificationService.success(message, title);
        },
        (error) => {
          const modal = this.modal.open(ModalComponent, { centered: true });
          modal.componentInstance.data = {
            content: `Unfortunately we are unable to proceed with your application. Please contact ${environment.client} for further assistance.`
          };
        }
      );
  }

  private sendSmsWithChecks(planId: string) {
    return this.sendSms(planId, { ddrSource: SourceListId.Sms, check_expiry: true, moduleType: PendingPlanType.Proposal });
  }

  private sendSms(id: string, options: SendSmsQueryParams = {}): Observable<any> {
    return this.paymentService.smsPlan(id, options).pipe(
      map((response) => {
        if (response.data[0].message) {
          this.paymentService.fetchUnsubmittedPlans();
          const title = response?.data[0]?.title;
          this.notificationService.success(response.data[0].message, title);
        }
      }),
      take(1)
    );
  }

  private resumePendingPlan(id: string, isView = false): void {
    const progress = this.plan?.plan_progress;
    const smsSent = progress?.find((x) => x.custrecord_spps_step_id === 'smsSent');

    this.paymentService.prepare(id);

    this.router.navigate(['/provider-journey/create-plan'], {
      queryParams: {
        savedPlanId: id,
        isSmsOpened: !!smsSent && smsSent.custrecord_spps_completed,
        isView
      }
    });
  }

  private resumePendingProposal(id: string, isView = false): void {
    const progress = this.plan?.plan_progress;
    const smsSent = progress?.find((x) => x.custrecord_spps_step_id === 'smsSent');

    this.router.navigate(['/provider-journey/create-proposal'], {
      queryParams: {
        planType: this.plan?.$$initialPlanType,
        savedPlanId: id,
        isSmsOpened: !!smsSent && smsSent.custrecord_spps_completed,
        isView
      }
    });
  }

  private comment(plan: UnsubmittedPlan): void {
    const modal = this.modal.open(CommentModalComponent, { centered: true });
    modal.componentInstance.id = plan.id;
    modal.componentInstance.propertyName = 'custrecord_unsubmitted_comment';
    modal.componentInstance.commentValue = plan.custrecord_unsubmitted_comment;
    modal.closed
      .pipe(
        filter((d) => !!d),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        const updatedValue = { ...plan, custrecord_unsubmitted_comment: data.custrecord_unsubmitted_comment };
        this.paymentService.updateUnsubmittedPlan(updatedValue.id, updatedValue);
      });
  }

  private setBasicPlanForm(): void {
    this.basicPlanForm = this.fb.group({
      patientDetails: this.fb.group({
        isPatientIsRp: [false, [Validators.required]],
        title: [null, [Validators.required]],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        patientId: [null],
        dob: [null, [Validators.required]],
        mobilePhone: [null],
        mobilePhone2: [null]
      }),
      rpDetails: this.fb.group({
        title: [null, [Validators.required]],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        mobilePhone: [null, [Validators.required]],
        mobilePhone2: [null],
        dob: [null, [Validators.required]]
      }),
      paymentPlan: this.fb.group({
        generalDetails: this.fb.group({
          doctor: [null, [Validators.required]],
          treatmentType: [null, [Validators.required]],
          orthoItems: [null],
          planType: [null, [Validators.required]],
          templateDd: [null]
        }),
        paymentPlanDetails: this.fb.group({
          totalTreatmentCost: [null, [Validators.required]],
          deposit: [null, [Validators.required]],
          paymentPlanTotal: [null, [Validators.required]],
          paymentFrequency: [null, [Validators.required]],
          quote: this.fb.array([
            this.fb.group({
              paymentPlanDurationInMonths: [null, [Validators.required]],
              weekly: [null],
              fortnightly: [null],
              monthly: [null]
            })
          ]),
          singlePayments: [null],
          noOfPayments: [null],
          overrideTermLength: [null],
          firstDebitDate: this.fb.group({
            specificDate: [null, [Validators.required]]
          }),
          maxRepaymentAmount: [null], // For PLUS validation only
          confirmDetailsCbx: [null],
          isProactiveConnect: [null]
        })
      }),
      ddrSource: [null],
      oldSavedPlanId: [null],
      prevDdr: [''],
      resetPlanProgress: [false]
    });
  }

  private setBasicPlanValues(plan: SmsJourneyPlan): void {
    this.basicPlanForm.patchValue({
      patientDetails: {
        isPatientIsRp: !!plan.custrecord_mfa_ddr_copy_rp_fields,
        title: plan.patient_title,
        firstName: plan.patient_firstname,
        lastName: plan.patient_lastname,
        patientId: plan.practice_patient_id_no,
        dob: plan.custrecord_mfa_ddr_patient_dob ? this.helperService.parseDate(plan.custrecord_mfa_ddr_patient_dob, 'dd/MM/yyyy') : null,
        mobilePhone: plan.custrecord_mfa_ddr_copy_rp_fields ? plan.rp_mobile_num : ''
      },
      rpDetails: {
        title: plan.rp_title,
        firstName: plan.rp_firstname,
        lastName: plan.rp_lastname,
        mobilePhone: plan.rp_mobile_num,
        dob: plan.rp_dob ? this.helperService.parseDate(plan.rp_dob, 'dd/MM/yyyy') : null
      },
      paymentPlan: {
        generalDetails: {
          doctor: plan.doctor_id,
          treatmentType: plan.treatment_type,
          orthoItems: plan.ortho_items,
          planType: plan.plan_type
        },
        paymentPlanDetails: {
          totalTreatmentCost: plan.total_plan_value,
          deposit: plan.deposit,
          paymentPlanTotal: plan.payment_plan_total,
          paymentFrequency: plan.payment_freq,
          singlePayments: plan.custrecord_mfa_ddr_single_payments,
          noOfPayments: plan.no_of_payments,
          overrideTermLength: !this.planTypeService.isPlanTypeBnpl((plan.plan_type as PlanType) || null),
          quote: [
            {
              paymentPlanDurationInMonths: plan.quote[0].term_months,
              weekly: plan.quote[0].weekly,
              fortnightly: plan.quote[0].fortnightly,
              monthly: plan.quote[0].monthly
            }
          ],
          firstDebitDate: {
            specificDate: this.helperService.parseDate(plan.start_date, 'dd/MM/yyyy')
          },
          isProactiveConnect: plan.custrecord_ddrip_proactive,
          confirmDetailsCbx: plan.confim_details_cbx
        }
      },

      ddrSource: plan.ddr_source
    });
  }

  private setBasicPlanValidators(): void {
    const generalDetails = this.basicPlanForm?.get('paymentPlan.generalDetails');
    const paymentPlanDetails = this.basicPlanForm?.get('paymentPlan.paymentPlanDetails');
    const planType = generalDetails?.get('planType')?.value;

    generalDetails?.get('treatmentType')?.setValidators(this.environmentService.showTreatmentType() ? Validators.required : null);
    generalDetails
      ?.get('orthoItems')
      ?.setValidators(this.ppamService.isTreatmentTypeOtho(generalDetails.get('treatmentType')?.value) ? Validators.required : null);

    if (this.planTypeService.isPlanTypeBnpl(planType)) {
      this.bnplCalculatorV2Service.setFieldValidators(paymentPlanDetails as FormGroup);
    } else {
      this.plusConnectCalcV2Service.setFieldValidators(paymentPlanDetails as FormGroup);
      // BE handles start date validation
      // TO DO: Refactor validations
      paymentPlanDetails
        ?.get('firstDebitDate.specificDate')
        ?.setValidators([Validators.required, DDRValidator.maxDateValidator(this.plusConnectCalcV2Service.getMaxPaymentStartDate())]);
      paymentPlanDetails?.get('firstDebitDate.specificDate')?.updateValueAndValidity();

      // Max repayment amount is only applicable to PLUS
      if (this.planTypeService.isPlanTypePlus(planType)) {
        this.setMaxRepaymentAmountV2(paymentPlanDetails);
      }
    }

    this.basicPlanForm
      .get('patientDetails.dob')
      ?.setValidators(
        this.basicPlanForm.get('patientDetails.isPatientIsRp')?.value
          ? [
              Validators.required,
              DDRValidator.minDateValidator(this.dobService.getDefaultMinDate()),
              DDRValidator.isDOBMinYearsValidator(this.dobService.getMinAllowedRpAge())
            ]
          : [Validators.required, DDRValidator.isDOBMinYearsTodayValidator]
      );

    this.basicPlanForm
      .get('patientDetails.mobilePhone')
      ?.setValidators(this.basicPlanForm.get('patientDetails.isPatientIsRp')?.value ? [Validators.required] : null);

    paymentPlanDetails
      ?.get('confirmDetailsCbx')
      ?.setValidators(
        paymentPlanDetails.get('isProactiveConnect')?.value && !this.userSettingsService.isKycEnabled() ? Validators.required : null
      );
  }

  private openSendSmsValidationModal(): void {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      iconClass: 'icon-type-error',
      title: 'Warning',
      content: 'send_sms_validation',
      langConfigKey: this.LANG_CONFIG_KEY,
      buttons: [
        {
          text: 'Close',
          class: 'btn-primary w-230 ml-1',
          value: 'true'
        }
      ]
    };
  }

  private openCopyRecordModal(recordType: 'Plan' | 'Proposal'): void {
    const modal = this.modal.open(CopyPendingRecordComponent, { size: 'sm', centered: true, keyboard: false, backdrop: 'static' });
    modal.componentInstance.recordType = recordType;
    modal.closed.subscribe((copy: boolean) => {
      if (!this.plan || !copy) {
        return;
      }

      if (recordType === 'Plan') {
        this.resumePendingPlan(this.plan.id);
      } else {
        this.resumePendingProposal(this.plan.id);
      }
    });
  }

  private findTreatmentOptionIndexById(id: number): number {
    return this.treatmentOptions.controls.findIndex((control) => {
      return control.value.id === id;
    });
  }

  private setProposalValues(pendingProposal: SmsJourneyPlan): void {
    if (!pendingProposal) {
      return;
    }

    this.proposalForm.patchValue({
      patientDetails: {
        isPatientIsRp: !!pendingProposal.custrecord_mfa_ddr_copy_rp_fields,
        title: pendingProposal.patient_title,
        firstName: pendingProposal.patient_firstname,
        lastName: pendingProposal.patient_lastname,
        patientId: pendingProposal.practice_patient_id_no,
        dob: pendingProposal.custrecord_mfa_ddr_patient_dob
          ? this.helperService.parseDate(pendingProposal.custrecord_mfa_ddr_patient_dob, 'dd/MM/yyyy')
          : null,
        mobilePhone: pendingProposal.custrecord_mfa_ddr_copy_rp_fields ? pendingProposal.rp_mobile_num : ''
      },
      rpDetails: {
        title: pendingProposal.rp_title,
        firstName: pendingProposal.rp_firstname,
        lastName: pendingProposal.rp_lastname,
        mobilePhone: pendingProposal.rp_mobile_num,
        dob: pendingProposal.rp_dob ? this.helperService.parseDate(pendingProposal.rp_dob, 'dd/MM/yyyy') : null
      },
      common: {
        introductoryTextSms: pendingProposal.introductory_text_sms || '',
        introductoryTextPrintout: pendingProposal.introductory_text_printout || '',
        notes: pendingProposal.practice_notes
          ? pendingProposal.practice_notes
          : pendingProposal?.customrecord_template_proposal?.custrecord_practice_notes || '',
        confirmDetailsCbx: pendingProposal.confim_details_cbx
      },
      isProactiveConnect: pendingProposal.custrecord_ddrip_proactive,
      ddrSource: pendingProposal.ddr_source || SourceListId.Sms
    });

    this.treatmentOptions.clear();
    pendingProposal.treatment_options?.forEach((treatmentOption, index) => {
      this.addTreatmentOption();

      this.treatmentOptions.at(index).patchValue({
        treatmentDescription: treatmentOption.treatment_description,
        practiceDocuments: treatmentOption.text_section_list,
        selected: false,
        isPifSelected: treatmentOption.is_pif_selected,
        isPaymentPlanSelected: treatmentOption.is_payment_plan_selected
      });

      this.treatmentOptions.at(index).get('generalDetails')?.patchValue({
        doctor: treatmentOption.doctor_id,
        treatmentType: treatmentOption.treatment_type,
        orthoItems: treatmentOption.ortho_items,
        treatmentName: treatmentOption.custrecord_tp_treatment_option,
        planType: pendingProposal.plan_type
      });

      this.treatmentOptions
        .at(index)
        .get('paymentPlanDetails.firstDebitDate')
        ?.patchValue({
          specificDate: this.helperService.parseDate(treatmentOption.start_date, 'dd/MM/yyyy'),
          relativeOption: treatmentOption.first_payment_date_option,
          relativeInput: treatmentOption.first_payment_date_input,
          type: treatmentOption.first_payment_date_type
        });

      this.treatmentOptions
        .at(index)
        .get('paymentPlanDetails')
        ?.patchValue({
          totalTreatmentCost: treatmentOption.total_plan_value,
          discountAmount: treatmentOption.discount_amount,
          discountPercentage: treatmentOption.discount_percent,
          discountType: treatmentOption.discount_type,
          deposit: treatmentOption.deposit,
          paymentPlanTotal: treatmentOption.payment_plan_total,
          paymentFrequency: treatmentOption.payment_freq,
          discountReason: treatmentOption.discount_reason,
          singlePayments: treatmentOption.custrecord_mfa_ddr_single_payments,
          noOfPayments: treatmentOption.no_of_payments,
          overrideTermLength: treatmentOption.custrecord_override_term_length,
          depositPayee: treatmentOption.pay_deposit_to ? treatmentOption.pay_deposit_to.toString() : null
        });

      if (this.userSettingsService.isPayInFullEnabled()) {
        const totalPlanValue = treatmentOption.pif_total_plan_value || 0;
        const discount = treatmentOption.pif_discount_amount || 0;
        const otherDiscount = treatmentOption.pif_other_discount_amount || 0;

        this.treatmentOptions
          .at(index)
          .get('payInFull')
          ?.patchValue({
            fullPaymentTotalTreatmentCost: totalPlanValue,
            fullPaymentDiscount: discount,
            fullPaymentDiscountType: treatmentOption.pif_discount_type,
            fullPaymentDiscountPercentage: treatmentOption.pif_discount_percent,
            fullPaymentOtherDiscount: otherDiscount,
            fullPaymentNetTotal: totalPlanValue - discount - otherDiscount,
            fullPaymentPaymentPreference: treatmentOption.pif_pay_to
          });
      }

      const quoteFormArray: any = this.treatmentOptions.at(index).get('paymentPlanDetails.quote');
      if (quoteFormArray) {
        quoteFormArray.at(0).patchValue({
          paymentPlanDurationInMonths: treatmentOption.quote[0].term_months,
          weekly: treatmentOption.quote[0].weekly,
          fortnightly: treatmentOption.quote[0].fortnightly,
          monthly: treatmentOption.quote[0].monthly
        });
      }
    });
  }

  private setProposalValidators(): void {
    const treatmentOptions = this.proposalForm.get('treatmentOptions') as FormArray;

    treatmentOptions.controls.forEach((control) => {
      const planType = control?.get('generalDetails.planType')?.value;

      control.get('generalDetails.treatmentType')?.setValidators(this.environmentService.showTreatmentType() ? Validators.required : null);
      control.get('generalDetails.treatmentType')?.updateValueAndValidity();

      control
        .get('generalDetails.orthoItems')
        ?.setValidators(
          this.ppamService.isTreatmentTypeOtho(control.get('generalDetails.treatmentType')?.value) ? Validators.required : null
        );
      control.get('generalDetails.orthoItems')?.updateValueAndValidity();

      if (this.planTypeService.isPlanTypeBnpl(planType)) {
        this.bnplCalculatorV2Service.setFieldValidators(control.get('paymentPlanDetails') as FormGroup);

        control.get('paymentPlanDetails.firstDebitDate.specificDate')?.setValidators(Validators.required);
        control.get('paymentPlanDetails.firstDebitDate.specificDate')?.updateValueAndValidity();
      } else {
        this.plusConnectCalcV2Service.setFieldValidators(
          control.get('paymentPlanDetails') as FormGroup,
          control.get('paymentPlanDetails.discountType')?.value
        );

        // Validation of term months
        control
          .get('paymentPlanDetails.quote.0.paymentPlanDurationInMonths')
          ?.setValidators([Validators.min(1), Validators.max(this.plusConnectCalcV2Service.getMaxTermMonths(control as FormGroup))]);
        control.get('paymentPlanDetails.quote.0.paymentPlanDurationInMonths')?.updateValueAndValidity();

        this.setDiscountValidator(
          this.plusConnectCalcV2Service.getMaxDiscount(
            control.get('paymentPlanDetails.discountType')?.value,
            control.get('paymentPlanDetails.totalTreatmentCost')?.value,
            control.get('paymentPlanDetails.deposit')?.value
          ),
          control.get('paymentPlanDetails')
        );

        // BE handles start date validation
        // TO DO: Refactor validations
        control
          ?.get('paymentPlanDetails.firstDebitDate.specificDate')
          ?.setValidators([Validators.required, DDRValidator.maxDateValidator(this.plusConnectCalcV2Service.getMaxPaymentStartDate())]);
        control?.get('paymentPlanDetails.firstDebitDate.specificDate')?.updateValueAndValidity();

        // Max repayment amount is only applicable to PLUS
        if (this.planTypeService.isPlanTypePlus(planType)) {
          this.setMaxRepaymentAmountV2(control.get('paymentPlanDetails'));
        }
      }
    });

    this.proposalForm
      .get('patientDetails.dob')
      ?.setValidators(
        this.proposalForm.get('patientDetails.isPatientIsRp')?.value
          ? [
              Validators.required,
              DDRValidator.minDateValidator(this.dobService.getDefaultMinDate()),
              DDRValidator.isDOBMinYearsValidator(this.dobService.getMinAllowedRpAge())
            ]
          : [Validators.required, DDRValidator.isDOBMinYearsTodayValidator]
      );
    this.proposalForm.get('patientDetails.dob')?.updateValueAndValidity();

    this.proposalForm
      .get('patientDetails.mobilePhone')
      ?.setValidators(this.proposalForm.get('patientDetails.isPatientIsRp')?.value ? [Validators.required] : null);
    this.proposalForm.get('patientDetails.mobilePhone')?.updateValueAndValidity();

    this.proposalForm
      .get('common.confirmDetailsCbx')
      ?.setValidators(
        this.proposalForm.get('isProactiveConnect')?.value && !this.userSettingsService.isKycEnabled() ? Validators.required : null
      );
    this.proposalForm.get('common.confirmDetailsCbx')?.updateValueAndValidity();
  }

  private setProposalForm(): void {
    this.proposalForm = this.fb.group({
      patientDetails: this.fb.group({
        isPatientIsRp: [false, [Validators.required]],
        title: [null, [Validators.required]],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        patientId: [null],
        dob: [null, [Validators.required]],
        mobilePhone: [null],
        mobilePhone2: [null]
      }),
      rpDetails: this.fb.group({
        title: [null, [Validators.required]],
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        mobilePhone: [null, [Validators.required]],
        mobilePhone2: [null],
        dob: [
          null,
          [
            Validators.required,
            DDRValidator.minDateValidator(this.dobService.getDefaultMinDate()),
            DDRValidator.isDOBMinYearsValidator(this.dobService.getMinAllowedRpAge())
          ]
        ]
      }),
      treatmentOptions: this.fb.array([]),
      common: this.fb.group({
        notes: [null],
        confirmDetailsCbx: [null],
        introductoryTextSms: [null],
        introductoryTextPrintout: [null]
      }),
      isProactiveConnect: [null, Validators.required],
      ddrSource: [null, Validators.required]
    });
  }

  private createTreatmentOptionForm(): FormGroup {
    return this.fb.group({
      generalDetails: this.fb.group({
        doctor: [null, [Validators.required]],
        treatmentType: [null],
        orthoItems: [null],
        planType: [null, [Validators.required]],
        templateDd: [null],
        treatmentName: [null, [Validators.required]]
      }),
      paymentPlanDetails: this.fb.group({
        totalTreatmentCost: [null, [Validators.required]],
        discountAmount: [0],
        discountPercentage: [0],
        discountType: [null, [Validators.required]],
        deposit: [null, [Validators.required]],
        paymentPlanTotal: [null, [Validators.required]],
        paymentFrequency: [null, [Validators.required]],
        discountReason: [null],
        quote: this.fb.array([
          this.fb.group({
            paymentPlanDurationInMonths: [null, [Validators.required]],
            weekly: [null],
            fortnightly: [null],
            monthly: [null]
          })
        ]),
        singlePayments: [null],
        noOfPayments: [null],
        overrideTermLength: [null],
        firstDebitDate: this.fb.group({
          specificDate: [null],
          relativeOption: [null],
          relativeInput: [null],
          type: [null, Validators.required]
        }),
        maxRepaymentAmount: [null],
        depositPayee: [null]
      }),
      payInFull: this.fb.group({
        fullPaymentTotalTreatmentCost: [0, Validators.min(0)],
        fullPaymentDiscount: [0, Validators.min(0)],
        fullPaymentDiscountType: [DiscountType.Currency],
        fullPaymentDiscountPercentage: [0],
        fullPaymentOtherDiscount: [0, Validators.min(0)],
        fullPaymentNetTotal: [0, Validators.min(0)],
        fullPaymentPaymentPreference: [null]
      }),
      treatmentDescription: [null, Validators.required],
      practiceDocuments: [this.getAllPracticeDocumentIds()],
      selected: [false],
      isPaymentPlanSelected: [true],
      isPifSelected: [this.userSettingsService.isPayInFullEnabled()]
    });
  }

  private addTreatmentOption(): void {
    const newTreatmentOption = this.createTreatmentOptionForm();
    this.treatmentOptions?.push(newTreatmentOption);
  }

  private setMaxRepaymentAmountV2(control: AbstractControl | null): void {
    const maxRepaymentAmountPerMonth = this.plusConnectCalcV2Service.getMaxDirectDebitAmount();
    let maxRepaymentAmountByFreq = maxRepaymentAmountPerMonth;

    switch (+control?.get('paymentFrequency')?.value) {
      case 1: {
        maxRepaymentAmountByFreq = maxRepaymentAmountByFreq / 4;
        break;
      }
      case 2: {
        maxRepaymentAmountByFreq = maxRepaymentAmountByFreq / 2;
        break;
      }
    }

    control?.get('maxRepaymentAmount')?.setValue(maxRepaymentAmountByFreq);
    control?.get('maxRepaymentAmount')?.setValidators(DDRValidator.maxRepaymentAmountValidator(control?.get('singlePayments')?.value));
    control?.get('maxRepaymentAmount')?.updateValueAndValidity();
  }

  private setDiscountValidator(maxDiscount: number, control: AbstractControl | null): void {
    maxDiscount = this.helperService.roundUpToTwoDecimal(maxDiscount);
    switch (control?.get('discountType')?.value) {
      case DiscountType.Currency: {
        control?.get('discountAmount')?.setValidators([Validators.required, Validators.min(0), Validators.max(maxDiscount)]);
        control?.get('discountPercentage')?.setValidators(null);
        control?.get('discountAmount')?.updateValueAndValidity();
        control?.get('discountPercentage')?.updateValueAndValidity();
        break;
      }
      case DiscountType.Percentage: {
        control?.get('discountPercentage')?.setValidators([Validators.required, Validators.min(0), Validators.max(maxDiscount)]);
        control?.get('discountAmount')?.setValidators(null);
        control?.get('discountAmount')?.updateValueAndValidity();
        control?.get('discountPercentage')?.updateValueAndValidity();
        break;
      }
    }
  }

  private getAllPracticeDocumentIds(): string[] {
    return this.createNewProposalService.getAllPracticeDocumentIds();
  }
}
