import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { Observable } from 'rxjs';

@Injectable()
export class PlanEditPatientIdService {
  private CONFIG_KEY = 'plan_edit_patient_id';

  constructor(private configService: ConfigService) {}

  getSaveBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'save_btn');
  }
}
