export enum DepositPayee {
  PRACTICE = '1',
  DENTICARE = '2'
}

export enum DepositStatus {
  PENDING = '1', // deposit hold
  CLEARED = '2', // deposit processed, rp paid to denticare
  PAID = '3', // denticare paid to provider
  FAILED = '4',
  PRACTICE_TO_PROCESS = '5',
  RELEASED = '6'
}
