import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { FirstPaymentDateDdItem } from '@app/core/models/common/form-fields.interface';
import { ConfigService } from '@app/core/services/config/config.service';
import { maxDateValidator, minDateValidator } from '@app/core/services/ddr.validator';
import { FormFieldsService } from '@app/core/services/form-fields/form-fields.service';
import { HelperService } from '@app/core/services/helper.service';
import { PlusConnectCalculatorService } from '@app/core/services/plus-connect-calculator/plus-connect-calculator.service';
import { UserSegmentService } from '@app/core/services/user-segment/user-segment.service';
import { AppDatePicker } from '@app/shared/interfaces/date-picker.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { NumberInputConfig } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum FirstDebitDateOptions {
  Days = '1',
  Weeks = '3',
  Months = '2'
}

@Injectable()
export class FirstPaymentDateProposalService {
  private CONFIG_KEY = 'fpd_proposal';

  maxStarDateInDays = +this.userSegmentService.getMaxStartDateInDays();
  tomorrow = this.helperService.addDaysToDate(1);

  constructor(
    private configService: ConfigService,
    private formFieldsService: FormFieldsService,
    private plusConnectCalcService: PlusConnectCalculatorService,
    private helperService: HelperService,
    private userSegmentService: UserSegmentService
  ) {}

  getDtpConfig(): Observable<AppDatePicker> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'dtp').pipe(
      map((data) => {
        const newData = { ...data };
        newData.minDate = this.tomorrow;
        newData.maxDate = this.getMaxPaymentStartDate();
        return newData;
      })
    );
  }

  getRelativeInputConfig(): Observable<NumberInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'relative_input');
  }

  getRelativeDdConfig(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'relative_dd');
  }

  getDdItems(): FirstPaymentDateDdItem[] {
    return this.formFieldsService.getFpdOptions();
  }

  getDefaultFirstPaymentDate(): Date {
    return this.plusConnectCalcService.getDefaultFirstPaymentDate();
  }

  getMaxPaymentStartDate(): Date {
    return this.helperService.addDaysToDate(this.maxStarDateInDays);
  }

  getStartDatePickerValidators(): ValidatorFn | ValidatorFn[] | null {
    return [Validators.required, minDateValidator(this.tomorrow), maxDateValidator(this.getMaxPaymentStartDate())];
  }
}
