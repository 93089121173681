import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SettingsAccountComponent } from './components/settings-account/settings-account.component';
import { SettingsTncComponent } from './components/settings-tnc/settings-tnc.component';
import { SettingsUsersComponent } from './components/settings-users/settings-users.component';
import { SettingsComponent } from './settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full'
      },
      { path: 'account', component: SettingsAccountComponent },
      { path: 'users', component: SettingsUsersComponent },
      { path: 'tnc', component: SettingsTncComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule {}
