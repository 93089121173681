import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { Frequency } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { PlanCalculationService } from '@app/core/services/plan-calculation.service';
import { DiscountType } from '@app/proposal-calculator/enums/discount.enum';
import { PlanCalculator } from '@app/shared/interfaces/plan-calculator.interface';
import { CustrecordJsonBody } from '../../models/payment/user-settings.interface';
import { FormFieldsService } from '../form-fields/form-fields.service';
import { UserSegmentService } from '../user-segment/user-segment.service';
import { UserSettingsService } from '../user-settings/user-settings.service';

@Injectable()
export class BnplCalculatorV2Service implements PlanCalculator {
  minTreatmentCostBnpl = 0;
  today = new Date();
  tomorrow = this.planCalculationService.tomorrow;
  bnplStructure = this.getBnplStructure();

  constructor(
    private helperService: HelperService,
    private planCalculationService: PlanCalculationService,
    private userSettingsService: UserSettingsService,
    private formFieldsService: FormFieldsService,
    private userSegmentService: UserSegmentService
  ) {}

  getMaxDirectDebitAmount(): number {
    return 0;
  }

  getMaxPaymentPlanAmount(): number {
    return this.getMaxTreatmentCost();
  }

  getDiscountAmount(ttc: number, percentage: number): number {
    const discountedPrice = ttc * (parseFloat(percentage.toString()) / 100);
    return this.helperService.roundUpToTwoDecimal(discountedPrice);
  }

  getFrequencies(): Frequency[] {
    const freqs = this.formFieldsService.getPaymentFrequencies();
    return freqs.filter((item: any) => item.internalid !== '3');
  }

  getMaxTreatmentCost(): number {
    return this.bnplStructure[this.bnplStructure.length - 1].maxAmount;
  }

  calculateMinTreatmentCost(): number {
    return +this.userSegmentService.getMinPaymentPlanAmount();
  }

  setFieldValidators(formGroup: FormGroup): void {
    this.minTreatmentCostBnpl = this.calculateMinTreatmentCost();

    formGroup
      .get('totalTreatmentCost')
      ?.setValidators([Validators.required, Validators.min(this.minTreatmentCostBnpl), Validators.max(this.getMaxTreatmentCost())]);
    formGroup.get('totalTreatmentCost')?.updateValueAndValidity();

    formGroup.get('deposit')?.setValidators(null);
    formGroup.get('deposit')?.updateValueAndValidity();

    formGroup.get('paymentPlanTotal')?.setValidators([Validators.required, Validators.min(this.minTreatmentCostBnpl)]);
    formGroup.get('paymentPlanTotal')?.updateValueAndValidity();

    formGroup.get('quote.0.paymentPlanDurationInMonths')?.setValidators(null);
    formGroup.get('quote.0.paymentPlanDurationInMonths')?.updateValueAndValidity();

    formGroup.get('firstDebitDate.specificDate')?.setValidators([Validators.required]);
    formGroup.get('firstDebitDate.specificDate')?.updateValueAndValidity();
  }

  setTermMonths(formGroup: FormGroup): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;

    const termMonthsBnpl = this.getTermMonths(formGroup.get('paymentFrequency')?.value);

    if (quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.value !== termMonthsBnpl) {
      quoteGroup.controls[0].get('paymentPlanDurationInMonths')?.setValue(termMonthsBnpl);
    }
  }

  getTermMonths(frequency: string): number {
    const weeksPerYear = 52;

    switch (frequency) {
      // weekly payments
      case '1':
        return Math.ceil(weeksPerYear / 12);
      // fortnightly payments
      case '2':
        return Math.ceil(weeksPerYear / 2 / 12);
      default:
        return 0;
    }
  }

  setSinglePayment(formGroup: FormGroup, frequencies: Frequency[]): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;

    const quoteName = this.planCalculationService.setDDRSinglePayments(formGroup.get('paymentFrequency')?.value, frequencies);

    if (quoteName) {
      formGroup.get('singlePayments')?.setValue(quoteGroup.controls[0].get(quoteName)?.value);
    }
  }

  setQuoteGroup(formGroup: FormGroup, discountType: DiscountType | null = null): void {
    const quoteGroup = formGroup.get('quote') as UntypedFormArray;
    const noOfPayments = formGroup.get('noOfPayments')?.value;
    const paymentPlanAmount = this.getPaymentPlanAmount(formGroup, discountType);
    const amountRequiredToPay = this.helperService.roundUpToTwoDecimal(paymentPlanAmount / noOfPayments);

    quoteGroup.controls.forEach((quote) => {
      let weeklyCost;
      let fortnightlyCost;
      let monthlyCost;

      if (!noOfPayments || noOfPayments === 0) {
        quote.get('weekly')?.setValue(0);
        quote.get('fortnightly')?.setValue(0);
        quote.get('monthly')?.setValue(0);
      } else {
        if (formGroup.get('paymentFrequency')?.value === '2') {
          weeklyCost = this.helperService.roundUpToTwoDecimal(paymentPlanAmount / (noOfPayments * 2));
          fortnightlyCost = amountRequiredToPay;
          monthlyCost = amountRequiredToPay;
        } else {
          weeklyCost = amountRequiredToPay;
          fortnightlyCost = this.helperService.roundUpToTwoDecimal(paymentPlanAmount / (noOfPayments / 2));
          monthlyCost = amountRequiredToPay;
        }
        quote.get('weekly')?.setValue(weeklyCost >= 0 ? weeklyCost : null);
        quote.get('fortnightly')?.setValue(fortnightlyCost >= 0 ? fortnightlyCost : null);
        quote.get('monthly')?.setValue(monthlyCost >= 0 ? monthlyCost : null);
      }
    });
  }

  setNoOfPayments(formGroup: FormGroup): void {
    formGroup
      .get('noOfPayments')
      ?.setValue(this.getCalculateNoOfPayments(formGroup.get('paymentFrequency')?.value, formGroup.get('totalTreatmentCost')?.value));
  }

  getCalculateNoOfPayments(frequency: string, totalPlanValue: number): number {
    let noOfPayments = 0;

    totalPlanValue = this.helperService.roundUpToTwoDecimal(totalPlanValue);

    this.bnplStructure.map((x: any) => {
      if (totalPlanValue >= x.minAmount && totalPlanValue <= x.maxAmount) {
        switch (frequency) {
          case '1': {
            noOfPayments = x.weekly;
            break;
          }
          case '2': {
            noOfPayments = x.weekly / 2;
            break;
          }
          default: {
            noOfPayments = x.weekly;
            break;
          }
        }
      }
    });

    return this.helperService.roundUpToTwoDecimal(noOfPayments);
  }

  setPaymentPlanAmount(formGroup: FormGroup, discountType: DiscountType | null = null): void {
    formGroup.get('paymentPlanTotal')?.setValue(this.getPaymentPlanAmount(formGroup, discountType));
  }

  getPaymentPlanAmount(formGroup: FormGroup, discountType: DiscountType | null = null): number {
    const totalPlanValue = formGroup.get('totalTreatmentCost')?.value ? +formGroup.get('totalTreatmentCost')?.value : 0;
    const depositAmount = formGroup.get('deposit')?.value ? +formGroup.get('deposit')?.value : 0;

    if (discountType) {
      const discount = formGroup.get('discountAmount')?.value ? +formGroup.get('discountAmount')?.value : 0;

      return this.helperService.roundUpToTwoDecimal(this.getTotalTreatmentCostWithDiscount(totalPlanValue, discount) - depositAmount);
    }

    return this.helperService.roundUpToTwoDecimal(totalPlanValue - depositAmount);
  }

  setMinimumDeposit(formGroup: FormGroup): void {
    formGroup.get('deposit')?.setValue(this.getMinimumDeposit(formGroup));
  }

  // BNPL has no deposit
  getMinimumDeposit(formGroup: FormGroup): number {
    return 0;
  }

  // BNPL has no deposit
  getMaximumDeposit(): number {
    return 0;
  }

  // BNPL has no deposit
  getDepositRatio(): number {
    return 0;
  }

  /**
    Set to today
   */
  getDefaultFirstPaymentDate(): Date {
    return new Date();
  }

  getMaxPaymentStartDate(): Date {
    return new Date();
  }

  getMaxDiscountMsg(selectedDiscountType: DiscountType, maxDiscount: number): string {
    switch (selectedDiscountType) {
      case DiscountType.Currency: {
        return 'Maximum discount must be less than ' + this.helperService.convertToMoney(maxDiscount);
      }
      case DiscountType.Percentage: {
        return `Discount percentage cannot exceed ${Math.floor(maxDiscount)}%`;
      }
    }
  }

  getMaxDiscount(discountType: DiscountType, totalTreatmentCost: number, deposit: number): number {
    switch (discountType) {
      case DiscountType.Currency: {
        // return totalTreatmentCost - deposit - this.calculateMinTreatmentCost();
        return totalTreatmentCost;
      }
      case DiscountType.Percentage: {
        // const maxAmount = totalTreatmentCost - deposit - this.calculateMinTreatmentCost();
        return 100;
      }
    }
  }

  getTotalTreatmentCostWithDiscount(ttc: number, discount: number): number {
    return this.helperService.roundUpToTwoDecimal(ttc - discount);
  }

  private getBnplStructure(): CustrecordJsonBody[] {
    return this.userSettingsService.getBnplSettings()?.bnplStructure?.custrecord_json_body;
  }
}
