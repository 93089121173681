import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { UnsubmittedPlan } from '@app/core/models/common/sms-journey.interface';
import { PaymentService } from '@app/core/services/payment.service';
import { LoadingScreenResponse } from '@app/treatment-proposal/models/loading-screen.response';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class PaymentPlanInitPageService {
  // File type from API will always be PNG
  private readonly PNG_BASE_64_PREFIX = 'data:image/png;base64,';

  constructor(private paymentService: PaymentService) {}

  retrievePlan(appendParams: Params): Observable<UnsubmittedPlan> {
    return this.paymentService.retrievePlan(appendParams);
  }

  retrieveProposalLoaderDetails(ddrDraftId: string, providerName: string): Observable<LoadingScreenResponse> {
    return this.paymentService.retrieveProposalLoaderDetails(ddrDraftId, providerName).pipe(
      tap((data) => {
        const imgSrc = data.custrecord_asset_logo ? this.PNG_BASE_64_PREFIX + data.custrecord_asset_logo : '';
        sessionStorage.setItem('proposaLoaderDetailsLogo', imgSrc);
        sessionStorage.setItem('proposaLoaderDetailsHeader', data.headingContent);
      })
    );
  }
}
