export enum BasiqVerificationStatus {
  PENDING = '',
  REJECTED = '1',
  FAILED_CREDENTIALS = '2',
  NO_TRANSACTIONAL_ACCOUNTS = '3',
  NO_INCOME = '4',
  FAILED_INCOME = '5',
  JOBSEEKER = '6',
  FAILED_ACCOUNT = '7',
  FAILED_TRANSACTION = '8',
  PASS = '9'
}

export enum BasiqVerificationStepTitle {
  VERIFY_CREDENTIALS = 'verify-credentials',
  RETRIEVE_ACCOUNTS = 'retrieve-accounts',
  RETRIEVE_TRANSACTIONS = 'retrieve-transactions'
}

export enum BasiqVerificationStepStatus {
  SUCCESS = 'success',
  IN_PROGRESS = 'in-progress',
  FAILED = 'failed'
}
