<div class="container-fluid">
  <h5 class="mb-3">Users</h5>
  <div class="row" *ngIf="settingAccessFiltered$ | async">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th sliqpaySortable="contactname" (sort)="onSort($event)"><span class="sorting">Name</span></th>
              <th sliqpaySortable="roleName" (sort)="onSort($event)"><span class="sorting">Role</span></th>
              <th sliqpaySortable="email" (sort)="onSort($event)"><span class="sorting">Email</span></th>
              <th sliqpaySortable="total" (sort)="onSort($event)"><span class="sorting">Last log in</span></th>
              <th sliqpaySortable="total" (sort)="onSort($event)"><span class="sorting">Status</span></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let settingAccess of (settingAccessFiltered$ | async) || []
                  | searchFilter : searchData
                  | paginate : { itemsPerPage: itemPerPage, currentPage: page }
              "
            >
              <td>{{ settingAccess.contactname }}</td>
              <td>{{ settingAccess.roleName }}</td>
              <td>{{ settingAccess.email }}</td>
              <td>-</td>
              <td>
                <div class="d-flex justify-content-between">
                  <span
                    class="btn btn-status"
                    sliqpayStatusBtnClass
                    [value]="settingAccess.giveaccess === 'false' ? 'Inactive' : 'Active'"
                    >{{ settingAccess.giveaccess === 'true' ? 'Active' : 'Inactive' }}</span
                  >

                  <sliqpay-setting-action-menu
                    [access]="settingAccess"
                    [accesses]="settingAccessB"
                    (edited)="edited()"
                  ></sliqpay-setting-action-menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <sliqpay-empty-screen
    *ngIf="(settingAccessFiltered$ | async) === null || (settingAccessFiltered$ | async)?.length === 0"
    [text]="'No users here yet'"
  ></sliqpay-empty-screen>
</div>
<sliqpay-pagination
  [(page)]="page"
  [(itemPerPage)]="itemPerPage"
  *ngIf="settingAccessFiltered$ | async"
></sliqpay-pagination>
