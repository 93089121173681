<div class="mobile-loading-screen">
  <div class="h-100 position-relative">
    <div class="logo text-center">
      <a href="#">
        <img src="../../../../assets/images/{{ themePath }}/logo-big.svg" alt="logo" />
      </a>
    </div>

    <div class="loader-message-container">
      <p [innerHTML]="(fullscreenLoaderState$ | async)?.message"></p>
      <sliqpay-dot-loader></sliqpay-dot-loader>
    </div>
  </div>
</div>
