import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sliqpay-dot-loader',
  templateUrl: './dot-loader.component.html',
  styleUrls: ['./dot-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DotLoaderComponent {
  @Input() theme: 'light' | 'primary' = 'light';
}
