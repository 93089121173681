import { Injectable } from '@angular/core';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { Observable } from 'rxjs';
import { ConfigService } from '@app/core/services/config/config.service';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { HelperService } from '@app/core/services/helper.service';

@Injectable()
export class MissedPaymentsListService {
  private CONFIG_KEY = 'missed_payment_list';

  constructor(private configService: ConfigService, public helperService: HelperService) {}

  getEscalateBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'apca_transfer_btn');
  }

  getCreatePlanBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'create_plan_btn');
  }

  getSearchWidgetConfig(): Observable<SearchConfig[]> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'search_widget');
  }

  getSendInvoiceBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'send_invoice_btn');
  }
}
