<sliqpay-alert-banner *ngIf="alertBannerMessage" [message]="alertBannerMessage"></sliqpay-alert-banner>

<div
  class="sliqpay-main"
  [style.height]="(mainElHeightSubject | async) + 'px'"
  [class.sliqpay-main--full-width]="(hideMenu$ | async) === true"
  (window:resize)="onResize($event)"
>
  <div class="sidenav-container" *ngIf="(hideMenu$ | async) === false">
    <sliqpay-sidenav [windowWidth$]="windowWidth$"></sliqpay-sidenav>
  </div>

  <div class="main-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
