<div class="freq-container form-group">
  <label class="w-100 d-flex">
    <span [ngClass]="{ disabled: disabled }" class="text-truncate" title="Select Payment Frequency:">
      Select Payment Frequency:
    </span>
    <span *ngIf="isRequired && !disabled" class="required-field"></span>
  </label>

  <div class="options-container row" [class.pp-freq-error]="hasError && control.touched">
    <mat-radio-group [disabled]="!weekly?.value || disabled" [(ngModel)]="value">
      <div
        *ngFor="let freq of options$ | async as options; let i = index"
        class="field-ctnr"
        [ngClass]="{ 'col-md-4': options.length > 2, 'col-md-6': options.length < 3 }"
      >
        <mat-radio-button [value]="freq.internalid" [disableRipple]="true" (change)="onChange($event.value)">
          {{ getQuoteValue(freq?.name || '') | currency }} {{ freq?.name }}
          <span *ngIf="showNumberOfPayments"> ({{ getNoOfPaymentByFreq(freq.internalid) + ' payments' }})</span>
        </mat-radio-button>
        <span *ngIf="i !== options.length - 1" class="icon-divide"></span>
      </div>
    </mat-radio-group>
  </div>
  <div class="invalid-feedback d-block" *ngIf="!disabled && hasError && control?.touched">
    <div *ngIf="hasError.required">Payment Frequency is required</div>
  </div>
</div>
