import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { LangConfigSelector } from '@app/store/selectors/lang-config.selector';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private isLangConfigLoadedSubject = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient, private langConfigSelector: LangConfigSelector) {}

  // Get lang config by key-value pair
  getLangConfigValue(key: string, value: string): Observable<any> {
    return this.getJson().pipe(
      map((data) => {
        const json: any = data;
        return json[key][value];
      })
    );
  }

  // Get lang config in store
  getJson(): Observable<any> {
    if (this.isLangConfigLoadedSubject.getValue()) {
      return this.langConfigSelector.getLangConfig();
    }

    return this.setLangConfigInStore();
  }

  // Set the lang config to store
  // Config will merge base.json and <brand>.json
  setLangConfigInStore(): Observable<any> {
    return combineLatest([this.getBaseLangConfigJson(), this.getBrandLangConfigJson()]).pipe(
      map(([baseLangConfig, brandLangConfig]) => {
        const mergedJson = Object.assign({}, baseLangConfig, brandLangConfig);

        // Update config state in store
        this.langConfigSelector.setLangConfig(mergedJson);

        // For checking if config has already been loaded
        this.isLangConfigLoadedSubject.next(true);

        return mergedJson;
      })
    );
  }

  isConfigLoaded(): Observable<boolean> {
    return this.isLangConfigLoadedSubject;
  }

  private getBrandLangConfigJson(): Observable<any> {
    return this.httpClient.get('assets/lang/' + environment.client.toLowerCase() + '.json');
  }

  private getBaseLangConfigJson(): Observable<any> {
    return this.httpClient.get('assets/lang/base.json');
  }
}
