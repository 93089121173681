import { Injectable } from '@angular/core';
import { PlanType } from '@app/core/enums/plan-type.enum';
import {
  CreditCardType,
  DdrPlanType,
  FirstPaymentDateDdItem,
  FormFields,
  InternalIdNamePair,
  MedicareCardColor,
  NameValuePair,
  PaymentMethod,
  ProviderContactDdr,
  RpCompliance,
  RpIdType,
  RpState,
  TextValuePair
} from '@app/core/models/common/form-fields.interface';

@Injectable({
  providedIn: 'root'
})
export class FormFieldsService {
  getFormFields(): FormFields {
    return JSON.parse(sessionStorage.getItem('form_fields') as string);
  }

  getRpCompliance(planType: PlanType): RpCompliance[] {
    const plan = this.getFormFields()?.custrecord_mfa_ddr_plan_type.find((x) => x.internalid === planType);
    return plan ? plan.custrecord_plantype_rpcompliance : [];
  }

  getPaymentFrequencies(): InternalIdNamePair[] {
    return this.getFormFields()?.custrecord_mfa_ddr_payment_frequency;
  }

  getDoctors(): ProviderContactDdr[] {
    return this.getFormFields()?.custrecord_custentity_providercontactdr;
  }

  getTitles(): InternalIdNamePair[] {
    return this.getFormFields()?.custrecord_title;
  }

  getCountries(): TextValuePair[] {
    return this.getFormFields()?.country_list;
  }

  getIdTypes(): RpIdType[] {
    return this.getFormFields()?.custrecord_rp_id_type;
  }

  getMedicareCardColors(): MedicareCardColor[] {
    return this.getFormFields()?.customrecord_medicare_card_colour;
  }

  getStates(): RpState[] {
    return this.getFormFields()?.custrecord_mfa_ddr_rpstate;
  }

  getEmploymentStatus(): InternalIdNamePair[] {
    return this.getFormFields()?.custrecord_pa_custfield_ddr_emplstatus_special;
  }

  getTreatmentTypes(): NameValuePair[] {
    return this.getFormFields()?.ztreatment_types;
  }

  getOrthoItems(): InternalIdNamePair[] {
    return this.getFormFields()?.custrecord_pa_ddr_orthoitems;
  }

  getPlanTypes(): DdrPlanType[] {
    return this.getFormFields()?.custrecord_mfa_ddr_plan_type;
  }

  getPaymentMethods(): PaymentMethod[] {
    return this.getFormFields()?.zpayment_methods;
  }

  getDdrSourceList(): InternalIdNamePair[] {
    return this.getFormFields()?.customlist_mfa_ddr_source_list;
  }

  getFpdOptions(): FirstPaymentDateDdItem[] {
    return this.getFormFields()?.customrecord_template_first_pay_dt_op;
  }

  getFpdTypes(): InternalIdNamePair[] {
    return this.getFormFields()?.customlist_first_payment_dt_type;
  }

  getModuleTypes(): InternalIdNamePair[] {
    return this.getFormFields()?.customlist_module_type;
  }

  getDiscountItems(): InternalIdNamePair[] {
    return this.getFormFields()?.customlist_prov_config_disc_settings;
  }

  getTemplateTypes(): InternalIdNamePair[] {
    return this.getFormFields()?.customlist_tp_template_template_type;
  }

  getTemplateTreatmentTypes(): InternalIdNamePair[] {
    return this.getFormFields()?.customlist_tp_template_treatment_type;
  }

  getFpdOptionName(internalid: string): string {
    return this.getFpdOptions().find((x) => x.internalid === internalid)?.name || '';
  }

  getCreditCardTypes(): CreditCardType[] {
    return this.getFormFields()?.ztype_of_card;
  }

  getDepositPayeeOptions(): InternalIdNamePair[] {
    return this.getFormFields().customlist_pay_deposit_to;
  }
}
