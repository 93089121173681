import { Injectable } from '@angular/core';
import { PaymentPlanDataService } from '@app/core/data-services/payment-plan/payment-plan-data.service';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { ConfigService } from '@app/core/services/config/config.service';
import { FormFieldsService } from '@app/core/services/form-fields/form-fields.service';
import { HelperService } from '@app/core/services/helper.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AllPlansService {
  private PAYMENT_PLANS_CONFIG_KEY = 'payment_plans_page';

  constructor(
    private configService: ConfigService,
    private helperService: HelperService,
    private paymentPlanDataService: PaymentPlanDataService,
    private formFieldsService: FormFieldsService,
    private userSettingsService: UserSettingsService
  ) {}

  getSearchWidgetConfig(): Observable<SearchConfig[]> {
    return this.configService.getConfigValue(this.PAYMENT_PLANS_CONFIG_KEY, 'search_widget');
  }

  getPlanTypeOptions(): Array<{ [key: string]: any }> {
    return (
      this.formFieldsService.getPlanTypes().filter((planType) => {
        if (!this.userSettingsService.isPayInFullEnabled()) {
          return planType.internalid !== PlanType.PayInFull;
        }

        return planType;
      }) ?? []
    );
  }

  getDepositInvoiceFileByDdrId(ddrId: string): Observable<any> {
    return this.paymentPlanDataService.getDepositInvoiceFile(ddrId).pipe(
      map((res) => {
        if (this.helperService.checkAPIResponse(res)) {
          return res.data;
        }
      })
    );
  }
}
