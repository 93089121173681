import { Action } from '@ngrx/store';

export const UPDATE_LANG_CONFIG = '[LangConfig] Update';

export class UpdateLangConfig implements Action {
  readonly type: string = UPDATE_LANG_CONFIG;

  constructor(public payload: any) {}
}

export type All = UpdateLangConfig;
