import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatStepperModule } from '@angular/material/stepper';

import { PaymentsRoutingModule } from '@app/payments/payment-plans-routing.module';
import { DecodeLineBreakPipe } from '@app/shared/pipes/decode-line-break.pipe';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@core/core.module';
import { NgxMaskModule } from 'ngx-mask';
import { AllPlansComponent } from './all-plans/all-plans.component';
import { PaymentsActionMenuComponent } from './all-plans/payments-action-menu/payments-action-menu.component';
import { PlanEditPatientIdComponent } from './all-plans/plan-edit-patient-id/plan-edit-patient-id.component';
import { ApprovalPlansComponent } from './approval-plans/approval-plans.component';
import { MissedPaymentsDetailsComponent } from './missed-payments/details/missed-payments-details.component';
import { MissedPaymentsListActionMenuComponent } from './missed-payments/list/missed-payments-list-action-menu/missed-payments-list-action-menu.component';
import { MissedPaymentsListComponent } from './missed-payments/list/missed-payments-list/missed-payments-list.component';
import { MissedPaymentsComponent } from './missed-payments/missed-payments.component';
import { MissedPaymentsService } from './missed-payments/services/missed-payments.service';
import { AmendmentRequestComponent } from './modals/amendment-request/amendment-request.component';
import { CommentModalComponent } from './modals/comment-modal/comment-modal.component';
import { PaymentsMenuComponent } from './payments-menu/payments-menu.component';
import { AmendmentActionMenuComponent } from './pending-plans/amendment-action-menu/amendment-action-menu.component';
import { PaymentsUnsubmittedActionMenuComponent } from './pending-plans/payments-unsubmitted-action-menu/payments-unsubmitted-action-menu.component';
import { PendingPlansComponent } from './pending-plans/pending-plans.component';
import { PaymentPlansService } from './services/payment-plans.service';

@NgModule({
  declarations: [
    AllPlansComponent,
    ApprovalPlansComponent,
    MissedPaymentsComponent,
    PaymentsMenuComponent,
    PaymentsActionMenuComponent,
    PaymentsUnsubmittedActionMenuComponent,
    PlanEditPatientIdComponent,
    AllPlansComponent,
    PendingPlansComponent,
    ApprovalPlansComponent,
    MissedPaymentsComponent,
    AmendmentActionMenuComponent,
    AmendmentRequestComponent,
    MissedPaymentsListComponent,
    MissedPaymentsDetailsComponent,
    MissedPaymentsListActionMenuComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    PaymentsRoutingModule,
    MatSliderModule,
    MatStepperModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    CommentModalComponent,
    DecodeLineBreakPipe
  ],
  providers: [MissedPaymentsService, PaymentPlansService]
})
export class PaymentPlansModule {}
