import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { DDRRegex } from '@app/core/services/ddr.validator';
import { EnvironmentService } from '@app/core/services/environment.service';
import { AppInput } from '@app/shared/interfaces/input.interface';

@Component({
  selector: 'sliqpay-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class InputComponent implements ControlValueAccessor {
  @Input()
  config!: AppInput | null;
  @Output()
  changed: EventEmitter<string> = new EventEmitter();
  @Output()
  inputBlur: EventEmitter<string> = new EventEmitter();
  @Output()
  inputKeyPress: EventEmitter<string> = new EventEmitter();

  onChange!: (value: string) => void;
  onTouched!: () => void;

  form!: UntypedFormGroup;

  constructor(
    private environmentService: EnvironmentService,
    private fb: UntypedFormBuilder,
    @Self() @Optional() public control: NgControl
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
    this.form = this.fb.group({
      input: new UntypedFormControl('')
    });
  }

  get formInput(): AbstractControl | null {
    return this.form.get('input');
  }

  public get invalid(): boolean | null {
    return this.control ? this.control.invalid : false;
  }

  public get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  public get disabled(): boolean {
    return this.config?.disabled || this.control?.disabled ? true : false;
  }

  public get mask(): string {
    return this.config?.mask ? this.config?.mask : '';
  }

  public get hideArrows(): boolean {
    return this.config?.hideArrows && this.config?.type === 'number' ? this.config?.hideArrows : false;
  }

  public get pattern(): RegExp | string {
    if (this.config?.pattern) {
      return this.config.pattern;
    }
    if (this.config?.type === 'email') {
      return DDRRegex.getEmailValidation();
    }
    return '';
  }

  public get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }

  public get hasVerifiedText(): string {
    return this.config?.verifiedText || '';
  }

  writeValue(value: string): void {
    this.form.get('input')?.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isAutocompleteEnabled(): string {
    return this.environmentService.isInputAutoCompleteEnabled() ? 'on' : 'off';
  }

  onBlur(value: string): void {
    this.onChange(value);
    this.onTouched();
    this.inputBlur.emit(value);
  }

  onInputChange(value: string): void {
    this.onChange(value);
    this.changed.emit(value);
  }

  onKeyup(value: string): void {
    this.onChange(value);
    this.inputKeyPress.emit(value);
  }
}
