import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ProviderConfigFeature } from '@app/core/directives/provider-config-feature-flag/provider-config-feature.enum';
import { NgVarDirective } from '@app/core/directives/var/ng-var.directive';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PaymentUnsubmittedItemResponse, UnsubmittedPlan } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { PaymentService } from '@app/core/services/payment.service';
import { DateSearchRequest, SearchRequest } from '@app/shared/interfaces/search-request.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CommentModalComponent, CommentPropertyName } from '../modals/comment-modal/comment-modal.component';
import { PendingPlansService } from './pending-plans.service';

@Component({
  selector: 'sliqpay-pending-plans',
  templateUrl: './pending-plans.component.html',
  styleUrls: ['./pending-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PendingPlansService]
})
export class PendingPlansComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'unsubmitted_plans';

  @ViewChildren(NgVarDirective) headers: QueryList<NgVarDirective> | any[] = [];

  page = 0;
  itemPerPage = environment.itemPerPage;
  ModuleType = ModuleType;
  ProviderConfigFeature = ProviderConfigFeature;

  searchData: SearchRequest = {};
  dateSearchData: DateSearchRequest = {};

  themePath = environment.client;
  dropdownChanges = false;

  paymentPlan$: Observable<PaymentUnsubmittedItemResponse[]> = of([]);
  paymentPlanFiltered$: Observable<PaymentUnsubmittedItemResponse[] | null> = of(null);

  sortOptionsSubject = new BehaviorSubject<{ [key: string]: any }>({ column: '', direction: '', type: '' });
  searchWidgetConfig$!: Observable<SearchConfig[]>;

  trackByFn = (index: number, item: PaymentUnsubmittedItemResponse) => item.id;

  private unsubscribe$ = new Subject();

  constructor(
    private paymentService: PaymentService,
    private toastrService: ToastrService,
    private helpService: HelperService,
    private pendingPlansService: PendingPlansService,
    private router: Router,
    protected modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.paymentService.fetchUnsubmittedPlans();

    this.paymentPlan$ = this.paymentService.getPaymentPlanUnsubmitted$();
    this.paymentPlanFiltered$ = combineLatest([this.paymentPlan$, this.sortOptionsSubject.asObservable()]).pipe(
      map(([unsubmittedRequests, { column, direction, type, options }]) => {
        if (!unsubmittedRequests.length) {
          return null;
        }
        const _options = type === 'dateTime' ? options : {};
        return this.helpService.onSort(column, direction, unsubmittedRequests, type, _options) ?? [];
      })
    );

    this.setConfigs();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // sortOptions: { column: string; direction: string; type: string }
  onSort(sortOptions: any) {
    this.sortOptionsSubject.next(sortOptions);
  }

  onDepositReleaseDateSort(sortOptions: any): void {
    this.sortOptionsSubject.next({ ...sortOptions, options: { dateTimeFormat: 'dd/MM/yyyy h:m:s a' } });
  }

  searchFilter(event: SearchRequest): void {
    this.page = 0;
    this.searchData = event;
  }

  onDateFilterChange(event: { from: Date; to: Date }): void {
    this.dateSearchData = {
      from: event?.from,
      to: event?.to,
      searchColumn: 'json_transformed.start_date'
    };
  }

  deletePlan(id: string): void {
    this.paymentService
      .deletePlan(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.paymentService.deletePlan(id);
        this.toastrService.success(response[0].message, 'Deleted');
      });
  }

  goToCreatePlanPage(): void {
    this.router.navigate(['provider-journey/create-plan']);
  }

  comment(plan: UnsubmittedPlan): void {
    const modal = this.modal.open(CommentModalComponent, { centered: true });
    modal.componentInstance.id = plan.id;
    modal.componentInstance.propertyName = CommentPropertyName.PENDING_PLANS;
    modal.componentInstance.commentValue = plan.custrecord_unsubmitted_comment;
    modal.closed
      .pipe(
        filter((d) => !!d),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        const updatedValue = { ...plan, custrecord_unsubmitted_comment: data.custrecord_unsubmitted_comment };
        this.paymentService.updateUnsubmittedPlan(updatedValue.id, updatedValue);
      });
  }

  private setConfigs(): void {
    this.searchWidgetConfig$ = this.pendingPlansService.getSearchWidgetConfig();
  }
}
