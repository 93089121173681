import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDatePicker } from '@app/shared/interfaces/date-picker.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { AppInput, NumberInputConfig } from '@app/shared/interfaces/input.interface';
import { AppRadioGroupConfig } from '@app/shared/interfaces/radio-group.interface';
import { SliderConfig } from '@app/shared/interfaces/slider.interface';
import { Observable } from 'rxjs';

@Injectable()
export class AmendmentRequestUiService {
  private CONFIG_KEY = 'support_amendment_component';

  constructor(private configService: ConfigService) {}

  getAmendmentReasonConfig$(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_reason_dd');
  }

  getOrthoItemsDdConfig$(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'ortho_items_dd');
  }

  getRemainingPlanAmountInputConfig$(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_remaining_plan_amount');
  }

  getPlanAmountChangeConfig$(): Observable<NumberInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_amount_change');
  }

  getNewPlanTotalAmountConfig$(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_new_plan_amount');
  }

  getRequestorNameConfig$(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_requestor_name');
  }

  getRequestorEmailConfig$(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_requestor_email');
  }

  getDurationInputConfig$(): Observable<NumberInputConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_plan_duration');
  }

  getDurationSliderConfig$(): Observable<SliderConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_plan_duration_slider');
  }

  getFeeOwnerConfig$(): Observable<AppDropdown> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'amendment_fee_owner_dd');
  }

  getProceedBtnConfig$(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'proceed_btn');
  }

  getSubmitCancellationRequestBtnConfig$(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'submit_cancellation_request_btn');
  }

  getUpdateAmendmentBtnConfig$(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'update_amendment_btn');
  }

  getStartDateDatePickerConfig$(): Observable<AppDatePicker> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'start_date_datepicker');
  }

  getPaymentFrequencyRadioGroupConfig$(): Observable<AppRadioGroupConfig> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'payment_frequency_radio_group');
  }
}
