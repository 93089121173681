<sliqpay-header [pageTitle]="'page_title' | lang: LANG_CONFIG_KEY | async" class="page-header-sticky">
  <div class="create-btn-container">
    <sliqpay-create-plan-dropdown
      class="mr-2"
      [moduleType]="ModuleType.Proposal"
      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
    ></sliqpay-create-plan-dropdown>

    <sliqpay-create-plan-dropdown></sliqpay-create-plan-dropdown>
  </div>
</sliqpay-header>

<div class="page-content">
  <div class="bg-white rounded">
    <sliqpay-payments-menu></sliqpay-payments-menu>
    <div class="content">
      <div class="container-fluid">
        <sliqpay-list-search-widget
          [config]="(searchWidgetConfig$ | async) ?? []"
          (searchValue)="onSearchValueChange($event)"
          (dateChange)="onDateChange($event)"
        ></sliqpay-list-search-widget>
        <div class="row" *ngIf="paymentPlanSummaryFilter$ | async">
          <div class="col-12">
            <div class="table-responsive all-plans-table" [class.drop-down-opened]="dropdownChanges">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" sliqpaySortable="id" (sort)="onSort($event)">
                      <span class="sorting" [innerHTML]="'ddr_no' | lang: LANG_CONFIG_KEY | async"> </span>
                    </th>
                    <th scope="col" sliqpaySortable="portal_plan_type_label" (sort)="onSort($event)" class="col-narrow">
                      <span class="sorting" [innerHTML]="'plan_type' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th scope="col" sliqpaySortable="doctor_name" (sort)="onSort($event)" class="col-narrow">
                      <span class="sorting" [innerHTML]="'doctor_name' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th scope="col" sliqpaySortable="patient_name" (sort)="onSort($event)" class="col-narrow">
                      <span class="sorting" [innerHTML]="'patient_name' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th scope="col" sliqpaySortable="practice_patient_id_no" (sort)="onSort($event)">
                      <span class="sorting" [innerHTML]="'patient_id' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th scope="col" sliqpaySortable="responsible_party_name" (sort)="onSort($event)" class="col-narrow">
                      <span class="sorting" [innerHTML]="'rp_name' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th scope="col" sliqpaySortable="total" type="number" (sort)="onSort($event)">
                      <span class="sorting" [innerHTML]="'plan_amount' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th
                      scope="col"
                      sliqpaySortable="$$depositOrPifStatusLabel"
                      (sort)="onSort($event)"
                      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
                    >
                      <span class="sorting" [innerHTML]="'deposit_status' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th
                      scope="col"
                      sliqpaySortable="$$providerResidualSortingValue"
                      type="number"
                      (sort)="onSort($event)"
                      class="col-narrow"
                    >
                      <span
                        class="sorting"
                        [innerHTML]="'provider_closing_balance' | lang: LANG_CONFIG_KEY | async"
                      ></span>
                    </th>
                    <th
                      scope="col"
                      sliqpaySortable="custrecord_mfa_ddr_provider_comments"
                      (sort)="onSort($event)"
                      class="col-wide"
                    >
                      <span class="sorting" [innerHTML]="'provider_comments' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                    <th scope="col" sliqpaySortable="ddr_status_label" (sort)="onSort($event)">
                      <span class="sorting" [innerHTML]="'status' | lang: LANG_CONFIG_KEY | async"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let paymentPlan of (paymentPlanSummaryFilter$ | async) || []
                        | searchFilter: searchData
                        | dateFilter: searchByDate
                        | paginate: { itemsPerPage: itemPerPage, currentPage: page }
                    "
                  >
                    <td>{{ paymentPlan.id }}</td>
                    <td>{{ paymentPlan.portal_plan_type_label }}</td>
                    <td>
                      <div class="show-hide-truncate" title="{{ paymentPlan.doctor_name }}">
                        {{ paymentPlan.doctor_name }}
                      </div>
                    </td>
                    <td>{{ paymentPlan.patient_name }}</td>
                    <td>{{ paymentPlan.practice_patient_id_no }}</td>
                    <td>{{ paymentPlan?.responsible_party_name }}</td>
                    <td>${{ paymentPlan?.total | number: '1.2-2' }}</td>
                    <td [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL">
                      <ng-container *ngIf="paymentPlan?.$$isDepositPaid; else displayAsText">
                        <a
                          href="javascript:void(0)"
                          class="text-primary"
                          (click)="previewDepositInvoice(paymentPlan?.ddrId)"
                          >{{ paymentPlan.$$depositOrPifStatusLabel }}</a
                        >
                      </ng-container>
                      <ng-template #displayAsText>
                        {{ paymentPlan.$$depositOrPifStatusLabel }}
                      </ng-template>
                    </td>
                    <td>
                      {{ '$' + (paymentPlan?.providerResidual || '0' | number: '1.2-2') }}
                    </td>
                    <td class="ellipsis" (click)="comment(paymentPlan)">
                      <span [innerHTML]="paymentPlan.custrecord_mfa_ddr_provider_comments | decodeLineBreak"></span>
                    </td>
                    <td>
                      <div class="d-flex justify-content-between">
                        <span
                          class="btn btn-status text-nowrap"
                          sliqpayStatusBtnClass
                          [value]="paymentPlan?.ddr_status_label"
                          >{{ paymentPlan?.ddr_status_label }}</span
                        >
                        <sliqpay-payments-action-menu
                          [plan]="paymentPlan"
                          (planChange)="planChanged($event)"
                          (dropdownChanges)="dropdownChanges = $event"
                        ></sliqpay-payments-action-menu>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sliqpay-empty-screen
      iconClass="icon-empty-screen"
      text="No plans here yet"
      *ngIf="(paymentPlanSummaryFilter$ | async) === null || (paymentPlanSummaryFilter$ | async)?.length === 0"
    ></sliqpay-empty-screen>
  </div>

  <sliqpay-pagination
    [(page)]="page"
    [(itemPerPage)]="itemPerPage"
    *ngIf="paymentPlanSummaryFilter$ | async"
  ></sliqpay-pagination>
</div>
