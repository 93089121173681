import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DateRangeFilterConfig } from '@app/shared/interfaces/date-range-search.interface';

@Component({
  selector: 'sliqpay-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent implements OnInit {
  @Input() config!: DateRangeFilterConfig;
  @Output() dateChanged = new EventEmitter();

  searchForm!: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      from: [null],
      to: [null]
    });

    this.searchForm.valueChanges.subscribe((val) => {
      this.dateChanged.emit(val);
    });
  }

  get minDateValidator() {
    return this.searchForm.get('from')?.valid && this.searchForm.get('from')?.value
      ? {
          year: this.searchForm.get('from')?.value?.getFullYear(),
          month: this.searchForm.get('from')?.value?.getMonth() + 1,
          day: this.searchForm.get('from')?.value?.getDate()
        }
      : {
          year: 1800,
          month: null,
          day: null
        };
  }

  get maxDateValidator() {
    return this.searchForm.get('to')?.valid && this.searchForm.get('to')?.value
      ? {
          year: this.searchForm.get('to')?.value?.getFullYear(),
          month: this.searchForm.get('to')?.value?.getMonth() + 1,
          day: this.searchForm.get('to')?.value?.getDate()
        }
      : {
          year: 2050,
          month: null,
          day: null
        };
  }
}
