import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AmendmentRpJourney } from '@app/core/models/support/amendment-rp-journey.model';
import { Ddr, ErrorResponse, GenericListResponse, RpofDdr } from '@core/models';
import { Support } from '@core/models/support/support';
import { ApiUrlService } from '@core/services/api-url.service';
import { HelperService } from '@core/services/helper.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupportDataService {
  constructor(private httpClient: HttpClient, private apiUrlService: ApiUrlService, private helperService: HelperService) {}

  getSupportList(): Observable<Support[]> {
    return this.httpClient.get<Support[]>(`${this.apiUrlService.supportUrl}`).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          data = this.helperService.getAPIDataResponse(data);
          return data;
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  fetchRPofDDRList(bnplPlan = ''): Observable<GenericListResponse<RpofDdr, ErrorResponse>> {
    return this.httpClient.get<GenericListResponse<RpofDdr, ErrorResponse>>(
      bnplPlan
        ? this.apiUrlService.ddrRpListUrl + '?plan_type=' + this.helperService.getObjUser()?.validation_values.DDR_PLAN_TYPE.BNPL
        : this.apiUrlService.ddrRpListUrl
    );
  }

  fetchDDRs(rpID = '', isBnpl = false): Observable<GenericListResponse<Ddr, ErrorResponse>> {
    const appendBnpl = '?plan_type=' + this.helperService.getObjUser().validation_values.DDR_PLAN_TYPE.BNPL;

    return this.httpClient.get<GenericListResponse<Ddr, ErrorResponse>>(
      rpID
        ? this.apiUrlService.ddrListUrl + '/' + rpID + (isBnpl ? '' : appendBnpl)
        : this.apiUrlService.ddrListUrl + (isBnpl ? '' : appendBnpl)
    );
  }

  submit(body: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrlService.supportUrl, body);
  }

  getRetrieveAmendmentIncreaseWithoutLogin(appendParams: string): Observable<AmendmentRpJourney> {
    return this.httpClient.get(this.apiUrlService.savedPlanNoLoginUrl + '/' + appendParams).pipe(
      map((data: any) => {
        if (this.helperService.checkAPIResponse(data)) {
          return this.helperService.getAPIDataResponse(data);
        }
      })
    );
  }

  postCreateAmendmentWithoutLogin(appendParams: string, body: any): Observable<any> {
    return this.httpClient.post(this.apiUrlService.createAmendmentElementWithoutLogin + '/' + appendParams, body);
  }

  postSendAmendmentSMS(body: any): Observable<any> {
    return this.httpClient.post<any>(this.apiUrlService.sendAmendmentSMS, body);
  }
}
