import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { ToastComponent } from '@app/shared/components/toast/toast.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { SHARED_COMPONENTS } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { SHARED_ELEMENTS } from './elements';
import { SHARED_PIPES } from './pipes';

@NgModule({
  declarations: [...SHARED_ELEMENTS, ...SHARED_COMPONENTS, ...SHARED_PIPES, ...SHARED_DIRECTIVES],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatRadioModule,
    NgSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    NgbModule,
    MatMenuModule,
    MatSliderModule,
    NgxFileDropModule,
    MatExpansionModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      positionClass: 'toast-bottom-right',
      timeOut: 10000
    })
  ],
  exports: [...SHARED_ELEMENTS, ...SHARED_COMPONENTS, ...SHARED_PIPES, ...SHARED_DIRECTIVES]
})
export class SharedModule {}
