export enum SupportRequestType {
  Manual = 1,
  Reduce = 2,
  Increase = 3,
  Suspend = 4,
  Cancel = 5,
  Resolve = 6,
  General = 7,
  Transfer = 8,
  ContactAccess = 9,
  ReduceHf = 10
}
