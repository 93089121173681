<sliqpay-header class="page-header-sticky" [pageTitle]="'page_title' | lang: LANG_CONFIG_KEY | async">
  <div class="create-btn-container">
    <sliqpay-create-plan-dropdown
      class="mr-2"
      [moduleType]="ModuleType.Proposal"
      [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
    ></sliqpay-create-plan-dropdown>
    <sliqpay-create-plan-dropdown></sliqpay-create-plan-dropdown>
  </div>
</sliqpay-header>

<div class="page-content">
  <div class="bg-white rounded">
    <sliqpay-payments-menu></sliqpay-payments-menu>

    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-8">
            <h6 [innerHTML]="'header' | lang: LANG_CONFIG_KEY | async"></h6>
            <p [innerHTML]="'header_desc' | lang: LANG_CONFIG_KEY | async"></p>
          </div>
        </div>
        <sliqpay-list-search-widget
          [config]="(searchWidgetConfig$ | async) ?? []"
          (searchValue)="searchFilter($event)"
          (dateChange)="onDateFilterChange($event)"
        ></sliqpay-list-search-widget>
        <ng-container
          *ngIf="
            (paymentPlanApprovalFiltered$ | async) !== null && (paymentPlanApprovalFiltered$ | async)?.length !== 0;
            else emptyScreen
          "
        >
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th sliqpaySortable="ddrId" (sort)="onSort($event)">
                        <span class="sorting">DDR No</span>
                      </th>
                      <th sliqpaySortable="ddr_plan_type_label" (sort)="onSort($event)">
                        <span class="sorting">Plan type</span>
                      </th>
                      <th sliqpaySortable="patient_name" (sort)="onSort($event)">
                        <span class="sorting">Patient name</span>
                      </th>
                      <th sliqpaySortable="practice_patient_id_no" (sort)="onSort($event)">
                        <span class="sorting">Patient ID</span>
                      </th>
                      <th class="w-15" sliqpaySortable="responsible_party_name" (sort)="onSort($event)">
                        <span class="sorting">Responsible party</span>
                      </th>
                      <th sliqpaySortable="total" type="number" (sort)="onSort($event)">
                        <span class="sorting">Amount</span>
                      </th>
                      <th
                        sliqpaySortable="$$depositStatusLabel"
                        [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
                      >
                        <span class="sorting">Deposit status</span>
                      </th>
                      <th
                        sliqpaySortable="deposit_expiry"
                        type="dateTime"
                        [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL"
                        (sort)="depositExpirySortingFn($event)"
                      >
                        <span class="sorting">Deposit release date</span>
                      </th>
                      <th sliqpaySortable="ddr_status_label" (sort)="onSort($event)">
                        <span class="sorting">Plan status</span>
                      </th>
                      <th class="w-15" sliqpaySortable="custrecord_ddr_rpcompliance_failed" (sort)="onSort($event)">
                        <span class="sorting">Failed criteria</span>
                      </th>
                      <th>Approve/Ineligible payment plan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let paymentPlan of (paymentPlanApprovalFiltered$ | async) || []
                          | searchFilter: searchData
                          | dateFilter: dateSearchData
                          | paginate: { itemsPerPage: itemPerPage, currentPage: page };
                        trackBy: trackByFn
                      "
                    >
                      <td>DDR-{{ paymentPlan.ddrId }}</td>
                      <td>{{ paymentPlan.ddr_plan_type_label }}</td>
                      <td>{{ paymentPlan.patient_name }}</td>
                      <td>{{ paymentPlan.practice_patient_id_no }}</td>
                      <td>{{ paymentPlan.responsible_party_name }}</td>
                      <td>${{ paymentPlan.total | number: '1.2-2' }}</td>
                      <td [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL">
                        {{ paymentPlan.$$depositStatusLabel }}
                      </td>
                      <td [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.TREATMENT_PROPOSAL">
                        {{ paymentPlan.$$depositReleaseDate }}
                      </td>
                      <td>
                        <span class="btn btn-status" sliqpayStatusBtnClass [value]="paymentPlan?.ddr_status_label">{{
                          paymentPlan?.ddr_status_label
                        }}</span>
                      </td>
                      <td>{{ paymentPlan.custrecord_ddr_rpcompliance_failed }}</td>
                      <td>
                        <div class="d-flex">
                          <sliqpay-button
                            [config]="approveBtnConfig$ | async"
                            [disabled]="loading$ | async"
                            (clicked)="approve(paymentPlan)"
                          ></sliqpay-button>

                          <sliqpay-button
                            [config]="declineBtnConfig$ | async"
                            [disabled]="loading$ | async"
                            (clicked)="decline(paymentPlan)"
                          ></sliqpay-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #emptyScreen>
          <sliqpay-empty-screen iconClass="icon-empty-screen" text="No plans here yet"></sliqpay-empty-screen>
        </ng-template>
      </div>
    </div>
  </div>

  <sliqpay-pagination
    [(page)]="page"
    [(itemPerPage)]="itemPerPage"
    *ngIf="paymentPlanApprovalFiltered$ | async"
  ></sliqpay-pagination>
</div>
