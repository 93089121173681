import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[sliqpayStatusBtnClass]'
})
export class StatusBtnClassDirective implements OnInit {
  @Input() value: string | undefined = undefined;

  constructor(private row: ElementRef) {}

  ngOnInit() {
    let statusClass: string;

    switch (this.value) {
      case '1':
      case '3':
      case 'In Progress': // Support List
      case 'Complete':
      case 'Processing Verification': // All Plans Not In Use
        statusClass = 'bg-warning';
        break;
      case '5':
      case 'Inactive':
      case 'Closed': // Support List
      case 'Ineligible': // All Plans Changed From Declined
      case 'Cancelled':
        statusClass = 'bg-danger';
        break;
      case '4':
      case '9':
      case 'Not Started': // Support List
      case 'Pending Approval': // All Plans & Payment Plans
      case 'Paid':
        statusClass = 'bg-primary';
        break;
      case '2':
      case 'Active':
      case 'Approved': // All Plans
      case 'Escalted': // Support List
        statusClass = 'bg-success';
        break;
      case 'Paused': // All Plans Changed From Suspended
        statusClass = 'bg-suspended';
        break;
      case 'Pay At Practice':
        statusClass = 'bg-purple';
        break;
      default:
        statusClass = 'bg-success';
    }

    this.row.nativeElement.classList.add(statusClass);
  }
}
