<form [formGroup]="searchForm">
  <div class="row">
    <ng-container *ngFor="let configEl of config">
      <div [ngClass]="configEl?.containerClass ?? ''">
        <!-- SEARCH INPUT -->
        <ng-container *ngIf="configEl.searchType === searchTypes.TITLE">
          <h5 class="card-title">
            <span [innerHTML]="configEl.name"></span>
          </h5>
        </ng-container>

        <!-- SEARCH INPUT -->
        <ng-container *ngIf="configEl.searchType === searchTypes.QUERY">
          <sliqpay-search [config]="configEl.config" (queryChange)="onSearchQueryChange($event)"></sliqpay-search>
        </ng-container>

        <!-- DATE RANGE -->
        <ng-container *ngIf="configEl.searchType === searchTypes.DATE_RANGE">
          <sliqpay-date-range-filter
            [config]="configEl.config"
            (dateChanged)="onDateChange($event)"
          ></sliqpay-date-range-filter>
        </ng-container>

        <!-- DROPDOWN -->
        <ng-container *ngIf="configEl.searchType === searchTypes.DROPDOWN">
          <sliqpay-dropdown
            [formControlName]="configEl.name"
            [config]="configEl.config"
            [items]="configEl.config.options"
          >
          </sliqpay-dropdown>
        </ng-container>
      </div>
    </ng-container>
  </div>
</form>
