import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import { noop, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (_: HttpEvent<any>) => noop(),
        (err) => {
          if (err instanceof HttpErrorResponse && err.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
            // this.notificationService.error(of(err));
          }
        }
      )
    );
  }
}
