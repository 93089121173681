import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '@app/core/models';
import { PaymentMissedDetailsItemResponse } from '@app/core/models/payment/payment-missed-details-item-response';
import { ApiUrlService } from '@app/core/services/api-url.service';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MissedPaymentDetailsService {
  private CONFIG_KEY = 'missed_payment_details';

  constructor(private configService: ConfigService, private httpClient: HttpClient, private apiUrlService: ApiUrlService) {}

  getSendInvoiceBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'send_invoice_btn');
  }

  getEscalateBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'escalate_btn');
  }

  getPaymentMissedDetails(id: string): Observable<{
    isError: string;
    readonly data: PaymentMissedDetailsItemResponse[];
    readonly errors: ErrorResponse[];
  }> {
    return this.httpClient.get<{
      isError: string;
      readonly data: PaymentMissedDetailsItemResponse[];
      readonly errors: ErrorResponse[];
    }>(`${this.apiUrlService.missedPaymentsUrl}/${id}`);
  }

  getSearchWidgetConfig(): Observable<SearchConfig[]> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'search_widget');
  }
}
