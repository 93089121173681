import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppInput } from '@app/shared/interfaces/input.interface';
import { Observable } from 'rxjs';

@Injectable()
export class CreateNewPasswordService {
  private CONFIG_KEY = 'create_new_password_component';

  constructor(private configService: ConfigService) {}

  getOldPasswordConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'old_password');
  }

  getPasswordConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'password');
  }

  getPasswordConfirmationConfig(): Observable<AppInput> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'password_confirmation');
  }

  getConfirmBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'confirm_btn');
  }
}
