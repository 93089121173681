import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';
import { NotAuthGuard } from '@auth/not-auth.guard';
import { NotFoundComponent } from '@core/pages/not-found/not-found.component';
import { ConfigsResolver } from './core/resolvers/configs/configs.resolver';
import { MainComponent } from './main/main.component';
import { PaymentPlanInitPageComponent } from './sms-journey/payment-plan-init-page/payment-plan-init-page.component';

const routes: Routes = [
  {
    path: '',
    resolve: {
      config: ConfigsResolver
    },
    children: [
      {
        path: '',
        component: MainComponent,
        loadChildren: () => import('./main/main.module').then((m) => m.MainModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
        canActivate: [NotAuthGuard]
      },
      {
        path: 'amendment/confirmation',
        loadChildren: () => import('./amendment-confirm/amendment-confirm.module').then((m) => m.AmendmentConfirmModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'payment-plan-init',
        component: PaymentPlanInitPageComponent,
        data: {
          wrapperClass: 'dark-screen'
        }
      },
      {
        path: 'missed-payments-paynow',
        loadChildren: () => import('./paynow/paynow.module').then((m) => m.PaynowModule),
        data: {
          hideMenu: true,
          wrapperClass: 'white-screen'
        }
      },
      {
        path: 'payment-sms-journey',
        loadChildren: () => import('./sms-journey/sms-journey.module').then((m) => m.SmsJourneyModule),
        data: {
          wrapperClass: 'dark-screen'
        }
      },
      {
        path: 'treatment-proposal',
        loadChildren: () => import('./treatment-proposal/treatment-proposal.module').then((m) => m.TreatmentProposalModule),
        data: {
          wrapperClass: 'white-screen'
        }
      }
    ]
  },
  {
    path: '**',
    data: {
      hideMenu: true
    },
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
