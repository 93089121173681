<div class="row">
  <div class="col-12">
    <div [formGroup]="searchFormGroup" class="form-group search-field-group">
      <label *ngIf="config?.label" class="text-truncate w-100">{{ config?.label }}</label>
      <div class="search-field-group">
        <i class="uil uil-search"></i>
        <input type="text" class="form-control" [placeholder]="config?.placeholder" (keyup)="onKeyup($event)" />
      </div>
    </div>
  </div>
</div>
