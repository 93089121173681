import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppThreeDotActionMenu } from '@app/shared/interfaces/three-dot-action-menu.interface';
import { Observable } from 'rxjs';

@Injectable()
export class AmendmentActionMenuService {
  private CONFIG_KEY = 'amendment_action_menu';

  constructor(private configService: ConfigService) {}

  getMenuUiConfig$(): Observable<AppThreeDotActionMenu> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'actions');
  }
}
