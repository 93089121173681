import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordPageComponent } from '@auth/pages/forgot-password-page/forgot-password-page.component';

import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ProviderPageComponent } from '@auth/pages/provider-page/provider-page.component';
import { AuthGuard } from '@auth/auth.guard';

const routes: Routes = [
  { path: '', component: LoginPageComponent },
  { path: 'forgot-password', component: ForgotPasswordPageComponent },
  {
    path: 'select-provider',
    component: ProviderPageComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
