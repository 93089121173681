import { Pipe, PipeTransform } from '@angular/core';
import { SearchRequest } from '@app/shared/interfaces/search-request.interface';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchRequest: SearchRequest): any {
    if (!searchRequest || !Object.keys(searchRequest).length) {
      return items;
    }

    return items.filter((item) => {
      return Object.keys(searchRequest)
        .filter((key) => !!searchRequest[key])
        .every((key) => {
          if (item[key]) {
            return item[key].toLowerCase() === searchRequest[key].toLowerCase();
          }
          return JSON.stringify(item).toLowerCase().includes(searchRequest[key].toLowerCase());
        });
    });
  }
}
