import { Action } from '@ngrx/store';

export const UPDATE_CONFIG = '[Config] Update';

export class UpdateConfig implements Action {
  readonly type: string = UPDATE_CONFIG;

  constructor(public payload: any) {}
}

export type All = UpdateConfig;
