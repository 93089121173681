import { PayInFullPaymentPreference, PayInFullPaymentStatus } from '../enums/pay-in-full.enum';

export const PayInFullPaymentPreferenceOrder = new Map<PayInFullPaymentPreference, number>([
  [PayInFullPaymentPreference.IN_APP, 1],
  [PayInFullPaymentPreference.PAY_AT_PRACTICE, 2],
  [PayInFullPaymentPreference.RP_OPTIONAL, 3]
]);

export const PayInFullPaymentStatusLabel = new Map<string, string>([
  [PayInFullPaymentStatus.CLEARED, 'Payment in full collected'],
  [PayInFullPaymentStatus.PAID, 'Payment in full remitted'],
  [PayInFullPaymentStatus.TO_BE_COLLECTED_AT_PRACTICE, 'Practice to process payment in full'],
  [PayInFullPaymentStatus.COLLECTED_AT_PRACTICE, 'Payment collected at Practice']
]);
