export enum PlanStatus {
  PendingApproval = '1',
  Approved = '2',
  Complete = '9',
  SuspendedProviderAndRp = '11',
  SuspendedRp = '12',
  SuspendedProvider = '13',
  Hardship = '19',
  PayAtPractice = '28'
}
