import * as LangConfigActions from '../actions/lang-config.action';

export type LangConfigAction = LangConfigActions.All;

const defaulState: any = {};

// Helper function to create new state object
const newState = (state: any, newData: any) => {
  return Object.assign({}, state, newData);
};

export const langConfigReducer = (state: any = defaulState, action: LangConfigAction): any => {
  switch (action.type) {
    case LangConfigActions.UPDATE_LANG_CONFIG:
      return newState(state, action.payload);

    default:
      return state;
  }
};
