import { Injectable } from '@angular/core';
import { ConfigService } from '@app/core/services/config/config.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsTncService {
  private CONFIG_KEY = 'settings_tnc';

  constructor(private configService: ConfigService) {}

  getDownloadBtnConfig(): Observable<AppButton> {
    return this.configService.getConfigValue(this.CONFIG_KEY, 'download_btn');
  }
}
