<ng-container *ngIf="config">
  <div class="form-group mb-0">
    <label *ngIf="config?.label" class="w-100 d-flex">
      <span [ngClass]="{ disabled: disabled }" class="text-truncate" title="{{ config.label }}">
        {{ config.label }}
      </span>
      <span *ngIf="isRequired && config.showRequiredMarker && !disabled" class="required-field"></span>
    </label>
    <div class="pp-slider-container">
      <div class="pp-slider-label-container">
        <label id="pp-slider-name-label" class="pp-slider-name-label mb-0">{{ config.min || 0 }}</label>
        <label class="pp-slider-name-label mb-0">{{ config.max }}</label>
      </div>
      <mat-slider
        [disabled]="disabled || config.disabled"
        [max]="config.max"
        [min]="config.min"
        [displayWith]="formatThumb()"
        [value]="value"
        (input)="onInput($event)"
        aria-labelledby="pp-slider-name-label"
        [thumbLabel]="config.matSliderThumb"
        class="pp-slider"
      >
        <input matSliderThumb [(ngModel)]="value" />
      </mat-slider>
    </div>
  </div>
</ng-container>
