import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ModalPdfViewerComponent } from '@app/core/components/modal-pdf-viewer/modal-pdf-viewer.component';
import { ProviderConfigFeature } from '@app/core/directives/provider-config-feature-flag/provider-config-feature.enum';
import { NgVarDirective } from '@app/core/directives/var/ng-var.directive';
import { DepositStatus } from '@app/core/enums/deposit.enum';
import { ModuleType } from '@app/core/enums/module-type.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { PaymentPlanItemResponse } from '@app/core/models';
import { HelperService } from '@app/core/services/helper.service';
import { DateSearchRequest, SearchRequest } from '@app/shared/interfaces/search-request.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { CommentModalComponent, CommentPropertyName } from '../modals/comment-modal/comment-modal.component';
import { PaymentPlansService } from '../services/payment-plans.service';
import { AllPlansService } from './all-plans.service';

@Component({
  selector: 'sliqpay-all-plans',
  templateUrl: './all-plans.component.html',
  styleUrls: ['./all-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AllPlansService]
})
export class AllPlansComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'all_plans';

  ModuleType = ModuleType;
  ProviderConfigFeature = ProviderConfigFeature;
  PlanType = PlanType;
  DeposiStatus = DepositStatus;

  page = 0;
  itemPerPage = environment.itemPerPage;

  searchData: SearchRequest = {};
  searchByDate: DateSearchRequest = {};

  dropdownChanges = false;

  paymentPlanSummary$ = new BehaviorSubject<PaymentPlanItemResponse[] | null>(null);
  paymentPlanSummaryFilter$ = new BehaviorSubject<PaymentPlanItemResponse[] | null>(null);
  searchWidgetConfig$!: Observable<SearchConfig[]>;

  @ViewChildren(NgVarDirective) headers: QueryList<NgVarDirective> | any[] = [];
  private unsubscribe$ = new Subject<boolean>();

  constructor(
    private helperService: HelperService,
    private allPlansService: AllPlansService,
    private router: Router,
    private modal: NgbModal,
    private paymentPlansService: PaymentPlansService
  ) {}

  ngOnInit(): void {
    this.paymentPlansService.initAllActivePlans();

    this.getPaymentSummary();
    this.setConfigs();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPaymentSummary() {
    this.paymentPlansService.getAllActivePlans$().subscribe((planList) => {
      this.paymentPlanSummary$.next(planList);
      this.paymentPlanSummaryFilter$.next(planList);
    });
  }

  onSort({ column, direction, type }: any) {
    this.paymentPlanSummaryFilter$.next(this.helperService.onSort(column, direction, this.paymentPlanSummary$?.value, type));
  }

  planChanged(plan: PaymentPlanItemResponse) {
    if (this.paymentPlanSummaryFilter$.value) {
      const changedList = this.paymentPlanSummaryFilter$.value.map((p) => {
        return p.id === plan.id ? plan : p;
      });
      this.paymentPlanSummaryFilter$.next(changedList);
    }
  }

  onSearchValueChange(event: SearchRequest): void {
    this.searchData = event;
    // reset focus to the first page
    this.page = 1;
  }

  onDateChange(event: { from: Date; to: Date }): void {
    this.searchByDate = {
      from: event?.from,
      to: event?.to,
      searchColumn: 'start_date'
    };
  }

  goToCreatePlanPage(): void {
    this.router.navigate(['provider-journey/create-plan']);
  }

  comment(plan: PaymentPlanItemResponse): void {
    const modal = this.modal.open(CommentModalComponent, { centered: true });
    modal.componentInstance.id = plan.ddrId;
    modal.componentInstance.propertyName = CommentPropertyName.DDR_PLANS;
    modal.componentInstance.commentValue = plan.custrecord_mfa_ddr_provider_comments;
    modal.closed
      .pipe(
        filter((d) => !!d),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        this.planChanged({ ...plan, custrecord_mfa_ddr_provider_comments: data.custrecord_mfa_ddr_provider_comments });
      });
  }

  previewDepositInvoice(ddrId: string): void {
    this.allPlansService
      .getDepositInvoiceFileByDdrId(ddrId)
      .pipe(take(1))
      .subscribe((base64content) => {
        if (base64content) {
          const pdfInBase64 = base64content;
          const byteArray = new Uint8Array(
            atob(pdfInBase64)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          const modal = this.modal.open(ModalPdfViewerComponent, {
            centered: true,
            size: 'xl',
            backdrop: 'static',
            keyboard: false
          });
          modal.componentInstance.data = {
            title: 'Preview',
            pdfInBase64,
            pdfInUint8Array: byteArray,
            fileName: `${ddrId}-depositInvoice.pdf`
          };
        }
      });
  }

  private setConfigs(): void {
    this.searchWidgetConfig$ = combineLatest([this.allPlansService.getSearchWidgetConfig(), this.paymentPlanSummaryFilter$]).pipe(
      map(([rawConfigs, paymentPlans]) => {
        return rawConfigs.map((config) => {
          const cloneConfig: SearchConfig = { ...config };

          if (config.name === 'ddr_plan_type') {
            cloneConfig.config = {
              ...cloneConfig.config,
              options: this.allPlansService.getPlanTypeOptions()
            };
          } else if (config.name === 'ddr_status_label') {
            cloneConfig.config = {
              ...cloneConfig.config,
              options: this.helperService.getUniqueObjectValuesFromArray(paymentPlans || [], 'ddr_status_label')
            };
          }

          return cloneConfig;
        });
      })
    );
  }
}
